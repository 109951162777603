import { message } from "antd";
import axios from "axios";
import { getAuth } from "firebase/auth";

export const callInternalApi = async <RequestData, ResponseType>(
  path: string,
  data: RequestData
) => {
  const auth = getAuth();
  const user = auth?.currentUser;
  const token = await user?.getIdToken();
  try {
    if (!window) return null;

    const hostname = window.location.hostname;
    const isLocalhost = hostname === "localhost";
    const isVercelTest = hostname?.includes("vercel.app");
    const res = await axios({
      method: "POST",
      url: `/api/internal/${path}`,
      headers: {
        Authorization: "Bearer " + token,
        "kanpla-auth-provider": "GAuth",
        "kanpla-debug": true,
        "kanpla-app-env": isLocalhost ? "DEV" : isVercelTest ? "TEST" : "PROD",
      },
      data: data || {},
    });

    return res.data as ResponseType;
  } catch (e) {
    console.error(e);
    message.error(e?.message);
  }
};
