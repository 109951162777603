import { withSearch } from "@elastic/react-search-ui";
import { Input } from "antd";
import React, { useEffect, useState } from "react";

const Search = ({ searchTerm, setSearchTerm }) => {
  const [query, setQuery] = useState(searchTerm);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <Input.Search
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
      }}
      className="w-60"
      placeholder="Search"
    />
  );
};

export default withSearch(({ searchTerm, setSearchTerm }) => ({
  searchTerm,
  setSearchTerm,
}))(Search);
