import React from "react";
import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  /** Custom color of the icon */
  color?: string;
}

export const TooltipHiddenPrices = ({ color = "" }: Props) => {
  const { t } = useTranslation(["translation"]);

  return (
    <Tooltip
      title={t("translation:hidden-prices")}
      trigger={["hover", "click"]}
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={color}
      />
    </Tooltip>
  );
};
