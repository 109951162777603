import {
  FlexOption,
  Product,
  MenuItemVariants,
  OrderConfig,
} from "@kanpla/types";
import React, { useState } from "react";
import Option from "./Option";

export interface InternalRewrittenProduct {
  /** Name of the dish, gets displayed in the modal */
  name?: Product["name"];
  /** Id of the dish */
  id?: Product["id"];
  /** Price of the dish */
  price?: Product["price"];
  /** Color of the product, default is some type of gray */
  color?: Product["color"];
  /** 0-1 */
  taxPercentage?: number;
}

interface Props {
  /** Info about the product */
  product?: InternalRewrittenProduct;
  /** Available variants */
  options?: FlexOption[];
  value: OrderConfig["options"];
  onChange: (newValue: OrderConfig["options"]) => void;
  variantNames?: MenuItemVariants;
  editing?: boolean;
}

export const ProductOptions = ({
  product = {},
  options = [],
  value,
  onChange,
  variantNames,
}: Props) => {
  const [isErrored, setIsErrored] = useState<string[]>([]);

  const processError = (moduleId: string, add: boolean) => {
    if (!add) {
      const newArray = isErrored.filter((e) => e !== moduleId);
      return setIsErrored(newArray);
    }

    if (add) {
      const newArray = isErrored.filter((e) => e !== moduleId);
      return setIsErrored([...newArray, moduleId]);
    }
  };

  const isFirst = (index: number) => {
    return index === 0;
  };

  return (
    // Couldn't do actions because of colors
    <>
      {/* Options */}
      {options
        .filter((o) => !o.extension?.includes("hidden"))
        .map((option: FlexOption, index) => (
          <Option
            key={option.id}
            option={option}
            color={product.color}
            setError={(id, add) => processError(id, add)}
            orderChoices={value}
            variantNames={variantNames}
            product={product}
            onChange={onChange}
            isFirst={isFirst(index)}
          />
        ))}
    </>
  );
};
