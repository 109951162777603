import React from "react";
import { ModalButton } from "@kanpla/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinary } from "@fortawesome/pro-light-svg-icons";
import Data from "./Data";

interface Props {
  docId: string;
  collection: string;
}

interface ReadyJsonProps {
  json: JSON;
}

type PropsType = Props | ReadyJsonProps;

export const RawDataRenderer = (props: PropsType) => {
  if ((props as ReadyJsonProps).json) {
    return (
      <ModalButton
        label="Raw data"
        buttonProps={{
          size: "small",
          icon: <FontAwesomeIcon icon={faBinary} className="mr-2" />,
        }}
      >
        <pre>{JSON.stringify(props.json, null, 2)}</pre>
      </ModalButton>
    );
  }

  return (
    <ModalButton
      label="Raw data"
      buttonProps={{
        size: "small",
        icon: <FontAwesomeIcon icon={faBinary} className="mr-2" />,
      }}
    >
      <Data docId={props?.docId} collection={props?.collection} />
    </ModalButton>
  );
};
