import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import React from "react";

interface Props extends TextProps {
  value: string;
  /** Label that can be displayed even though value will be copied */
  label?: string | JSX.Element;
}

export const IdDisplay = ({ value, label, ...props }: Props) => {
  return (
    <Typography.Text copyable={{ text: value }} {...props}>
      {label || value}
    </Typography.Text>
  );
};
