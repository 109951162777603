import { FirebaseFirestore } from "@firebase/firestore-types";
import {
  InvoiceDataItemProps,
  OrderPersonal,
  School,
  Timestamp,
} from "@kanpla/types";
import { calculateOrderTotal } from "../orders/calculateOrderTotal";

import calculateDelivery from "./calculateDelivery";
import calculateFlex from "./calculateFlex";
import groupItems from "./groupItems";

interface Props {
  schoolId: string;
  school: School;
  timestampFrom: Timestamp;
  timestampTo: Timestamp;
  db: FirebaseFirestore | any; // used to fetch data
}

export const calculateBill = async (props: Props) => {
  const { schoolId, school, timestampFrom, timestampTo, db } = props;

  // Data
  const individualDocs =
    (await db
      .collection("flexOrders")
      .where("schoolId", "==", schoolId)
      .where("date", ">=", timestampFrom)
      .where("date", "<", timestampTo)
      .get()
      .then((res) =>
        res.docs
          .map((d) => ({ ...d.data(), id: d.ref.id }))
          .filter((d) => !d.deleted)
      )
      .catch((e) => console.log("Error getting individual docs", e))) || [];

  // Get data of old meeting orders
  const migratedRefs: string[] = [];

  const newOrderDocs =
    (await db
      .collection("orders")
      .where("schoolId", "==", schoolId)
      .where("date", ">=", timestampFrom)
      .where("date", "<", timestampTo)
      .where("paymentMethod", "==", "billing")
      .get()
      .then((res) =>
        res.docs.map((d) => {
          const data = d.data();

          if (data.migratedRef) {
            migratedRefs.push(data.migratedRef);
          }

          return { ...data, id: d.ref.id };
        })
      )
      .catch((e) => console.log("Error getting meeting docs", e?.message))) ||
    [];

  const oldOrderDocs =
    (await db
      .collection("meetingOrders")
      .where("schoolId", "==", schoolId)
      .where("date", ">=", timestampFrom)
      .where("date", "<", timestampTo)
      .get()
      .then((res) =>
        res.docs
          .map((d) => {
            const data = d.data();

            if (migratedRefs.includes(d.id)) {
              return undefined;
            }

            return { ...data, id: d.ref.id };
          })
          .filter((d) => d)
      )
      .catch((e) => console.log("Error getting meeting docs", e?.message))) ||
    [];

  const orderDocs: Array<OrderPersonal> = [...newOrderDocs, ...oldOrderDocs];

  // console.log(meetingDocs);
  let firstDate = undefined;
  if (timestampFrom.seconds === 0) {
    const allDocs = [...individualDocs, ...orderDocs];

    if (allDocs.length > 0) {
      firstDate = Math.min(
        ...allDocs.map((ad) => ad?.date?.seconds).sort((a, b) => a - b)
      );
    }
  }

  //   Calculate
  const flexCost = calculateFlex({
    individualDocs,
    bulkDocs,
  });

  // Calculate all orders (møde type) with variants
  const orderCost = orderDocs.reduce((acc, order) => {
    return acc + (calculateOrderTotal(order.order || {}) || 0);
  }, 0);

  const { deliveryCost, singleCost, days } = calculateDelivery({
    school,
    individualDocs,
    bulkDocs,
  });

  const totalCost = flexCost + orderCost + deliveryCost;

  const handleSolutions = async (prom) => Promise.all(prom);

  try {
    // const flexItemsPromise = ; // individual + bulk = flexModules
    const flexItems = (await handleSolutions(
      groupItems([...individualDocs, ...bulkDocs], "flexModules", db)
    )) as unknown as Array<InvoiceDataItemProps>;

    const orderItems = (await handleSolutions(
      groupItems(orderDocs, "products", db, true)
    )) as unknown as Array<InvoiceDataItemProps>;

    return {
      flexCost,
      flexItems,
      /** Kanpla 3.0 */
      orderCost,
      /** Kanpla 3.0 */
      orderItems,
      /** @deprecated */
      meetingCost: false,
      /** @deprecated */
      meetingItems: [],
      deliveryCost,
      singleDeliveryCost: singleCost,
      deliveryDays: days,
      totalCost,
      firstDate,
    };
  } catch (e) {
    console.log("Error calculating bill", e);
    return {};
  }
};
