import { FirestoreDocument } from "@kanpla/types";
import { isEmpty } from "lodash";
import { db } from "../firebase.config";
import { fetchDocument } from "./fetchDocument";
import { Firestore } from "@google-cloud/firestore";

interface Options {
  db: Firestore;
  includeRef?: boolean;
}

/**
 * Fetch multiple documents in the same collection by id
 * @typeParam T You can define a generic type here
 * @param collection The name of the collection of the query
 * @param docIds An array of document ids
 * @returns An array of documents data
 */
export const fetchMultipleDocuments = async <T extends FirestoreDocument>(
  collection: string,
  docIds: string[],
  options?: Options
): Promise<Array<T>> => {
  try {
    const filteredIds = docIds.filter((id) => id);

    if (isEmpty(filteredIds)) return [];

    // If we need to fetch more than 10 documents
    const dataInPromises = filteredIds.flat().map((id) =>
      fetchDocument<T>(
        (options?.db || db).collection(collection).doc(id),
        options?.includeRef,
        {
          allowUnknown: true,
        }
      )
    );

    return Promise.all(dataInPromises);
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export default fetchMultipleDocuments;
