import { Order } from "@kanpla/types";
import { isEmpty } from "lodash";
import { calculateOrderTotal } from "./calculateOrderTotal";

export const calculateMultipleOrdersTotal = (orders: Order[]) => {
  if (isEmpty(orders)) return 0;

  let totalPrice = 0;
  orders.forEach((order) => {
    totalPrice += calculateOrderTotal(order.order);
  });

  return totalPrice;
};
