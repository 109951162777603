/* eslint-disable @typescript-eslint/no-namespace */
import React, { FC } from "react";
import { Navigation, NavItemProps } from "./layout/Navigation";
import { Dashboard as ImportDashboard } from "./layout/Dashboard";
import ImportHeader from "./layout/Header";
import ImportTable from "./layout/Table";
import { BooleanDisplay as ImportBooleanDisplay } from "./components/BooleanDisplay";
import { IdDisplay as ImportIdDisplay } from "./components/IdDisplay";
import { RawDataRenderer as ImportRawDataRenderer } from "./components/RawDataRenderer";
import { EditableList as ImportEditableList } from "./components/EditableList";
import { IconDisplay as ImportIconDisplay } from "./components/IconDisplay";
import { UserDisplay as ImportUserDisplay } from "./components/UserDisplay";

interface Props {
  slug: string;
  navItems: Array<NavItemProps>;
}

export namespace Panel {
  export const Panel: FC<Props> = ({ slug, navItems, children }) => {
    return (
      <div className="grid grid-cols-5 grid-rows-5 absolute inset-0">
        <Navigation selectedItem={slug} items={navItems} />
        {children}
      </div>
    );
  };

  // Layout
  export const Dashboard = ImportDashboard;
  export const Header = ImportHeader;
  export const Table = ImportTable;

  // Components
  export const RawDataRenderer = ImportRawDataRenderer;
  export const EditableList = ImportEditableList;
  export const BooleanDisplay = ImportBooleanDisplay;
  export const IdDisplay = ImportIdDisplay;
  export const IconDisplay = ImportIconDisplay;
  export const UserDisplay = ImportUserDisplay;

  export type NavItemType = NavItemProps;

  export interface FilterProps {
    label: string;
    fn: (data: any) => boolean;
  }
}
