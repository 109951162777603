import { List, Select } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import React, { useState } from "react";
import { ModalButton } from "../../elements/ModalButton";

interface Props<T> {
  items: Array<T>;
  extraItems?: Array<DefaultOptionType>;
  buttonLabel?: string;
  renderItem: (item: T) => JSX.Element;
  onAdd?: (id: string) => void;
}

export const EditableList = <T,>({
  items,
  renderItem,
  buttonLabel = "Add",
  extraItems,
  onAdd = () => null,
}: Props<T>) => {
  const [selected, setSelected] = useState<string>(null);
  return (
    <List
      bordered
      dataSource={items}
      renderItem={(data) => {
        const item = renderItem(data);
        if (!item) return null;

        return <List.Item>{item}</List.Item>;
      }}
      footer={
        extraItems && (
          <ModalButton
            buttonProps={{
              className: "w-full",
              type: "dashed",
              size: "large",
            }}
            modalProps={{
              onOk: () => {
                if (!selected) throw new Error("Nothing selected");
                onAdd(selected);
              },
            }}
            label={buttonLabel}
          >
            <Select
              options={extraItems}
              onChange={setSelected}
              className="w-full"
            />
          </ModalButton>
        )
      }
    />
  );
};
