import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CombinedOfferItem,
  FlexBulkStandard,
  OrderInfo,
  OrderOrder,
} from "@kanpla/types";
import { message } from "antd";
import classnames from "classnames";
import { set, unset } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  selectedProducts: {
    [key: string]: {
      id: string;
      dateSeconds: string;
    };
  };
  date: string;
  selectProductByDay: ({
    product,
    dateSeconds,
    noLunch,
  }: {
    dateSeconds: string;
    product?: CombinedOfferItem;
    noLunch?: boolean;
  }) => void;
  full?: boolean;
  submit: (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string
  ) => Promise<void>;
  setStandardWeek?: Dispatch<SetStateAction<FlexBulkStandard["standard"]>>;
  isBulk: boolean;
  fromStandard: boolean;
  isPast: boolean;
}

export const NoLunchButton = ({
  full = false,
  selectedProducts,
  date,
  selectProductByDay,
  submit,
  setStandardWeek,
  isBulk,
  fromStandard,
  isPast,
}: Props) => {
  const { t } = useTranslation(["translation", "libs"]);

  const todayString = useMemo(
    () => moment.unix(Number(date)).format("dddd"),
    [date]
  );

  const isNoLunch = useMemo(() => {
    return (
      selectedProducts?.[date]?.id === "noLunch" &&
      selectedProducts?.[date]?.dateSeconds === date
    );
  }, [JSON.stringify(selectedProducts), date]);

  const wrapperStyle = {
    style: {
      width: full ? "100%" : "calc(33.333333% - 24px)",
    },
  };

  const setNoLunch = async () => {
    try {
      await submit({}, {}, date);
      message.success({
        key: "success-flex-order",
        content: t("libs:message.success.success-flex-order", {
          value: todayString,
        }),
      });
    } catch (error) {
      message.error({
        key: "error-flex-order",
        content: error || t("translation:something-went-wrong"),
      });
    }
  };

  const updateStandard = ({ amount, productId, dayIndex }) => {
    setStandardWeek((oldValues) => {
      const newStandard = {
        ...(oldValues || {}),
      } as FlexBulkStandard["standard"];
      if (!isBulk) unset(newStandard, `${dayIndex}`);
      if (productId) set(newStandard, `${dayIndex}.${productId}`, { amount });
      return newStandard;
    });
  };

  const wrapperClassNames = useMemo(() => {
    return classnames({
      "opacity-30 !border pointer-events-none": isPast,
      "border-2 border-primary-main": isNoLunch && !isPast && !fromStandard,
      "border-2 border-blue-600": isNoLunch && !isPast && fromStandard,
    });
  }, [isNoLunch, fromStandard, isPast]);

  const iconClassNames = useMemo(() => {
    return classnames({
      "bg-primary-main": isNoLunch && !isPast && !fromStandard,
      "bg-blue-600": isNoLunch && !isPast && fromStandard,
      "bg-secondary-dark": !isNoLunch || isPast,
    });
  }, [isNoLunch, fromStandard, isPast]);

  return (
    <div
      onClick={async () => {
        selectProductByDay({
          noLunch: true,
          dateSeconds: date,
        });

        if (fromStandard) {
          const dayIndex = Object.keys(selectedProducts)?.findIndex(
            (dateSeconds) => dateSeconds === date
          );
          updateStandard({ amount: 1, dayIndex, productId: "no_lunch" });

          return;
        }

        await setNoLunch();
      }}
      className={`px-5 py-4 mb-3 rounded transition-all bg-white flex items-center justify-between gap-x-8 cursor-pointer border self-start ${wrapperClassNames}`}
      {...wrapperStyle}
    >
      <div
        style={{
          width: "calc(100% - 32px - 32px)",
        }}
        className="flex gap-x-2"
      >
        <p
          style={{
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {t("translation:no-breakfast")}
        </p>
      </div>
      <div
        style={{
          minWidth: "32px",
        }}
        className={`w-8 aspect-square rounded-full self-center flex items-center justify-center ${iconClassNames}`}
      >
        {isNoLunch && !isPast && (
          <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
        )}
      </div>
    </div>
  );
};
