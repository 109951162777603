import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { ElasticApiKeys } from "@kanpla/types";
import { message } from "antd";
import { callFunction } from "../firebase.config";
import { decryptElasticApi } from "./useConnector";

interface Options {
  /** Do not render an ANTD message on error, defaults to true */
  renderError?: boolean;
}

export const getElasticConnector = async (
  userId: string,
  index: string,
  apiKeyName: ElasticApiKeys,
  options: Options = {}
) => {
  try {
    if (!userId) throw new Error("You have to send a user ID in!");
    if (!index)
      throw new Error("You have to let us know what index to search in!");

    //  Fetch Elastic API key
    const hashedApiKey = await callFunction("elastic-getElasticApiKey", {
      userId,
      requestedApiKey: apiKeyName,
    });

    const apiKey = decryptElasticApi(hashedApiKey);

    const connector = new ElasticsearchAPIConnector({
      host: "https://kanpla-data-storage.es.europe-west1.gcp.cloud.es.io", // host url for the Elasticsearch instance
      index: index, // index name where the search documents are contained
      apiKey,
    });

    return connector;
  } catch (e) {
    console.error(e);
    const renderError = options?.renderError ?? true;
    renderError && message.error(e?.message);
  }
};
