import { useFetch } from "./useFetch";
import { useRequest } from "./useRequest";

type SetData = ReturnType<typeof useFetch>["setData"];

interface Props<RequestData, ResponseType, SetDataType extends SetData> {
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string;
  /** Function from useFetch to optimistically update data */
  setData: SetData;
  /** Construct request to the server from submitted data */
  requestConstructor: (newData: ResponseType) => RequestData;
  /** Take response from teh server (must have same format as submitted data), and update state with it */
  responseDestructor: (
    response: ResponseType
  ) => ReturnType<Parameters<SetDataType>[2]>;
}

export const useSubmit = <
  RequestData,
  ResponseType,
  SetDataType extends SetData
>(
  props: Props<RequestData, ResponseType, SetDataType>
) => {
  const { path, setData, requestConstructor, responseDestructor } = props;

  // Actual call to the server
  const { request, isSubmitting } = useRequest<RequestData, ResponseType>(path);

  // Wrapped as an optimistic update
  const submit = async (submitData: ResponseType) => {
    const serverRequestLoad = requestConstructor(submitData);

    setData<ResponseType>(
      submitData,
      async () => await request(serverRequestLoad),
      responseDestructor
    );
  };

  return { submit, isSubmitting };
};
