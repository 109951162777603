import Head from "next/head";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const CompanySuppliers = () => {
  const { customBranding } = useContainer(AppContext);
  const appStoreId = customBranding?.appstoreId;

  return (
    <Head>
      <title>
        {customBranding?.name
          ? `${customBranding.name} | powered by Kanpla`
          : "Kanpla"}
      </title>
      <link rel="shortcut icon" href={customBranding?.favicon} />
      {appStoreId && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${appStoreId.replace("id", "")}`}
        />
      )}
    </Head>
  );
};

export default CompanySuppliers;
