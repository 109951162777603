import moment from "moment";

interface Props {
  dateSeconds: string;
  todayString: string;
}

/**
 * This method returns a string that represent a day (current or not).
 * E.g.: today is August 22 -> Monday (Today) and August 23 will be -> Tuesday
 */
export const displayDayString = ({ dateSeconds, todayString }: Props) => {
  const isToday = Number(dateSeconds) === moment().startOf("day").unix();

  const day = `${moment.unix(Number(dateSeconds)).format("dddd [d. ]DD/MM")}${
    isToday ? ` (${todayString})` : ""
  }`;

  return day;
};
