import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupConstructor, Supplier } from "@kanpla/types";
import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CarouselContext, Dot, ButtonNext } from "pure-react-carousel";
import tinycolor from "tinycolor2";
import { Logo } from "@kanpla/ui";

interface Props {
  popup: PopupConstructor.Fullscreen;
  supplier: Supplier;
  onLastScreen?: () => void | Promise<void>;
  onFinish: () => void;
  hideSystemScreens?: boolean;
  /** Shows the carousel in a desktop-friendly way */
  desktopView?: boolean;
  /** Loading state for the last button of the custom screens */
  lastButtonLoading?: boolean;
}

export const Screen = (props: Props) => {
  const {
    popup,
    supplier,
    onFinish,
    onLastScreen = () => null,
    hideSystemScreens = false,
    desktopView = false,
    lastButtonLoading = false,
  } = props;

  const carouselContext = useContext(CarouselContext);

  const [title, text, image, modulesPreview] = popup?.blocks || [];

  // App logo
  const appLogo = popup?.logo;
  const showAppLogo = appLogo?.visible || false;
  const isMonotone = appLogo?.style === "white" || appLogo?.style === "dark";

  const screenShadowColor = popup?.screenShadowColor || "#000000";
  const screenTextColor = popup?.screenTextColor || "#FFFFFF";
  const hasModulesPreview = modulesPreview?.options.length > 0;
  const backgroundImage =
    image?.src ||
    "https://images.unsplash.com/photo-1567620905732-2d1ec7ab7445?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=960&q=80";

  const buttonSize = desktopView ? "middle" : "large";

  const [isLastScreen, setIsLastScreen] = useState(false);

  useEffect(() => {
    function onChange() {
      setIsLastScreen(
        carouselContext.state.currentSlide + 1 ===
          carouselContext.state.totalSlides - (hideSystemScreens ? 0 : 2)
      );
    }
    onChange();
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const handleOnLastButtonClick = async () => {
    await onLastScreen();
    onFinish();
  };

  const sizes = {
    h1: "h700",
    h2: "h600",
    h3: "h500",
    h4: "h400",
  };

  return (
    <div
      className="w-full h-full relative p-10"
      style={{
        backgroundColor: supplier?.color || "#FFFFF",
        backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${tinycolor(
          screenShadowColor
        ).toRgbString()} 88%), url("${backgroundImage}")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full h-full flex flex-col">
        {/* Top */}
        {showAppLogo && (
          <div className="w-full flex justify-center items-center">
            <div
              className={`w-${appLogo?.size || "40"} mt-8 ${
                appLogo?.withBackground
                  ? "bg-white rounded-md p-4 box-content"
                  : ""
              }`}
            >
              <Logo
                style={{
                  filter: isMonotone
                    ? `brightness(0) ${
                        appLogo?.style === "white" ? "invert(1)" : ""
                      }`
                    : "",
                }}
              />
            </div>
          </div>
        )}
        {/* Middle */}
        <div
          className="flex flex-col w-full h-full justify-end mb-10 tracking-wide"
          style={{ color: screenTextColor }}
        >
          <h1
            className={`${sizes[title?.size || "h2"]} font-bold mb-2`}
            style={{ color: screenTextColor, letterSpacing: "0.025em" }}
          >
            {title?.text || "Coor"}
          </h1>
          {(text?.text || "Example company").split("\n").map((paragraph) => (
            <p className="mt-1 md:text-base text-lg leading-relaxed">
              {paragraph}
            </p>
          ))}
          {hasModulesPreview && (
            <div className="w-full flex mt-8 flex-col">
              {modulesPreview.options.map(({ moduleId, moduleName, icon }) => {
                return (
                  <div
                    key={moduleId}
                    className="flex items-center mb-5 md:mb-3"
                  >
                    <div className="w-12 md:w-10 md:h-10 h-12 px-4 flex items-center justify-center rounded-full bg-primary-main mr-4 md:mr-2">
                      <FontAwesomeIcon
                        size={desktopView ? "1x" : "lg"}
                        icon={icon}
                      />
                    </div>
                    <h1 className="text-lg md:text-base text-white font-semibold">
                      {moduleName}
                    </h1>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {/* Bottom */}
        <div className="w-full mb-6 flex justify-between items-center h-8">
          <nav className="w-1/2 flex items-center">
            {/* Subtract the system slides from the count */}
            {Array(
              carouselContext.state.totalSlides - (hideSystemScreens ? 0 : 2)
            )
              .fill("-")
              .map((_, index) => (
                <Dot
                  slide={index}
                  key={index}
                  className="mx-1 h-4 w-4 rounded-full flex items-center justify-center opacity-30 disabled:opacity-100 transition-opacity"
                  style={{ outline: "none" }}
                >
                  <span className="w-2 h-2 bg-primary-contrast rounded-full"></span>
                </Dot>
              ))}
          </nav>
          <div className="w-1/2 flex justify-end">
            {isLastScreen ? (
              <Button
                size={buttonSize}
                loading={lastButtonLoading}
                className="md:px-4 px-8"
                onClick={handleOnLastButtonClick}
              >
                Færdig
              </Button>
            ) : (
              <ButtonNext>
                <Button size={buttonSize} className="md:px-4 px-8">
                  Næste
                </Button>
              </ButtonNext>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
