import { deconstructSlugs } from "@kanpla/system";
import { useRouter } from "next/router";
import React from "react";
import SVG from "react-inlinesvg";
import { Logo } from "..";

interface Props {
  type?: "default" | "inverted" | "white";
  className?: string;
  /** Overrides secondary colors to match primary */
  monotone?: boolean;
  style?: React.CSSProperties;
  centered?: boolean;
}

/** WORKS ONLY ON FRONTEND! */
export const NewLogo = ({
  type = "default",
  className = "",
  monotone,
  style = {},
  centered,
}: Props) => {
  // Load schoolId from url
  const router = useRouter();
  const slugs = router.query?.slugs as unknown as Array<string>;
  const { schoolId } = deconstructSlugs({
    slugs,
  });

  return (
    <SVG
      src={schoolId ? `/api/logo?schoolId=${schoolId}` : "/api/logo"}
      height="auto"
      title="Logo"
      className={`logo-${type} ${monotone ? "monotone" : ""} ${className}`}
      style={{
        width: "100%",
        height: "100%",
        maxHeight: 120,
        maxWidth: 120,
        ...style,
      }}
      onError={() => null}
    >
      <div
        className={`logo-${type} ${monotone ? "monotone" : ""} ${className}`}
        style={{
          maxHeight: 120,
          maxWidth: 120,
          ...style,
        }}
      >
        <Logo
          center={centered}
          dark={type !== "default"}
          white={type === "white"}
        />
      </div>
    </SVG>
  );
};
