import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  IBaseProducts,
  ISelectProductByDayProps,
  Supplier,
} from "@kanpla/types";
import { message } from "antd";
import classnames from "classnames";
import { set, unset } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ProductItemInfo from "./ProductItemInfo";

interface Props {
  selectedProducts: IBaseProducts;
  date: string;
  selectProductByDay: (props: ISelectProductByDayProps) => void;
  product: CombinedOfferItem;
  isSingleCategory?: boolean;
  fromStandard?: boolean;
  full?: boolean;
  hidePrices?: boolean;
  schoolId?: string;
  setSelectedProduct: Dispatch<SetStateAction<CombinedOfferItem>>;
  setOpenVariants: Dispatch<SetStateAction<boolean>>;
  setOpenProductInfos: Dispatch<SetStateAction<boolean>>;
  setDayDateSeconds: Dispatch<SetStateAction<string>>;
  onPurchase?: (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => Promise<void>;
  setStandardWeek?: Dispatch<SetStateAction<FlexBulkStandard["standard"]>>;
  isBulk?: boolean;
  isPast?: boolean;
  isItemFromStandard?: boolean;
  supplier?: Supplier;
}

export const ProductItem = ({
  full = false,
  isSingleCategory = false,
  isItemFromStandard = false,
  selectProductByDay,
  product,
  date,
  selectedProducts,
  fromStandard,
  hidePrices,
  setSelectedProduct,
  setOpenVariants,
  setOpenProductInfos,
  setDayDateSeconds,
  onPurchase,
  setStandardWeek,
  isBulk,
  isPast,
  supplier,
}: Props) => {
  const { i18n, t } = useTranslation(["translation", "libs"]);

  moment.locale(i18n.language);

  const todayString = useMemo(
    () => moment.unix(Number(date)).format("dddd"),
    [date]
  );

  const isProductSelected = useMemo(() => {
    return (
      selectedProducts?.[date]?.id === product.id &&
      selectedProducts?.[date]?.dateSeconds === date
    );
  }, [JSON.stringify(selectedProducts), date, product.id]);

  const isNotNoLunch = useMemo(() => {
    return (
      selectedProducts?.[date]?.id !== "noLunch" &&
      selectedProducts?.[date]?.dateSeconds === date
    );
  }, [JSON.stringify(selectedProducts), date]);

  const wrapperStyle: { style: React.CSSProperties } = {
    style: {
      width: isSingleCategory && !full ? "calc(33.333333% - 24px)" : "100%",
    },
  };

  const iconStyle = useMemo(
    () => ({
      minWidth: "32px",
    }),
    []
  );

  const { wrapperClassNames, selectIconClassNames, selectBorderClassNames } =
    useMemo(() => {
      return {
        wrapperClassNames: classnames({
          "opacity-30 pointer-events-none": isPast,
          "": !isSingleCategory || isNotNoLunch,
        }),
        selectIconClassNames: classnames({
          "bg-blue-600": fromStandard || isItemFromStandard,
          "bg-primary-main": !fromStandard,
        }),
        selectBorderClassNames: classnames({
          "border-blue-600": fromStandard || isItemFromStandard,
          "border-primary-main": !fromStandard,
        }),
      };
    }, [
      JSON.stringify(selectedProducts),
      isSingleCategory,
      date,
      fromStandard,
      isItemFromStandard,
      isNotNoLunch,
      isPast,
    ]);

  const onOrder = async () => {
    try {
      await onPurchase(product, { optionChoices: {} } as any, date);
      selectProductByDay({
        product,
        dateSeconds: date,
      });
      message.success({
        key: "success-flex-order",
        content: t("libs:message.success.success-flex-order", {
          value: todayString,
        }),
      });
    } catch (error) {
      message.error({
        key: "error-flex-order",
        content: t("translation:something-went-wrong"),
      });
    }
  };

  const updateStandard = ({ amount, productId, dayIndex }) => {
    setStandardWeek((oldValues) => {
      const newStandard = {
        ...(oldValues || {}),
      } as FlexBulkStandard["standard"];
      if (!isBulk) unset(newStandard, `${dayIndex}`);
      if (productId) set(newStandard, `${dayIndex}.${productId}`, { amount });

      return newStandard;
    });
  };

  useEffect(() => {
    if (fromStandard && setStandardWeek) {
      const dayIndex = Object.keys(selectedProducts)?.findIndex(
        (dateSeconds) => dateSeconds === date
      );

      const productId =
        Object.entries(selectedProducts).find(
          ([dateSeconds, _]) => dateSeconds === date
        )?.[1]?.id === "noLunch"
          ? "no_lunch"
          : Object.entries(selectedProducts).find(
              ([dateSeconds, _]) => dateSeconds === date
            )?.[1]?.id;

      updateStandard({ amount: 1, dayIndex, productId });
    }
  }, [selectedProducts]);

  const onClickProduct = async () => {
    switch (true) {
      case (product?.options?.length && isItemFromStandard && !fromStandard) ||
        (product?.options?.length && !isItemFromStandard && !fromStandard): {
        setDayDateSeconds(date);
        setOpenVariants(true);
        return;
      }
      case !product?.options?.length && isItemFromStandard && !fromStandard: {
        await onOrder();
        return;
      }
      case fromStandard: {
        selectProductByDay({
          product,
          dateSeconds: date,
        });
        return;
      }
      case !product?.options?.length: {
        await onOrder();
        return;
      }
    }
  };

  return (
    <div
      onClick={async (event) => {
        event.stopPropagation();
        setSelectedProduct(product);

        await onClickProduct();
      }}
      className={`pr-5 pl-7 py-4 mb-3 rounded transition-all bg-white flex items-start justify-between gap-x-8 cursor-pointer relative overflow-hidden ${
        isProductSelected ? `border-2 ${selectBorderClassNames}` : "border"
      } ${wrapperClassNames}`}
      {...wrapperStyle}
    >
      <div
        className="w-2 h-full absolute inset-y-0 left-0 bg-divider-main"
        style={{ backgroundColor: product?.color }}
      />
      <ProductItemInfo
        product={fromStandard ? { ...product, dates: {} } : product}
        hidePrices={hidePrices}
        date={date}
        supplier={supplier}
      />
      {isProductSelected ? (
        <div
          style={iconStyle}
          className={`w-8 aspect-square rounded-full self-start mt-2 flex items-center justify-center ${selectIconClassNames}`}
        >
          <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
        </div>
      ) : (
        <div
          style={iconStyle}
          className="w-8 aspect-square rounded-full bg-secondary-dark self-start mt-2"
        ></div>
      )}
    </div>
  );
};
