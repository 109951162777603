/* eslint-disable react-hooks/rules-of-hooks */
import { FlexBulkStandard } from "@kanpla/types";
import { useCollectionListener } from "../firestore/UseCollectionListener";

interface Props {
  schoolId: string;
  moduleId: string;
  childId: string;
  /** For permissions for individual standard */
  userId: string;
  /** If true, looking for bulk standard */
  isBulk?: boolean;
  /** Database provider */
  db: any;
  /** Required for flexBulk */
  groupName?: string;
}

export const getFlexStandard = (props: Props) => {
  const { schoolId, moduleId, childId, isBulk, db, userId, groupName } = props;
  const bulkRef = db
    .collection("orderStandards")
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where("groupName", "==", groupName || null);
  const individualRef = db
    .collection("flexStandards")
    .where("childId", "==", childId || null)
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where("userId", "==", userId || null);

  const ref = isBulk ? bulkRef : individualRef;

  const [settings, standardLoading] =
    useCollectionListener<FlexBulkStandard>(ref);

  const standard = (settings?.[0]?.standard ||
    {}) as FlexBulkStandard["standard"];

  return { standard, standardLoading };
};
