import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addWeek,
  getMondaySeconds,
  getWeekArray,
  getWeekSeconds,
  subtractWeek,
  Timestamp,
  weekToDisplayString,
} from "@kanpla/system";
import { DayIndex, Week } from "@kanpla/types";
import { Button, DatePicker } from "antd";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  week: Week;
  setWeek: Dispatch<SetStateAction<Week>>;
  dayIndex: DayIndex;
  setDayIndex: Dispatch<SetStateAction<DayIndex>>;
}

export const WeekNavbarSwitch = ({ week, setWeek }: Props) => {
  const { t, i18n } = useTranslation(["translation", "libs"]);
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });
  const buttonStyles =
    "px-6 h-auto flex items-center justify-center rounded hover:bg-main-600 transition-all flex-grow-0 flex-shrink-0";

  const adjustWeek = (ahead: boolean) => {
    const newWeek = ahead ? addWeek(week) : subtractWeek(week);
    return setWeek(newWeek);
  };

  const weekSeconds = week[0]?.seconds;

  const weekRelative = useMemo(() => {
    const currentSeconds = getWeekSeconds(moment().unix());
    const weeksAhead = (weekSeconds - currentSeconds) / 604800;
    const displayString =
      weeksAhead === 0
        ? t("translation:this-week")
        : weeksAhead === 1
        ? t("translation:next-week")
        : weeksAhead > 1
        ? t("libs:in-weeks", { value: Math.abs(weeksAhead) })
        : t("libs:plural.week", {
            count: Math.abs(weeksAhead),
            value: Math.abs(weeksAhead),
          });
    return displayString;
  }, [weekSeconds, i18n.language]);

  return (
    <div className="justify-between border rounded-lg w-full max-w-sm bg-main-100 items-stretch flex">
      <button className={buttonStyles} onClick={() => adjustWeek(false)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      <button className="block text-center mx-1 md:mx-2 relative rounded hover:bg-main-600 transition-all flex-grow cursor-pointer md:w-40 px-2 py-2">
        <div className="text-lg -mb-1 font-semibold text-primary-main">
          {weekRelative}
        </div>
        <div className="text-xs text-text-secondary">
          {weekToDisplayString(week, t("libs:week"))}
        </div>

        <style>
          {`.hidden-input .ant-picker {
            height: 100% !important;
            width: 100% !important;
            opacity: 0;
          }`}
        </style>
        <div className="absolute inset-0 hidden-input cursor-pointer">
          <DatePicker
            dropdownAlign={{ points: ["tc", "bc"] }}
            className="cursor-pointer"
            value={moment.utc(week[0].seconds * 1000)}
            onChange={(newValue) => {
              const newSeconds =
                newValue.utc().startOf("week").valueOf() / 1000;
              const newWeek = getWeekArray(newSeconds, Timestamp);
              setWeek(newWeek);
            }}
            allowClear={false}
            format={(m) => weekToDisplayString(week, t("libs:week"))}
            picker="week"
            bordered={false}
            style={{ border: 0, width: 150 }}
            renderExtraFooter={() => (
              <Button
                size="small"
                className="w-full text-sm"
                type="dashed"
                onClick={() =>
                  setWeek(
                    getWeekArray(getMondaySeconds(moment().unix()), Timestamp)
                  )
                }
              >
                {t("libs:for-that-week")}
              </Button>
            )}
          />
        </div>
      </button>

      <button className={buttonStyles} onClick={() => adjustWeek(true)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};
