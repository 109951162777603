import { Child, Module } from "@kanpla/types";

type Props = {
  child: Child;
  module: Module;
};

export const isDefaultReferenceValid = ({ child, module }: Props) => {
  const minLength = module?.plugins?.invoiceReference?.minLength || 0;
  const referenceValid = child?.defaultReference?.length >= minLength;
  return referenceValid;
};
