import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { FC } from "react";
import { NavItemProps } from ".";
import classnames from "classnames";

interface Props {
  item: NavItemProps;
  active?: boolean;
  disabled?: boolean;
}

export const NavItem: FC<Props> = ({ item, active, disabled }) => {
  return (
    <li
      className={`my-0.5 w-full ${
        disabled ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <Link href={item.value}>
        <span
          className={classnames({
            "text-sm hover:bg-gray-100 transition rounded-lg p-2 px-3 w-full block cursor-pointer":
              true,
            "text-text-primary": active,
            "text-text-disabled hover:text-primary-main": !active,
          })}
        >
          {item.icon && <FontAwesomeIcon icon={item.icon} className="mr-2" />}
          {item.label}
        </span>
      </Link>
    </li>
  );
};

export default NavItem;
