import { uniq } from "lodash";
import { Module } from "@kanpla/types";

type Props = {
  module: Module;
};

export const getModuleList = ({ module }: Props) => {
  const flexList = module?.list || [];
  const shopList = uniq(module?.productLines?.map((pl) => pl?.productId) || []);
  const isShop = module?.type === "mealplan";
  return isShop ? shopList : flexList;
};
