/* eslint-disable no-constant-condition */
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const CardPreview = ({ card }: any) => {
  const { t } = useTranslation(["translation"]);

  if (!card || !card?.metadata) return null;

  return (
    <div className="whitespace-no-wrap">
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={
            card.metadata.brand === "visa"
              ? faCcVisa
              : card.metadata.brand === "mastercard" || "mc"
              ? faCcMastercard
              : faCreditCard
          }
          size="2x"
          className="mr-4 text-text-primary"
        />
        <div className="text-xs text-text-primary text-left">
          <div className="mr-auto text-xs">
            {t("translation:ends-with", { value: card.metadata.last4 })}{" "}
          </div>{" "}
          {card.metadata.exp_month}/{card.metadata.exp_year}
        </div>
      </div>
    </div>
  );
};

export default CardPreview;
