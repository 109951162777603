import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Module } from "@kanpla/types";
import { ContextCard, ProductSettingsHeader } from "@kanpla/ui";
import { Form, Input, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onChange?: (newReference: string) => void;
  defaultReference?: string;
  module: Module;
  /** deprecated */
  noBg?: boolean;
  hideHeader?: boolean;
  /** Force reference to be required in the form */
  required?: boolean;
  /** Use this to adjust the form item */
  formItemName?: string;
}

interface AvailableReferencesProps {
  refs: Array<string>;
  onSet: (newRef: string) => void;
}

const AvailableReferences = ({ refs, onSet }: AvailableReferencesProps) => {
  if (!refs || isEmpty(refs)) return null;

  return (
    <div className="absolute z-10 right-1 top-1 m-px">
      <ContextCard
        easyClose
        className="right-0 w-52 overflow-auto"
        button={
          <Tooltip title="Tilføj en foruddefineret reference" placement="left">
            <button className="bg-gray-300 rounded-md px-2 h-6" type="button">
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </Tooltip>
        }
      >
        <ul>
          {refs.map((ref, key) => (
            <li key={`invoice-refs-${ref}-${key}`}>
              <button
                className="p-2 px-3 w-full text-left hover:bg-background-secondary focus:bg-background-secondary transition-colors"
                onClick={() => onSet(ref)}
                type="button"
              >
                {ref}
              </button>
            </li>
          ))}
        </ul>
      </ContextCard>
    </div>
  );
};

export const InvoiceReference = ({
  defaultReference,
  module,
  hideHeader = false,
  required = false,
  formItemName = "invoiceReference",
}: Props) => {
  const { t, i18n } = useTranslation(["libs", "translation"]);

  const availableReferences =
    module?.plugins?.invoiceReference?.availableReferences;

  const title =
    module?.plugins?.invoiceReference?.title ||
    (isEmpty(i18n) ? "Reference til firmakøb" : t("libs:reference"));
  const description =
    module?.plugins?.invoiceReference?.description ||
    (isEmpty(i18n)
      ? "F. eks. dit personale ID til bogholderiet"
      : t("translation:form.notes.default-reference-note"));

  const minCharacters = module?.plugins?.invoiceReference?.minLength || 0;
  const defaultRequired =
    module?.plugins?.invoiceReference?.defaultRequired || false;

  const finalRequired = required || defaultRequired;

  return (
    <div>
      {!hideHeader && (
        <ProductSettingsHeader
          title={title}
          subtitle={description}
          icon={faAddressCard}
          noBg
        />
      )}
      <Form.Item
        name={formItemName || "invoiceReference"}
        required={finalRequired}
        rules={[
          {
            required: finalRequired,
            message: t("libs:specific-field-required", {
              fieldName: title,
            }),
          },
          {
            min: minCharacters,
            message: t("libs:text-data.min-characters", {
              value: minCharacters,
            }),
          },
        ]}
      >
        <InvoiceReferenceField
          availableReferences={availableReferences}
          description={description}
          defaultReference={defaultReference}
        />
      </Form.Item>
    </div>
  );
};

interface InvoiceReferenceFieldProps {
  onChange?: (newReference: string) => void;
  value?: string;
  availableReferences: string[];
  description: string;
  defaultReference: string;
}

const InvoiceReferenceField = ({
  value,
  onChange,
  availableReferences,
  description,
  defaultReference,
}: InvoiceReferenceFieldProps) => {
  const update = (v: string) => {
    onChange(v);
  };

  useEffect(() => {
    if (defaultReference && defaultReference !== "" && !value) {
      return update(defaultReference);
    }
  }, [defaultReference, value]);

  return (
    <div className="relative mt-2">
      <AvailableReferences
        refs={availableReferences}
        onSet={(d) => update(d)}
      />
      <Input
        className="mb-4"
        placeholder={description}
        value={value}
        onChange={(e) => update(e.target.value)}
      />
    </div>
  );
};
