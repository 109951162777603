import { Card } from "@kanpla/types";
import { message } from "antd";
import { useEffect, useState } from "react";
import { reepayCardGetDefaultCall } from "./firebase.functions";

export const useCustomerCard = ({
  userId,
  card: receivedCard,
  cards: receivedCards,
}) => {
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(null);

  const loadCards = async () => {
    if (!userId) return;
    console.warn("loading cards");
    try {
      const res = await reepayCardGetDefaultCall();
      const { defaultCardId, cards: allCards = [] } = res.data;

      setCards(allCards || []);

      const defaultCard = allCards.find((c: Card) => c.id === defaultCardId);

      setCard(defaultCard);
    } catch (e) {
      message.error(e?.message);
      console.error(e);
    }
  };

  useEffect(() => {
    setCard(receivedCard);
  }, [receivedCard]);
  useEffect(() => {
    setCards(receivedCards);
  }, [receivedCards]);

  return { card, cards, setCard, loadCards };
};
