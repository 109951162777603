import { registerServiceWorker } from "../utils/registerServiceWorker";
import { urlBase64ToUint8Array } from "../utils/urlBase64ToUint8Array";
import { callInternalApi } from "../api/callInternalApi";
import moment from "moment";
import { useLocalstorageState } from "rooks";

const useWebPushNotifications = (userId: string) => {
  // Let's store the unix time of the last subscription fetch
  const [lastFetchedSubscriptionTime, setLastFetchedSubscriptionTime] =
    useLocalstorageState<number>("push-subscription-date", moment().unix());

  const publicVapidKey =
    "BL4Ow0pGc7zqpf7khzyatdibmg-UU8QA5sluRKzSReBVz7BJPUj52xDL4YKjbuSdAytMma0iF8VoKECJm9vmSVs";

  const doesBrowserSupportsPush = () =>
    "serviceWorker" in navigator && "PushManager" in window;

  const askPermissions = async () => {
    try {
      const result = await Notification.requestPermission();
      if (result !== "granted") throw new Error("Permissions weren't granted.");

      return true;
    } catch (err) {
      console.error(err);
    }
  };

  const checkForExistingSubscription = async (
    registration: ServiceWorkerRegistration
  ) => {
    try {
      return await registration.pushManager.getSubscription();
    } catch (err) {
      console.error(err);
    }
  };

  const subscribeUserToPush = async () => {
    try {
      // 1. Check browser support
      const supported = doesBrowserSupportsPush();
      if (!supported)
        throw new Error("This broswer does not support Push Notifications.");

      // 2. Register service worker
      const existingRegistration =
        await navigator.serviceWorker.getRegistration(
          "/push-notifications-sw.js"
        );

      if (!existingRegistration) {
        const registrationReady = await registerServiceWorker(
          "/push-notifications-sw.js"
        );
        if (!registrationReady)
          throw new Error("Service worker can't be registered.");
      }

      const registration = await navigator.serviceWorker.ready;

      const exists = await checkForExistingSubscription(registration);

      if (exists) {
        console.log("Subscription already exists", exists);
        const now = moment().unix();
        // Renew subscriptions only if a day is passed
        const shouldSkipRegistration =
          Math.abs(
            moment
              .unix(now)
              .diff(moment.unix(lastFetchedSubscriptionTime), "hours")
          ) <= 24;

        if (shouldSkipRegistration) {
          console.log(
            "Skipping registration because it has been less than a day..."
          );
          return;
        }

        // There's currently no way of knowing when the subscription expires on Chrome
        // let's fetch it again every time the user opens the app
        await exists?.unsubscribe();
      }

      // 3. Ask for permissions
      const allowed = await askPermissions();
      if (!allowed) return;

      console.log("Permissions granted!");

      // 4. Subscribe user
      const options: PushSubscriptionOptionsInit = {
        // User must see when a notification is received, silent ones are prohibited!
        userVisibleOnly: true,
        // This is the application public key, also called VAPID key
        // see: https://web.dev/push-notifications-subscribing-a-user/#applicationserverkey-option
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      };

      const pushSubscription: PushSubscription =
        await registration.pushManager.subscribe(options);

      setLastFetchedSubscriptionTime(moment().unix());

      console.log("Received PushSubscription", pushSubscription);

      // Store push subscrition to db
      // notifications-storeUserPushSubscription
      await callInternalApi("user/submitWebPushSubscription", {
        userId,
        pushSubscription: JSON.stringify(pushSubscription),
      });

      return pushSubscription;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    subscribeUserToWebPush: subscribeUserToPush,
  };
};

export default useWebPushNotifications;
