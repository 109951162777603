import { OrderOrder, School } from "@kanpla/types";

interface Props {
  school: School;
  localOrder: OrderOrder;
}

/**
 * This method check if the order amount is below to a certain minimum (Flex Bulk Minimum).
 * However, if the order amount is 0 the order will go through.
 */
export const isBelowMinimum = ({ school, localOrder }: Props) => {
  const { flexBulkMinimum } = school?.contract || {};

  if (typeof flexBulkMinimum !== `number`) return false;

  const orderAmount = Object.values(localOrder).reduce(
    (acc: number, item) => acc + item.amount,
    0
  );

  return orderAmount < flexBulkMinimum && orderAmount !== 0;
};
