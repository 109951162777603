import { getChildGroupName } from "@kanpla/system";
import { ChildSelector, School } from "@kanpla/types";
import { SelectorsInput } from "@kanpla/ui";
import React, { useEffect, useState } from "react";
import { usePreviousImmediate } from "rooks";

interface Props {
  school: School;
  onChange?: (selectors: ChildSelector) => void;
  value?: ChildSelector;
}

export const SelectorInputWrapper = ({ school, onChange, value }: Props) => {
  const [selectors, setSelectors] = useState<ChildSelector>(value);
  const previousSelector = usePreviousImmediate(selectors);

  const trigger = getChildGroupName(selectors);

  useEffect(() => {
    if (!selectors) return;
    if (getChildGroupName(selectors) === getChildGroupName(previousSelector))
      return;

    onChange(selectors);
  }, [trigger]);

  return (
    <SelectorsInput
      selectors={value}
      setSelectors={setSelectors}
      school={school}
      allowHiddenOptions={true}
    />
  );
};

export default SelectorInputWrapper;
