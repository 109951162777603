import { Module, Plugins } from "@kanpla/types";
import { isSchoolSelectorInScope } from "../productBanks/isSchoolSelectorInScope";

type Props = {
  module: Module;
  /** Optional, to filter out modules out of this scope */
  schoolId?: string;
  /** Optional, to filter out modules out of this scope */
  groupName?: string;
};

export const getActivePlugins = ({ module, schoolId, groupName }: Props) => {
  const hasProvidedScope =
    typeof schoolId === "string" || typeof groupName === "string";
  const pluginsWithScope = ["invoiceReference"];

  const activePlugins = Object.fromEntries(
    Object.entries(module?.plugins || {})
      .filter(([k, v]) => {
        const isActive = v.active === true;
        if (!isActive) return false;

        const { scope } = v as Plugins.InvoiceReference;
        const scopeNotEmpty = scope?.generatedSchoolIds > 0;
        const shouldFilterByScope =
          hasProvidedScope && pluginsWithScope.includes(k) && scopeNotEmpty;

        if (!shouldFilterByScope) return true;

        const isInScope = isSchoolSelectorInScope({
          scope,
          schoolId,
          groupName,
        });
        return isInScope;
      })
      .map(([k]) => [k, true])
  );
  if (module?.flow === "meeting") activePlugins.advancedVariants = true;

  return activePlugins;
};
