/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const CustomIntercom = () => {
  const { school, child } = useContainer(AppContext);
  const { update } = useIntercom();

  // Send user information to intercom
  useEffect(() => {
    if (!child) return;

    update({
      customAttributes: {
        childId: child?.name || null,
        childName: child?.name || null,
      },
    });
  }, [child]);

  useEffect(() => {
    if (!school) return;

    update({
      customAttributes: {
        schoolName: school?.name || null,
        schoolContactPhone: school?.contact?.tel || null,
        schoolContactEmail: school?.contact?.email || null,
        schoolContactName: school?.contact?.name || null,
      },
    });
  }, [school]);

  return null;
};

export default CustomIntercom;
