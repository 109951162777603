import classnames from "classnames";
import Lottie from "lottie-react";
import React from "react";
import * as LottieLoading from "./loading-utensils.json";

interface Props {
  loading: boolean;
}

export const FullscreenLoader = ({ loading }: Props) => {
  return (
    <div
      className={classnames({
        "": loading,
        "opacity-0 pointer-events-none": !loading,
        "fixed inset-0 transition-opacity flex items-center justify-center text-primary-main z-max":
          true,
      })}
    >
      <Lottie animationData={LottieLoading} autoPlay autoplay loop />
    </div>
  );
};
