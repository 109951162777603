/**
 * The interception threshold should be an array of numbers from 0 to 1.
 * The function returns numeric sequence consisting from `length` items from 0 to 1.
 *
 * Example: useInterceptionThreshold(3) // Returns [0, 0.5, 1]
 */
export const getInterceptionThreshold = (length = 10): number[] => {
  return Array(length)
    .fill(0)
    .map((e, index) => (index + 1) * (1 / length));
};
