import React from "react";

type I18nProps = {
  /** Language to use to format the price or the amount */
  language?: string;
  /** Dictionaries to use to translate some pieces of the formatted price */
  dictionaries?: { [key: string]: string };
};

/**
 *
 * @param value - The price that needs to be formatted
 * @param i18n - An object to establish the current currency and to display dictionaries if needed
 * @param hasMultiple - This boolean establish if the price refer to a set or a single
 * @param hidePrices - This boolean comes from a module and establish if the price need to be hidden or not
 * @param config - An object to give more custom display infos if the prices are hidden
 * @returns A formatted price
 */
export const priceFormatter = (
  value: number | string = 0,
  i18n?: I18nProps,
  hasMultiple?: boolean,
  hidePrices?: boolean,
  config?: {
    wrapper?: (price?: string) => React.ReactNode | string;
    component?: () => React.ReactNode;
    withPrice?: boolean;
  }
) => {
  /** Assign default value if a property of the config object doesn't exist */
  const {
    wrapper = () => null,
    component = () => null,
    withPrice = false,
  } = config || {};

  /** Check if the wrapper and the component return a truthy value */
  const wrapperExist = !!wrapper();
  const componentExist = !!component();

  /** Sometimes happens that the value is directly a string */
  if (typeof value === "string") return value;

  /** Format the price */
  const price = new Intl.NumberFormat(i18n?.language || "da-DK", {
    style: "currency",
    currency: "DKK",
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);

  /**
   * Hidden price but add a wrapper and a component instead of it,
   * like a dash plus an icon that display a tooltip to give a feedback to the user
   */
  if (hidePrices && !withPrice && (wrapperExist || componentExist))
    return (
      <>
        {wrapper()} {component()}
      </>
    );

  /** Here the prices are totally hidden */
  if (hidePrices && !withPrice) return "";

  /**
   * Sometimes you need to display the price within a wrapper or just the price.
   * That's because to have something more dynamic
   */
  const formattedPrice =
    wrapperExist && withPrice ? config.wrapper(price) : price;

  if (!hasMultiple) return formattedPrice;

  return i18n?.dictionaries
    ? `${i18n?.dictionaries?.from || "fra"} ${formattedPrice}`
    : formattedPrice;
};
