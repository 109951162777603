import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { groupBy } from "lodash";
import React, { FC } from "react";
import NavItem from "./NavItem";

export interface NavItemProps {
  label: string;
  value: string;
  icon?: IconProp;
  category?: string;
  disabled?: boolean;
}

interface Props {
  selectedItem: string;
  items: Array<NavItemProps>;
}

export const Navigation: FC<Props> = ({ selectedItem, items }) => {
  const groupedItems = groupBy(items, "category");

  return (
    <nav className="bg-white w-full h-full p-4 row-span-5 shadow-lg z-10 relative">
      <ul className="w-full">
        {Object.entries(groupedItems || {}).map(([categoryName, catItems]) => (
          <li key={categoryName}>
            <span className="h200 mb-1 mt-4 px-3 block text-text-disabled">
              {categoryName}
            </span>
            <ul>
              {catItems.map((item) => (
                <NavItem
                  disabled={item.disabled}
                  key={item.value}
                  item={item}
                  active={selectedItem === item.value}
                />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};
