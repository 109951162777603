import { db } from "@kanpla/system";
import { User } from "@kanpla/types";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

interface Props {
  isSaving?: number;
  user: User;
}

export const useBalance = ({ isSaving, user }: Props) => {
  const [databaseUser] = useDocumentData(
    user?.id ? db.collection("users").doc(user?.id) : null
  );
  const [balance, setBalance] = useState<number>(databaseUser?.balance);

  const updateBalance = (newBalance: number) => {
    if (isSaving === 0) setBalance(newBalance);
  };

  useEffect(() => {
    if (!user?.balance && user?.balance !== 0) return;
    updateBalance(user.balance);
  }, [user?.balance]);

  useEffect(() => {
    updateBalance(databaseUser?.balance);
  }, [databaseUser?.balance]);

  return { user, balance, setBalance };
};
