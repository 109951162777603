import { capitalizeFirstLetter } from "@kanpla/system";
import React, { FC, ReactNode } from "react";
import classnames from "classnames";

interface Props {
  name?: string | ReactNode;
  noMargin?: boolean;
  noMarginTop?: boolean;
  children: ReactNode;
  /** Pixels from top */
  top?: number | string;
}

export const Category: FC<Props> = ({
  name = "—",
  children,
  noMargin = false,
  top,
  noMarginTop,
}) => {
  return (
    <div className={noMarginTop ? "" : "mt-8"}>
      <div
        className={classnames(
          "h400 md:text-xl relative flex items-center z-10",
          !noMargin && "px-2",
          !top && "-top-3 mt-3"
        )}
        style={{ top }}
      >
        <span className="min-w-full block p-0 px-4 rounded-br-lg -ml-7 pl-8 box-content z-20">
          {typeof name === "string" ? capitalizeFirstLetter(name) : name}
        </span>
      </div>
      <div className="z-10">{children}</div>
    </div>
  );
};
