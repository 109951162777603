import { Product, Supplier } from "@kanpla/types";
import { ProductSettingsHeader, allergenList } from "@kanpla/ui";
import classnames from "classnames";
import { isEmpty, sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Allergen } from "./Allergen";

interface Props {
  allergens: Product["allergens"];
  pictograms?: Product["pictograms"];
  hideHeader?: boolean;
  customBranding?: Supplier;
  className?: string;
}

export const AllergensDisplay = ({
  allergens,
  pictograms = {},
  hideHeader = false,
  customBranding,
  className = "",
}: Props) => {
  const { t, i18n } = useTranslation(["libs"]);

  const allergenEntries = Object.entries(allergens || {}).filter(
    ([allergenName, used]) => used
  );

  const pictogramsEntries = Object.entries(pictograms || {}).filter(
    ([pictogramName, used]) => used
  );

  if ([...allergenEntries, ...pictogramsEntries].length === 0) return null;

  return (
    <div
      className={classnames("block", hideHeader ? "py-2" : "px-2", className)}
    >
      {!hideHeader && (
        <ProductSettingsHeader
          title={isEmpty(i18n) ? "Allergener" : t("libs:allergens")}
          help={
            isEmpty(i18n)
              ? "Allergenerne er kun vejledende. Kontakt venligst køkkenet vedrørende spørgsmål om allergener."
              : t("libs:info-allergens")
          }
        />
      )}
      <div className="flex-wrap -mx-1">
        {sortBy(
          [...allergenEntries, ...pictogramsEntries],
          ([name]) => name
        ).map(([allergenName, used]) => {
          return (
            <Allergen
              key={allergenName}
              allergenName={allergenName}
              customBranding={customBranding}
              type={allergenList()?.[allergenName] ? "allergens" : "pictograms"}
            />
          );
        })}
      </div>
    </div>
  );
};
