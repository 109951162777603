import { db } from "@kanpla/system";
import { Spinner } from "@kanpla/ui";
import React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

interface Props {
  docId: string;
  collection: string;
}

const Data = ({ docId, collection }: Props) => {
  const [document, loading] = useDocumentDataOnce(
    db.collection(collection).doc(docId)
  );

  if (loading) <Spinner />;

  document?.school_ref && delete document.school_ref;

  return <pre>{JSON.stringify(document, null, 2)}</pre>;
};

export default Data;
