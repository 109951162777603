import { FlexMenu, Timestamp } from "@kanpla/types";
import { Menus } from "apps/backend/lib/flex/getMenus";
import React from "react";

export const formatDate = (date: Timestamp) => {
  const dayNames = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag"];
  const dateObject = date.toDate();
  const res = `${dayNames[dateObject.getDay() - 1]} ${dateObject.getDate()}/${
    dateObject.getMonth() + 1
  }`;
  return res;
};

interface Props {
  menus: FlexMenu[] | Menus;
  dayIndex: number;
  date: Timestamp;
  setMenus: (nextState: any) => void;
  setSaved: (nextState: boolean) => void;
  className?: string;
}

const HolidayButton = (props: Props) => {
  const { menus, dayIndex, date, setMenus, setSaved, className = "" } = props;

  const value = Object.values(menus).reduce((acc, menu) => {
    const menuValue = menu?.holidays && menu?.holidays?.[dayIndex];
    if (menuValue) return true;
    return acc;
  }, false);

  const style =
    value === false
      ? "text-text-secondary"
      : value === true
      ? "text-danger-main line-through font-bold"
      : "text-yellow-600 font-bold";

  const handleClick = () => {
    const newMenus = Object.values(menus).reduce((acc, menu) => {
      let newHolidays = menu.holidays || {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      };

      newHolidays[dayIndex] = !value;

      acc[menu.moduleId] = {
        ...menu,
        holidays: newHolidays,
      };

      return acc;
    }, {});

    setMenus(newMenus);
    setSaved(false);
  };

  return (
    <div
      className={`btn micro text-center text-sm ${className}`}
      onClick={handleClick}
    >
      <label
        className={`block py-1 cursor-pointer ${style}`}
        style={{ minWidth: 45 }}
      >
        <div className={`py-1 w-full rounded`}>{formatDate(date)}</div>
      </label>
    </div>
  );
};

export default HolidayButton;
