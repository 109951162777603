// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-comment */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/messaging";

import * as configJson from "./fb.json";
import { getAuth, connectAuthEmulator } from "firebase/auth";

export const config = configJson;

export const isReactRunningInDevelopment =
  process.env.NODE_ENV === "development";

export interface DeveloperVariables {
  emulatorMode: string;
  supplierUrl?: string;
}

export const productionDeveloperVariables: DeveloperVariables = {
  emulatorMode: "production",
  supplierUrl: undefined,
};

export const getDeveloperVariables = (): DeveloperVariables => {
  if (!isReactRunningInDevelopment) return productionDeveloperVariables; // IMPORTANT, avoids affecting production
  let developerVariables = {};
  try {
    developerVariables = require("./developerVariables.json");
    return {
      ...productionDeveloperVariables,
      ...developerVariables,
    };
  } catch (e) {
    console.log(e);
    return productionDeveloperVariables;
  }
};

export const isReactRunningInEmulator =
  getDeveloperVariables().emulatorMode === "emulateAll";

export const emulateFunctions = (app) => {
  app.functions("europe-west1").useEmulator("localhost", 5001);
  // const functions = getFunctions(getApp());
  // connectFunctionsEmulator(functions, "localhost", 5001);
};
const emulateAuth = () => {
  const newAuth = getAuth();
  connectAuthEmulator(newAuth, "http://localhost:9099");
};
const emulateStorage = () => {
  const storage = firebase.storage();
  storage.useEmulator("localhost", 8100);
  // const storage = getStorage();
  // connectStorageEmulator(storage, "localhost", 8100);
};
const emulateFirestore = () => {
  const firestoreSettings = {
    host: "localhost:8080",
    ssl: false,
    experimentalForceLongPolling: true,
  };
  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
  // @ts-ignore
  console.debug(`Using Firestore emulator: ${firestoreSettings.host}`);

  firebase.firestore().settings(firestoreSettings);
  // const db = getFirestore();
  // connectFirestoreEmulator(db, "localhost", 8080);
};

const emulateAll = (app) => {
  emulateFunctions(app);
  emulateAuth();
  emulateStorage();
  emulateFirestore();
};

const emulateAllCheck = (app) => {
  const isRunningInCypress = process.env.NX_APP_ENV === "cypress";
  const { emulatorMode } = getDeveloperVariables();
  const shouldEmulate = emulatorMode === "emulateAll" || isRunningInCypress;
  if (shouldEmulate) emulateAll(app);
};

const emulateFunctionsCheck = (app) => {
  const { emulatorMode } = getDeveloperVariables();
  const shouldEmulate = emulatorMode === "emulateFunctions";
  if (shouldEmulate) emulateFunctions(app);
};

const initializeApp = () => {
  try {
    const newApp = firebase.initializeApp(config);

    emulateAllCheck(newApp);

    return newApp;
  } catch (err) {
    console.log("Config error:", err);
  }
};

export const fb = !firebase?.apps?.length ? initializeApp() : firebase.app();

export const auth = getAuth();

emulateFunctionsCheck(fb);
export const fn = fb?.functions("europe-west1");

export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;

export const db = firebase.firestore();

export const fs = firebase.firestore;
// db.enablePersistence()

export const storage = firebase.storage();

// Start analytics (only if window is defined)
if (typeof window !== `undefined` && "measurementId" in config) {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMobileiOS =
    // @ts-ignore
    window.isRenderedInWebView || /iphone|ipod|ipad/.test(userAgent);

  // @ts-ignore
  if (isMobileiOS && window.trackingPermissionsGranted) {
    firebase.analytics();
  }

  if (!isMobileiOS) {
    firebase.analytics();
  }
}

export { firebase };

/** Constructor of type-safe onCall functions */
export const callFunction = async <FunctionType extends (data: any) => any>(
  functionName: string,
  props: Parameters<FunctionType>[0]
) => {
  const callable = fn.httpsCallable(functionName);
  const res = await callable(props);
  const data = res.data as Promise<ReturnType<FunctionType>>;
  return data;
};
