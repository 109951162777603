import { useWindowSize } from "@kanpla/system";
import { TooltipDisabledButton } from "@kanpla/types";
import { ButtonProps } from "antd";
import React, { ReactNode } from "react";
import { Lock } from "..";
import { Actions } from "../elements/BottomDrawer/Actions";
import { ModalWrapper } from "./ModalWrapper";

export interface ActionType extends ButtonProps, TooltipDisabledButton {
  label?: string | ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dataCy?: string;
}

export interface ModalProps {
  children: any;
  open: boolean;
  setOpen: (nextState: boolean) => void;
  title?: string;
  subtitle?: any;
  className?: string;
  style?: React.CSSProperties;
  containerClassName?: string;
  noPadding?: boolean;
  actions?: Array<ActionType>;
  zMax?: boolean;
  zIndex?: 20 | 30 | 40 | 50 | 60 | 100;
  actionsClassName?: string;
  closableOutside?: boolean;
  disableLock?: boolean;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    setOpen,
    title,
    subtitle,
    className = "",
    style = {},
    containerClassName = "",
    actionsClassName = "",
    noPadding = false,
    actions,
    zMax,
    zIndex = 40,
    closableOutside = true,
    disableLock = false,
  } = props;

  const { width: screenWidth } = useWindowSize();

  return (
    <ModalWrapper
      containerClassName={`py-16 flex px-3 ${containerClassName}`}
      innerClassName={`rounded-lg bg-background-primary shadow-xl relative w-full max-w-md overflow-hidden m-auto ${className}`}
      innerStyles={{
        transformOrigin: "50% 50% 0",
        ...style,
      }}
      open={open}
      setOpen={setOpen}
      dragToClose={true}
      zMax={zMax}
      zIndex={zIndex}
      closableOutside={closableOutside}
    >
      {!disableLock && screenWidth >= 768 && open && <Lock />}
      <>
        <div className={`${noPadding ? "p-0" : "p-8"} h-full`}>
          {title && (
            <div className="mb-4 relative z-10">
              <h2 className="h600 text-center">{title}</h2>
              {subtitle && (
                <h2 className="text-text-secondary text-center">{subtitle}</h2>
              )}
            </div>
          )}
          {children}
        </div>

        {actions && (
          <div
            className={`flex flex-wrap justify-center whitespace-nowrap w-full rounded-b-lg overflow-hidden ${
              actionsClassName || ""
            }`}
          >
            <Actions actions={actions} />
          </div>
        )}
      </>
    </ModalWrapper>
  );
};

export default Modal;
