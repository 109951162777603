import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@kanpla/system";
import { Child, School, Selector } from "@kanpla/types";
import { Button, Select } from "antd";
import { isEmpty, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePreviousImmediate } from "rooks";

interface Props {
  school: School;
  selectors: Child["selectors"];
  setSelectors: (newValue: Child["selectors"]) => void;
  allowHiddenOptions?: boolean;
  /** If this flag is set, the input will use the options coming from `extraSelectors` instead of the school */
  useExtraSelectors?: boolean;
  /** Custom selectors, not necessarily coming from a school */
  extraSelectors?: Selector[];
}

export const SelectorsInput = ({
  selectors,
  setSelectors,
  school,
  allowHiddenOptions = false,
  useExtraSelectors = false,
  extraSelectors = [],
}: Props) => {
  const { t, i18n } = useTranslation(["translation"]);

  const [activeSelectorIndex, setActiveSelectorIndex] = useState(0);

  const { width: screenWidth } = useWindowSize();

  const previousSchoolId = usePreviousImmediate(school?.id);

  useEffect(() => {
    setActiveSelectorIndex(Object.values(selectors || {}).length);
  }, [selectors]);

  // Empty selectors on school change
  const schoolId = school?.id;
  useEffect(() => {
    const isValid = Object.entries(selectors || {}).every(([layer, value]) => {
      const targetLayer = school?.selectors?.find((l) => l.name === layer);
      const hasTargetOption = targetLayer?.options?.some(
        (o) => o.name === value
      );
      return hasTargetOption;
    });
    if (!schoolId || isValid) return;
    if (!previousSchoolId) return;
    if (previousSchoolId === school?.id) return;
    setSelectors({});
  }, [schoolId]);

  const schoolSelectors = school?.selectors || ([] as School["selectors"]);
  const chooseSelectors = useExtraSelectors ? extraSelectors : schoolSelectors;

  const schoolsOptions = orderBy(chooseSelectors, "position");

  const onInputChange = (value: string, name: string, index: number) => {
    const selectorsToNull = schoolsOptions.filter((s, innerIndex) => {
      return innerIndex >= index;
    });

    const newSelectors = Object.entries(selectors || {}).reduce(
      (acc, [layerName, doc]) => {
        acc[layerName] = doc;

        if (selectorsToNull.map((m) => m.name).includes(layerName))
          acc[layerName] = "";

        return acc;
      },
      {}
    );
    newSelectors[name] = value;

    setSelectors(newSelectors);
  };

  const hasMoreThanOneSelector = schoolsOptions.length > 1;

  return (
    <div className="flex items-center">
      {schoolsOptions.map(({ name, options }, index: number) => {
        const allowedOptions = options.filter((option) => {
          // 1. Don't allow hidden options
          if (option?.hidden && !allowHiddenOptions) return false;

          // 2. Allow all on first index (layer)
          if (index === 0) return true;

          // 3. Don't allow disabled combinations
          const disabledEntries = Object.entries(option.disabled || {});
          const allowed = disabledEntries.reduce(
            (acc, [selectorName, disabledValues]) => {
              const selectedValue = selectors?.[selectorName];
              const shouldDisallow = disabledValues.includes(selectedValue);
              if (shouldDisallow) return false;
              return acc;
            },
            true
          );
          return allowed;
        });

        if (allowedOptions.length === 0) return null;

        const isThisAllowed = allowedOptions
          .map((option) => option.name)
          .includes(selectors && selectors[name]);

        return (
          <div
            className={`mr-1 pr-1 ${
              !hasMoreThanOneSelector ? "w-full" : "w-1/2"
            }`}
            key={name}
          >
            <div className="mb-2">
              <label
                className="capitalize text-text-secondary"
                htmlFor="grade-input"
              >
                {name}
              </label>
            </div>
            <Select
              placement={screenWidth < 768 ? "topLeft" : undefined}
              className="mt-1 block w-full"
              disabled={
                allowedOptions.length === 0 || index - 1 >= activeSelectorIndex
              }
              value={
                (isThisAllowed &&
                  selectors &&
                  selectors[name] &&
                  selectors[name]) ||
                ""
              }
              getPopupContainer={(triggerNode) => {
                return triggerNode as HTMLElement;
              }}
              onChange={(e) => onInputChange(e, name, index)}
              placeholder={!isEmpty(i18n) ? t("translation:choose") : "Vælg"}
              options={[
                {
                  label: !isEmpty(i18n) ? t("translation:choose") : "vælg",
                  value: "",
                  disabled: true,
                },
                ...allowedOptions.map((option) => ({
                  label: option.name,
                  value: option.name,
                })),
              ]}
              dropdownClassName="fixed"
              data-cy={name}
            />
          </div>
        );
      })}

      {hasMoreThanOneSelector && (
        <Button
          className="self-end text-text-primary"
          htmlType="button"
          type="ghost"
          onClick={() => setSelectors({})}
          icon={<FontAwesomeIcon icon={faBan} size="sm" className="mr-2" />}
        >
          {isEmpty(i18n) ? "Ryd feltet" : t("translation:clear")}
        </Button>
      )}
    </div>
  );
};
