interface SetTimerOptions {
  callback?: CallableFunction;
  timeout?: number;
}

/**
 * CombinedTimeout clears the previous setTimeout and uses new one.
 * All previous timeouts are being ignored.
 *
 * Usage example:
 * ```
 * let myVar = 0;
 * const timer = new CombinedTimeout();
 * timer.setTimer({ callback: () => myVar = 1, timeout: 100 });
 * timer.setTimer({ callback: () => myVar = 2, timeout: 200 });
 * ```
 * In this example the resulted timer will be the last one
 * which will fire the callback after 200 ms and will set
 * the value of `myVar` to `2`.
 */
export class CombinedTimeout {
  private timeoutId: number = null;
  private defaultCallback: CallableFunction = null;
  private defaultTimeout: number = null;


  /**
   * After setting default options(timeout and/or callback) it would be
   * possible to set a new timer with already given options.
   */
  constructor({
    timeout = 0,
    callback = null,
  }: SetTimerOptions = {}) {
    this.defaultTimeout = timeout;
    this.defaultCallback = callback;
  }

  public setTimer = ({
    callback = this.defaultCallback,
    timeout = this.defaultTimeout
  }: SetTimerOptions = {}): number => {
    if (!callback) return;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.timeoutId = setTimeout(callback, timeout);
    return this.timeoutId
  };
}
