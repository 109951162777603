import React, { FC } from "react";
import { FullscreenLoader } from "@kanpla/ui";
import { WithSearch } from "@elastic/react-search-ui";
import Table from "../Table";
import { ColumnsType, TableProps } from "antd/lib/table";

interface Props {
  columns: ColumnsType<any>;
  firstColumnProperty?: string;
  rawDataCollection?: string;
  sortFields?: Array<{ label: string; field: string }>;
  title?: string;
  /** In case the data uses "children" as in users' children, set this to true to avoid render errors */
  dataHasChildren?: boolean;
  tableProps?: TableProps<any>;
  disableFirstColumn?: boolean;
  disableLastColumn?: boolean;
}

export const Dashboard: FC<Props> = ({
  children,
  title,
  columns,
  firstColumnProperty,
  rawDataCollection,
  sortFields,
  dataHasChildren,
  tableProps = {},
  disableFirstColumn,
  disableLastColumn,
}) => {
  return (
    <WithSearch
      mapContextToProps={({ isLoading, error }) => ({ isLoading, error })}
    >
      {({ isLoading, error }) => (
        <div className="bg-white h-full p-7 py-3 col-span-4 row-span-5 overflow-y-scroll overflow-x-hidden">
          <FullscreenLoader loading={isLoading && !error} />
          {children}
          <Table
            title={title}
            columns={columns}
            firstColumnProperty={firstColumnProperty}
            rawDataCollection={rawDataCollection}
            sortFields={sortFields}
            dataHasChildren={dataHasChildren}
            tableProps={tableProps}
            disableFirstColumn={disableFirstColumn}
            disableLastColumn={disableLastColumn}
          />
        </div>
      )}
    </WithSearch>
  );
};
