import { Module } from "@kanpla/types";
import { sortBy } from "lodash";

interface WithId {
  id: string;
}

interface WithProductId {
  productId: string;
}

// type SortableProductList = WithId | WithProductId;

export function sortProducts<Type>(props: {
  /** Products you want to sort */
  items: Array<Type>;
  /** List of productIds, saved at the productBank document */
  list: Module["list"];
}): Array<Type> {
  const { items = [], list = [] } = props;

  const sorted = sortBy(items, (item) => {
    return list.indexOf(
      (item as unknown as WithProductId)?.productId ||
        (item as unknown as WithId)?.id
    );
  });

  return sorted;
}
