import {
  faCcAmex,
  faCcApplePay,
  faCcMastercard,
  faCcVisa,
  faGooglePay,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronDown,
  faCreditCard,
  faPlus,
  faRectangleHistoryCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, PaymentProvider } from "@kanpla/types";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { SelectPayment } from "../SelectPayment";

interface Props {
  card: Card;
  className?: string;
  onSelect: (selectedMethod: string) => void;
  label?: "pay" | "select";
  basketPrice?: number;
  showEditCardsButton?: boolean;
  defaultPaymentMethod?: PaymentProvider;
}

const DisplayDefaultCard = (props: Props) => {
  const {
    card,
    className = "",
    onSelect,
    label = "pay",
    basketPrice = null,
    showEditCardsButton = false,
    defaultPaymentMethod = "card",
  } = props;
  const { t } = useTranslation(["payment", "translation"]);
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymethMethod] =
    useState<PaymentProvider>(defaultPaymentMethod);

  const getPaymentMethodName = () => {
    switch (paymentMethod) {
      case "applepay":
        return "Apple Pay";
      case "googlepay":
        return "Google Pay";
      case "mobilepay":
        return "Mobile Pay";
      case "card":
        return `${
          card ? t("payment:standard-card") : t("payment:card")
        }`.toLowerCase();
      default:
        return t("payment:card");
    }
  };

  const IconImage = ({ icon }) => (
    <img
      src={`/images/${paymentMethod ? icon : "add-card-icon"}.png`}
      className={`w-8 object-contain mr-4 ${!paymentMethod ? "mt-1" : ""}`}
      alt={t("payment:card-icon")}
    />
  );

  const getPaymentIcon = () => {
    switch (paymentMethod) {
      case "applepay":
        return <FontAwesomeIcon size="lg" icon={faCcApplePay} />;
      case "googlepay":
        return <FontAwesomeIcon size="lg" icon={faGooglePay} />;
      case "mobilepay":
        return <IconImage icon={"MPay"} />;
      // @ts-ignore
      case "":
        return (
          <FontAwesomeIcon size="lg" icon={faRectangleHistoryCirclePlus} />
        );
      default:
        return <FontAwesomeIcon size="lg" icon={faCreditCard} />;
    }
  };

  return (
    <>
      <Button
        className={`w-full ${
          paymentMethod === "card" && card ? "py-2" : "py-3"
        } flex items-center justify-between cursor-pointer ${className}`}
        onClick={() => setOpen(true)}
        size="large"
      >
        <div className="flex items-center">
          {paymentMethod !== "card" || !card ? (
            <span
              className={`w-8 overflow-hidden block object-contain mr-4 ${
                !paymentMethod ? "mt-1" : ""
              }`}
            >
              {getPaymentIcon()}
            </span>
          ) : (
            <FontAwesomeIcon
              // @ts-ignore
              icon={
                card.metadata.brand === "visa"
                  ? faCcVisa
                  : card.metadata.brand === "mc"
                  ? faCcMastercard
                  : card.metadata.brand === "amex"
                  ? faCcAmex
                  : faCreditCard
              }
              size="lg"
              className="mr-4"
            />
          )}
          <div className="flex flex-col justify-start">
            <span>
              {paymentMethod
                ? `${
                    label === "pay" ? t("payment:pay-with") : ""
                  }${getPaymentMethodName()}`
                : t("payment:choose-payment-method")}
            </span>
            {paymentMethod === "card" && card && (
              <span className="text-text-secondary text-xs text-left">
                {t("translation:ends-with", { value: card.metadata.last4 })}
              </span>
            )}
          </div>
        </div>
        <FontAwesomeIcon
          icon={paymentMethod ? faChevronDown : faPlus}
          className={`${paymentMethod ? "mt-1" : ""}`}
        />
      </Button>
      <SelectPayment
        open={open}
        setOpen={setOpen}
        selectedMethod={paymentMethod}
        basketPrice={basketPrice}
        showCardButton={showEditCardsButton}
        onSelect={(method: PaymentProvider) => {
          setPaymethMethod(method);
          onSelect(method);
        }}
      />
    </>
  );
};

export default DisplayDefaultCard;
