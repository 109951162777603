import { antdFilterOptionSearch } from "@kanpla/system";
import { Badge, Space, Select, Form } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { ModalButton } from "libs/ui/src/elements/ModalButton";
import React, { FC } from "react";
import { ObjectParam, useQueryParam } from "next-query-params";
import Search from "./Search";
import { withSearch } from "@elastic/react-search-ui";
import { SearchContextState } from "@elastic/react-search-ui/lib/esm/withSearch";

interface Props extends SearchContextState {
  title: string;
  /** Provide a boolean to indicate when data has loaded to trigger some queries */
  searchQuery?: (query: string) => void;
  filterQuery?: (query: { [name: string]: Array<string> | string }) => void;
  filterOptions?: [
    {
      name: string;
      label: string;
      options: Array<DefaultOptionType>;
    }
  ];
}

const Header: FC<Props> = ({
  title,
  filterOptions,
  filterQuery,
  totalResults,
}: Props) => {
  const [filterQueryParams, setFilterQueryParams] = useQueryParam(
    "f",
    ObjectParam
  );

  // useEffect(() => {
  //   if (filterQueryParams)
  //     filterQuery(
  //       filterQueryParams as { [key: string]: string | Array<string> }
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataLoaded]);

  return (
    <header className="flex items-center justify-between sticky top-1">
      <Badge
        count={totalResults}
        overflowCount={9999}
        color="gray"
        offset={[25, 10]}
      >
        <h2 className="h400">{title}</h2>
      </Badge>
      <Space>
        {filterOptions && filterQuery && (
          <ModalButton label="Filter">
            <Form
              onValuesChange={(changed, allChanged) => {
                filterQuery(allChanged);
                setFilterQueryParams(allChanged);
              }}
              initialValues={filterQueryParams}
            >
              {filterOptions.map((option) => {
                return (
                  <Form.Item
                    name={option.name}
                    label={option.label}
                    key={option.name}
                  >
                    <Select
                      options={option.options}
                      mode="multiple"
                      filterOption={antdFilterOptionSearch}
                    />
                  </Form.Item>
                );
              })}
            </Form>
          </ModalButton>
        )}
        <Search />
      </Space>
    </header>
  );
};

export default withSearch<Props, SearchContextState>((p) => p)(Header);
