import { CombinedOfferItem, GroupedCombinedOffer, Week } from "@kanpla/types";
import { getWeekSeconds } from "@kanpla/system";

interface Props {
  items: CombinedOfferItem[];
  week: Week;
}

/**
 * This method group the offers by available day and by category.
 * Object structure:
 * {
 *   [dateSeconds]: {
 *     [category]: [offer, offer, offer],
 *     [category]: [offer, offer],
 *   },
 *   ...
 * }
 */
export const groupItemsByDayAndByCategory = ({
  items,
  week,
}: Props): GroupedCombinedOffer => {
  const thisMondaySeconds = getWeekSeconds(new Date().getTime() / 1000);

  const datesSeconds = getGroup({
    items: week as any,
    entity: "dates",
    thisMondaySeconds,
  });
  const categories = getGroup({ items, entity: "category" });

  const groupedItems = datesSeconds.reduce((acc, dateSeconds) => {
    const reducedCategories = categories.reduce(
      (acc, category) => ({ ...acc, [category]: [] }),
      {}
    );

    return {
      ...acc,
      [dateSeconds]: reducedCategories,
    };
  }, {});

  Object.entries(groupedItems).forEach(([key, value]) => {
    const availableItems = items.filter(
      (item) =>
        item.dates[key]?.available &&
        item.dates[key]?.menu?.name?.toLowerCase() !== "x"
    );

    if (availableItems.length) {
      Object.keys(value).forEach((category) => {
        const filteredAvailableItems = availableItems.filter(
          (item) => item.category === category
        );

        value[category] = filteredAvailableItems;
      });
    }
  });

  return groupedItems;
};

interface GetGroupProps {
  items: CombinedOfferItem[];
  entity: "dates" | "category";
  thisMondaySeconds?: number;
}

const getGroup = ({
  items,
  entity,
  thisMondaySeconds,
}: GetGroupProps): string[] => {
  const values = items?.reduce((acc, item) => {
    switch (entity) {
      case "dates": {
        const fridayIndex = 4;
        /** Get the Friday seconds of the week */
        const thisFridaySeconds = thisMondaySeconds + fridayIndex * 86400;

        // const datesSeconds = Object.keys(item[entity]).filter(
        //   (seconds) =>
        //     Number(seconds) >= thisMondaySeconds &&
        //     Number(seconds) <= thisFridaySeconds
        // );

        return [...acc, item["seconds"]];
      }
      case "category":
        return [...acc, item[entity]];
      default:
        return [...acc, item];
    }
  }, []);

  const uniqueValues = [...new Set(values)];

  return uniqueValues;
};
