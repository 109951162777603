import { DocumentReference } from "@firebase/firestore-types";
import moment from "moment";
import { SetDocumentOptions } from "./context";

export const setDocumentReact = async (
  ref: DocumentReference,
  data: any = {},
  merge = true,
  options?: SetDocumentOptions
) => {
  await ref.set(
    {
      ...data,
      changedAt: moment().unix(),
      changedBy: options?.userId,
    },
    { merge }
  );

  return;
};
