import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexOption,
  MenuModuleItemProps,
  Module,
  OrderConfig,
  OrderInfo,
  OrderOrder,
  Plugins,
  Supplier,
  TooltipDisabledButton,
} from "@kanpla/types";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ProductDetails } from "./ProductDetails";
import { ProductPreview } from "./ProductPreview";

export interface SingleProductProps extends CombinedOfferItem {
  /** If the product has more than one price, show "fra" on products */
  multiplePrices?: boolean;
  /** Show extra text in the price label */
  extraPriceLabelText?: string;
  moduleName?: MenuModuleItemProps["moduleName"];
  periodPrice?: number;
}

export interface ProductProps extends TooltipDisabledButton {
  product: SingleProductProps;
  isBig?: boolean;
  open?: boolean;
  setOpen?: (nextState: boolean) => void;
  backLabel?: string;
  purchaseLabel?: string;
  previewProps?: any;
  onPurchase?: (
    product: CombinedOfferItem,
    data: CustomOrderContent
  ) => Promise<void>;
  /** Option to disable amount input */
  hideAmount?: boolean;
  disabled?: boolean;
  /** Whether or not the order is placed from admin */
  fromAdmin?: boolean;
  /** Only show the product preview */
  previewOnly?: boolean;
  /** The product price before the discount (for leftovers) */
  oldPrice?: number;
  /** How many are left to buy (for leftovers)  */
  stock?: number;
  /** Overrides classes of a product block */
  customClassName?: string;
  plugins?: Plugins.Array;
  order: OrderOrder;
  orderInfo: OrderInfo;
  mealOptions: FlexOption[];
  module: Module;
  maxAmount?: number;
  initialAmount?: number;
  defaultReference?: string;
  showOrderInfo?: boolean;
  initialOptions?: OrderConfig["options"];
  hasRequiredProduct?: boolean;
  schoolId: string;
  isChildView?: boolean;
  /** Adds a banner where a countdown to the deadline is displayed */
  showDeadlineCountdown?: boolean;
  /** Used to replace default content  */
  customInputs?: ReactNode;
  /** Hide image from the product detail  */
  noImage?: boolean;
  orderButtonDisabled?: boolean;
  customBranding?: Supplier;
  /** To get extra infos for the current menu of the week */
  currentDayTimestamp?: number;
  /** To reset the state of the acceptedTerms property */
  setAcceptedTerms?: Dispatch<SetStateAction<boolean>>;
  hidePriceInLabel?: boolean;
}

export const Product = (props: ProductProps) => {
  const {
    product,
    open,
    isBig,
    setOpen,
    disabled = false,
    previewProps = {},
    previewOnly = false,
    stock = null,
    customClassName,
    currentDayTimestamp,
    setAcceptedTerms,
    customBranding,
    module,
  } = props;
  const [_open, _setOpen] = useState(false);

  useEffect(() => {
    if (typeof open === `boolean`) _setOpen(open);
  }, [open]);

  /**
   * If the modal will be closed the status of the payments will be resetted.
   */
  useEffect(() => {
    if (!_open && setAcceptedTerms) {
      setAcceptedTerms(_open);
    }
  }, [_open]);

  const updateOpen = (newOpen: boolean) => {
    _setOpen(newOpen);
    if (setOpen) setOpen(newOpen);
  };

  useEffect(() => {
    if (!product) return;
  }, [product]);

  const wrapperStyles = isBig
    ? "inline-block px-1 py-2 sm:p-2 lg:p-3 w-full sm:w-1/2 lg:w-1/2 xl:w-1/3"
    : "inline-block px-1 py-2 sm:p-2 lg:p-3 w-1/2 md:w-1/3 xl:w-1/4";

  return (
    <>
      <div
        className={
          customClassName || (previewProps?.view !== "table" && wrapperStyles)
        }
      >
        <ProductPreview
          product={product}
          isBig={isBig}
          disabled={disabled}
          stock={stock}
          onClick={() => {
            updateOpen(true);
          }}
          previewOnly={previewOnly}
          currentDayTimestamp={currentDayTimestamp}
          customBranding={customBranding}
          module={module}
          {...previewProps}
        />
      </div>
      {!previewOnly && (
        <ProductDetails
          {...props}
          currentDayTimestamp={currentDayTimestamp}
          open={_open}
          setOpen={updateOpen}
        />
      )}
    </>
  );
};
