import React, { useState, useEffect } from "react";
import {
  DeveloperVariables,
  getDeveloperVariables,
  productionDeveloperVariables,
  isReactRunningInDevelopment,
} from "@kanpla/system";
import { Select } from "antd";
import axios from "axios";
const { Option } = Select;

export const EmulatorController = () => {
  const [developerVariables, setDeveloperVariables] =
    useState<DeveloperVariables>(productionDeveloperVariables);

  useEffect(() => {
    try {
      const newDevVariables = getDeveloperVariables();
      setDeveloperVariables(newDevVariables);
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (!isReactRunningInDevelopment) return null;

  const onChange = async (newData: Partial<DeveloperVariables>) => {
    setDeveloperVariables((prev) => ({
      ...prev,
      newData,
    }));
    await axios.post("/api/setDeveloperVariables", newData);
    if (typeof window === "undefined") return;
    // window.location.reload(true);
    window.location.href = window.location.href;
  };

  return (
    <div className="z-max fixed bottom-0 left-0 ml-6 mb-6 flex gap-2">
      <Select
        value={developerVariables?.emulatorMode}
        onChange={(v) => onChange({ emulatorMode: v })}
        className="w-32"
      >
        <Option value="production">Production</Option>
        <Option value="emulateFunctions">Local functions</Option>
        <Option value="emulateAll">Emulate All</Option>
      </Select>
      <Select
        value={developerVariables?.supplierUrl || "kanpla.dk"}
        onChange={(v) =>
          onChange({ supplierUrl: v === "kanpla.dk" ? null : v })
        }
        className="w-32"
      >
        <Option value="kanpla.dk">Kanpla.dk</Option>
        <Option value="serwiz.kanpla.dk">Serwiz</Option>
        <Option value="coor.kanpla.dk">Coor</Option>
        <Option value="kokkeneskoekken.kanpla.dk">Kokkeneskoekken</Option>
        <Option value="506.kanpla.dk">Madklubben</Option>
        <Option value="dabba.kanpla.dk">DABBA</Option>
      </Select>
    </div>
  );
};
