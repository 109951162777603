import { withSearch } from "@elastic/react-search-ui";
import { SearchContextState } from "@elastic/react-search-ui/lib/esm/withSearch";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space, Table as AntdTable } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import { capitalize } from "lodash";
import React, { useEffect } from "react";
import { useWindowSize } from "rooks";
import { IdDisplay } from "../../components/IdDisplay";
import { RawDataRenderer } from "../../components/RawDataRenderer";
import Header from "../Header";

export interface PanelTableProps extends SearchContextState {
  columns: ColumnsType<any>;
  firstColumnProperty?: string;
  rawDataCollection?: string;
  sortFields?: Array<{ label: string; field: string }>;
  title?: string;
  /** In case the data uses "children" as in users' children, set this to true to avoid render errors */
  dataHasChildren?: boolean;
  tableProps?: TableProps<any>;
  disableFirstColumn?: boolean;
  disableLastColumn?: boolean;
}

const Table = ({
  columns = [],
  results,
  firstColumnProperty,
  current,
  setCurrent = () => null,
  setResultsPerPage = () => null,
  resultsPerPage,
  totalResults,
  rawDataCollection,
  sortFields = [],
  setSort,
  sortDirection,
  sortField,
  title,
  dataHasChildren,
  tableProps = {},
  disableFirstColumn,
  disableLastColumn,
}: PanelTableProps) => {
  const { innerHeight } = useWindowSize();

  useEffect(() => {
    setSort("date", "asc");
  }, []);

  const allSortFields = [
    {
      label: capitalize(firstColumnProperty || "name"),
      field: firstColumnProperty ? `${firstColumnProperty}` : "name",
    },
    ...sortFields,
  ];

  const allColumns: ColumnsType = [
    !disableFirstColumn
      ? {
          dataIndex: ["id", "raw"],
          title: capitalize(firstColumnProperty || "name"),
          render: (id: string, data) => (
            <div style={{ minWidth: 120 }}>
              <IdDisplay
                value={id}
                label={data[firstColumnProperty || "name"]?.raw}
              />
            </div>
          ),
          className: "text-sm",
          width: 250,
          fixed: "left",
        }
      : null,
    ...columns.map((c) => ({
      ...c,
      className: `${c.className || ""} text-sm`,
    })),
    !disableLastColumn
      ? {
          dataIndex: ["originalCollection", "raw"],
          title: "Raw data",
          className: "text-sm",
          render: (originalCollection, data: any) => {
            if (!rawDataCollection || !data?._meta?.id) return null;
            return (
              <RawDataRenderer
                docId={data?.["_meta"]?.id}
                collection={originalCollection || rawDataCollection}
              />
            );
          },
        }
      : null,
  ];

  return (
    <div>
      <AntdTable
        {...tableProps}
        className="mt-4 text-sm max-w-full"
        size="small"
        pagination={{
          defaultPageSize: 20,
          current: current,
          total: totalResults,
          pageSize: resultsPerPage,
          onChange: (p, size) => {
            p !== current && setCurrent(p);
            size !== resultsPerPage && setResultsPerPage(size);
          },
        }}
        columns={allColumns.filter((col) => col)}
        dataSource={
          dataHasChildren
            ? results.map(({ children, ...res }) => ({
                ...res,
                kids: children,
                tableId: res._meta?.id,
              }))
            : results.map((res) => ({
                ...res,
                tableId: res._meta?.id,
              }))
        }
        rowKey="tableId"
        scroll={{ x: true, y: innerHeight - 300 }}
        title={() => (
          <Space direction="vertical" className="w-full">
            <Header title={title} />
            <Space>
              Sort by:
              {allSortFields.map((field) => {
                const activeField = sortField === field.field;
                return (
                  <Button
                    size="small"
                    onClick={() =>
                      setSort(
                        sortDirection === "desc" ? null : field.field,
                        sortDirection === "asc"
                          ? "desc"
                          : sortDirection === "desc"
                          ? null
                          : "asc"
                      )
                    }
                    type={activeField ? "primary" : "default"}
                  >
                    {field.label}
                    {activeField ? (
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={sortDirection === "asc" ? faArrowDown : faArrowUp}
                      />
                    ) : null}
                  </Button>
                );
              })}
            </Space>
          </Space>
        )}
      />
    </div>
  );
};

export default withSearch<PanelTableProps, SearchContextState>(
  (props) => props
)(Table);
