import { DocumentReference } from "@firebase/firestore-types";
import moment from "moment";
import { SetDocumentOptions } from "./context";

export const updateDocumentReact = async (
  ref: DocumentReference,
  data: any = {},
  options?: SetDocumentOptions
) => {
  await ref.update({
    ...data,
    changedAt: moment().unix(),
    changedBy: options?.userId,
  });

  return;
};
