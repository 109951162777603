import { Module, OrderPersonal, OrderOrder } from "@kanpla/types";
import { isEmpty, sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSessionstorageState } from "rooks";
import {
  calculateMultipleOrdersItemsAmount,
  calculateMultipleOrdersTotal,
  getOrderConfigs,
} from "@kanpla/system";
import { generate } from "short-uuid";

type BasketContainer = {
  [basketId: string]: OrderPersonal;
};
type BasketOrder = OrderOrder | ((oldOrder?: OrderOrder) => OrderOrder);

interface OrderedBasket {
  [dateSeconds: string]: {
    [moduleId: string]: OrderPersonal;
  };
}

interface Props {
  userId: string;
  module: Module;
  schoolId: string;
  childId: string;
  dateSeconds: number;
}

export interface SetBasketProps {
  o: BasketOrder;
  orderInfo?: OrderPersonal["info"];
  moduleId: string;
  dateSeconds: number;
}

// Find the concrete order we are editing right now
const isTargetOrder = (
  o: OrderPersonal,
  moduleId: string,
  dateSeconds: number,
  schoolId: string,
  childId: string
) =>
  o.moduleId === moduleId &&
  o.dateSeconds === dateSeconds &&
  o.childId === childId &&
  o.schoolId === schoolId;

// Get all orders that should show in the current basket
const shoudShow = (
  o: OrderPersonal,
  paymentMethod: Module["paymentMethod"],
  schoolId: string,
  childId: string
) =>
  o.paymentMethod === paymentMethod &&
  o.schoolId === schoolId &&
  o.childId === childId;

// State holding all orders across whole system
const useBasketContainer = ({ userId }) => {
  const [basketContainer, setBasketContainer] = useState<BasketContainer>({});

  const [sessionBasket, setSessionBasket] =
    useSessionstorageState<BasketContainer>("kanpla:basket", {});
  const basketTrigger = JSON.stringify(basketContainer);

  const totalPrice = calculateMultipleOrdersTotal(
    Object.values(basketContainer)
  );
  const totalAmountOfItems = calculateMultipleOrdersItemsAmount(
    Object.values(basketContainer)
  );

  /** Store basket state in local storage */
  useEffect(() => {
    if (!isEmpty(basketContainer)) setSessionBasket(basketContainer);
  }, [basketTrigger]);

  /** Retrieve basket state if the user logs in/out */
  useEffect(() => {
    if (!isEmpty(sessionBasket)) setBasketContainer(sessionBasket);
  }, [userId]);

  return {
    basketContainer,
    setBasketContainer,
    setSessionBasket,
    totalPrice,
    totalAmountOfItems,
  };
};

const useBasket = ({
  userId,
  module,
  dateSeconds,
  schoolId,
  childId,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // Always close the basket when unmounting the hook
    return () => {
      setOpen(false);
    };
  }, []);

  const moduleId = module?.id || "";
  const paymentMethod = module?.paymentMethod;

  // State
  const {
    basketContainer: basketContainerObj,
    setBasketContainer,
    totalPrice: basketContainerTotalPrice,
    totalAmountOfItems: basketContainerTotalItemsAmount,
    setSessionBasket,
  } = useBasketContainer({
    userId,
  });

  const basketContainer = useMemo(
    () => Object.values(basketContainerObj),
    [basketContainerObj]
  );

  // Reset basket
  const reset = () => {
    setBasket({});
    setBasketContainer({});
    setSessionBasket({});
  };

  useEffect(() => {
    const hasEveryAmountZero = basketContainer.every((b) => {
      const configs = getOrderConfigs(b.order);
      return configs.every((c) => c.config.amount === 0);
    });

    if (hasEveryAmountZero) {
      reset();
      setOpen(false);
    }
  }, [JSON.stringify(basketContainer)]);

  // Finds a target order inside the `BasketContainer`
  const findTargetBasket = useCallback(
    (moduleId: string, dateSeconds: number) => {
      return basketContainer.find((o) =>
        isTargetOrder(o, moduleId, dateSeconds, schoolId, childId)
      );
    },
    [basketContainer, schoolId, childId]
  );

  const orderInView = useMemo(
    () => findTargetBasket(moduleId, dateSeconds),
    [findTargetBasket, moduleId, dateSeconds]
  );

  const basket = useMemo(() => orderInView?.order || {}, [orderInView]);

  const ordersThatShouldShow = basketContainer.find((o) =>
    shoudShow(o, paymentMethod, schoolId, childId)
  );

  const setBasketFromDifferentModule = ({
    o,
    orderInfo = {},
    moduleId,
    dateSeconds,
  }: SetBasketProps) => {
    return setBasket(o, orderInfo, moduleId, dateSeconds);
  };

  // Update method
  const setBasket = (
    order: BasketOrder,
    orderInfo: OrderPersonal["info"] = {},
    moduleIdFromParams?: string,
    dateSecondsFromParams?: number
  ) => {
    const actualOrder = typeof order === "function" ? order() : order;

    if (isEmpty(actualOrder)) return;

    const actualModuleId = moduleIdFromParams ? moduleIdFromParams : moduleId;
    const actualDateSeconds = dateSecondsFromParams
      ? dateSecondsFromParams
      : dateSeconds;

    let newBasketContainer: BasketContainer = {};

    setBasketContainer((prevOrders) => {
      // Keep other orders
      const otherOrders = Object.entries(prevOrders).reduce(
        (acc, [basketId, o]) => {
          const isCurrentOrder = isTargetOrder(
            o,
            actualModuleId,
            actualDateSeconds,
            schoolId,
            childId
          );

          if (isCurrentOrder) return acc;

          return {
            ...acc,
            [basketId]: o,
          };
        },
        {} as BasketContainer
      );

      // Update target one
      const [basketId, targetOrder] = Object.entries(prevOrders || {}).find(
        ([basketId, o]) =>
          isTargetOrder(o, actualModuleId, actualDateSeconds, schoolId, childId)
      ) || [null, null];

      // Add config uids to basket
      Object.entries(actualOrder || {}).forEach(([productId, item]) => {
        item.config?.forEach((config) => {
          config.uid =
            config.uid || `${productId}-${Math.random().toString(16).slice(2)}`;
        });
      });

      const newTargetOrder: OrderPersonal = {
        ...(targetOrder || {}),
        order: actualOrder,
        moduleId: actualModuleId,
        dateSeconds: actualDateSeconds,
        schoolId,
        info: orderInfo,

        paymentMethod,
        childId,
        userId,
      };

      newBasketContainer = {
        ...otherOrders,
        [basketId || generate()]: newTargetOrder,
      };

      return newBasketContainer;
    });

    return newBasketContainer;
  };

  const orderedBasket: OrderedBasket = useMemo(
    () =>
      basketContainer.reduce((acc, item) => {
        // Don't include products with amount === 0
        if (
          Object.values(item.order).every(
            (p) =>
              p.amount === 0 || (p?.config || []).every((c) => c.amount === 0)
          )
        )
          return acc;

        // Group by same date
        if (acc?.[item.dateSeconds]) {
          // Group by module
          const ordersInModule = sortBy(
            {
              [item.moduleId]: item,
              ...(acc?.[item.dateSeconds] || {}),
            },
            (x) => x.moduleId
          ).reduce((acc, item) => ({ ...acc, [item.moduleId]: item }), {});

          return {
            ...acc,
            [item.dateSeconds]: {
              ...ordersInModule,
            },
          };
        }

        return {
          ...acc,
          [item.dateSeconds]: {
            [item.moduleId]: item,
          },
        };
      }, {} as OrderedBasket),
    [basketContainer]
  );

  return {
    basket,
    setBasket,
    openBasket: open,
    setOpenBasket: setOpen,
    /** Order info of the current order in view */
    orderInfo: orderInView?.info || {},
    /** Orders that should show in the current viewing module */
    ordersThatShouldShow,
    /** The basket container */
    basketContainer,
    setBasketContainer,
    /** Exposes different utilities from the basket container */
    basketContainerUtils: {
      /** Basket container grouped by `dateSeconds` and `moduleId` */
      orderedBasket,
      /** Total price of all the baskets items inside the container */
      totalPrice: basketContainerTotalPrice,
      /** Total amount of all items inside the container */
      totalItemsAmount: basketContainerTotalItemsAmount,
      /** Finds a basket inside the container given `moduleId` and `dateSeconds` */
      findTargetBasket,
      /** Sets the basket from a specific `moduleId` and `dateSeconds` */
      setBasketFromDifferentModule,
      /** Total amount of baskets inside the container */
      totalBaskets: basketContainer.length,
      /** Empty the basket container */
      reset,
    },
  };
};

export default useBasket;
