/* eslint-disable prefer-const */
import { Week } from "@kanpla/types";

export const getWeekArray = (seconds: number, Timestamp: any) => {
  let week = [];
  for (let i = 0; i < 5; i++) {
    week.push(new Timestamp(seconds, 0));
    seconds = seconds + 86400;
  }

  return week as Week;
};
