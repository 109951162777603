import { db } from "..";

interface Props {
  userId: string;
  /** You can pass in a custom db (in case of GCP) */
  customDb?: any;
  /** Allow to pass even if the user isn't superadmin, returns false instead of an error */
  failSilently?: boolean;
}

/**
 * Check whether the user is superadmin
 * @param props Send in userId in object
 * @returns boolean if the user is superadmin
 */
export const isSuperAdmin = async (props: Props) => {
  const { userId, customDb, failSilently } = props;

  try {
    const superadminRef = await (customDb || db)
      .collection("superadmins")
      .doc(userId)
      .get();

    if (superadminRef.exists) return true;

    if (failSilently) return false;

    throw new Error("User is not a superadmin");
  } catch (err) {
    console.error(err);
    if (failSilently) return;
    throw new Error("User is not a superadmin");
  }
};
