import { User } from "@kanpla/types";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { db, FieldValue } from "../firebase.config";

/**
 *  Hook to trigger an opening of an Intercom product tour
 * @param tourId Tour ID. Find it in the "Use your tour everywhere", *not the ID in the URL!!!*
 * @param user Send in user component to check fo seen turs
 * @param open Trigger opening
 */
export const useProductTour = (tourId: number, user: User, open: boolean) => {
  const { startTour } = useIntercom();

  useEffect(() => {
    if (!tourId) return;
    if (!user?.id) return;
    if (!open) return;

    // User have already seen the tour
    if (user?.seenTours?.includes(tourId)) return;

    startTour(tourId);
    db.collection("users")
      .doc(user?.id)
      .update({ seenTours: FieldValue.arrayUnion(tourId) });
  }, [open, tourId, user?.seenTours]);
};
