interface Props {
  array: any[];
  index: number;
}

/**
 * This method return true if the index belongs to the last item
 */
export const isLastItem = ({ array, index }: Props) => {
  const isLast = array.length - 1 === index;

  return isLast;
};
