import { auth, fb, rules } from "@kanpla/system";
import { Form, Input, message } from "antd";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { isEmpty } from "lodash";
import React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ButtonWithLoading from "../button/ButtonWithLoading";

export const ResetPassword = () => {
  const { t, i18n } = useTranslation(["reset-password", "translation"]);

  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const hasI18n = !isEmpty(i18n);

  const handleGeneratePasswordReset = async (email: string) => {
    try {
      const resetPassword = fb
        .functions("europe-west1")
        .httpsCallable("resetPassword");
      await resetPassword({ email });
      message.success("Mail afsendt");
      setIsEmailSent(true);
    } catch (err) {
      console.error(err);
      message.error(
        hasI18n
          ? t("reset-password:message.error.failed-to-create-access-point")
          : "Kunne ikke oprette et link til nulstilling af adgangspunkt"
      );
    } finally {
      setLoading(false);
    }
  };

  const isUserEmailValid = async (email: string) => {
    try {
      const res = await fetchSignInMethodsForEmail(auth, email);
      if (!res.length) throw new Error();
      return true;
    } catch (err) {
      throw new Error(err);
    }
  };

  const onFinish = async (values): Promise<any> => {
    const { email } = values;

    setLoading(true);

    try {
      await isUserEmailValid(email);
      handleGeneratePasswordReset(email);
    } catch (err) {
      message.error(
        hasI18n
          ? t("reset-password:message.error.wrong-email-address")
          : "Forkert mailadresse"
      );
      setLoading(false);
    }
  };

  if (isEmailSent)
    return (
      <>
        <h1 className="h700 mb-3 text-primary-dark">
          {hasI18n ? t("reset-password:check-inbox") : "Tjek din indbakke"}
        </h1>
        <div className="max-w-md m-auto text-text-secondary">
          <p>
            {hasI18n
              ? t("reset-password:info-about")
              : "Du vil modtage en mail med informationer om nulstilling af adgangskoden."}
          </p>
          <p className="mt-6">
            <Trans t={t} i18nKey="reset-password:description">
              Det kan tage op til et par minutter før du modtager mailen.
              <br />
              Hvis du stadig ikke har modtaget mailen - tjek da venligst under
              spam eller få
              <button
                onClick={() => setIsEmailSent(false)}
                className="ml-1 pt-2 font-semibold cursor-pointer"
              >
                tilsendt en ny mail
              </button>
              .
            </Trans>
          </p>
        </div>
      </>
    );

  return (
    <>
      <h1 className="h700 mb-3 text-primary-dark">
        {hasI18n
          ? t("reset-password:reset-password")
          : "Nulstil din adgangskode"}
      </h1>
      <p className="text-text-secondary max-w-sm mx-auto">
        {hasI18n
          ? t("reset-password:enter-email")
          : "Indtast din mailadresse for at komme i gang med at nulstille din adgangskode."}
      </p>

      <Form
        className="my-8 max-w-xs m-auto"
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Form.Item
          label="E-mail"
          rules={[
            rules({
              rule: "required",
              ruleValue: true,
              message: hasI18n
                ? t("translation:form.errors.required", {
                    value: t("translation:email"),
                  })
                : "Indtast venligst mailadresse",
            }),
          ]}
          name="email"
        >
          <Input
            placeholder={
              hasI18n
                ? t("translation:form.placeholders.email")
                : "Indtast venligst mailadresse"
            }
          />
        </Form.Item>

        <ButtonWithLoading
          htmlType="submit"
          className="w-full"
          size="large"
          loading={loading}
          text={
            hasI18n
              ? t("translation:form.buttons.reset")
              : "Nulstil adgangskode"
          }
          loadingText={hasI18n ? t("translation:loading") : "Vent venligst"}
        />
      </Form>
    </>
  );
};
