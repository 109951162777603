import { Supplier } from "@kanpla/types";
import { HotJar } from "@kanpla/ui";
import { ConfigProvider, message } from "antd";
import localeDa from "antd/lib/locale/da_DK";
import localeEn from "antd/lib/locale/en_GB";
import "libs/ui/styles/antd.less";
import moment from "moment";
/** DO NOT REMOVE THIS IMPORT OR THE LOCALIZATION WILL STOP WORKING */
import "moment/locale/da";
import { NextQueryParamProvider } from "next-query-params";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { default as React, useEffect, useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { useTranslation } from "react-i18next";
import smoothscroll from "smoothscroll-polyfill";
import { useContainer } from "unstated-next";
import "../../../i18n";
import CompanySuppliers from "../components/CompanySuppliers";
import { ConfirmProvider } from "../components/confirmProvider";
import ContextProvider, { AppContext } from "../components/contextProvider";
import CookiesLegalMonster from "../components/CookiesLegalMonster";
import CustomAppBanner from "../components/CustomAppBanner";
import FCM from "../components/FCM";
import NewPayment from "../components/modals/payment/index";
import RefillNotification from "../components/modals/payment/RefillNotification";
import ServiceWorker from "../components/SerciveWorker";
import * as gtag from "../lib/gtag";
import "../styles/index.scss";
import { IntercomProvider } from "react-use-intercom";

Router.events.on("routeChangeComplete", (url) => gtag.pageview(url));

if (typeof window === `undefined`) {
  require("localstorage-polyfill");
}

const PaymentWrapper = ({ router }) => {
  const [open, setOpen] = useState(true);

  const modifyOpen = (nextOpen) => {
    setOpen(nextOpen);
    if (nextOpen === false) {
      Router.push({
        pathname: router.pathname,
        query: {},
      });
    }
  };

  return <NewPayment open={open} setOpen={modifyOpen} />;
};

const OfflineComponent = ({ offline = false, setPrevOffline, messages }) => {
  useEffect(() => {
    if (offline) {
      setPrevOffline(true);
      return message.error(messages.error);
    }

    message.success(messages.success);
    return setPrevOffline(false);
  }, [offline]);

  return null;
};

const CustomRouter = () => {
  const { auth } = useContainer(AppContext);
  const router = useRouter();

  useEffect(() => {
    // Wait if authentication is reloading
    if (auth.loading) return;

    // If the user is authenticated and on the login screen
    if (router.pathname === "/login" && auth.user)
      router.push({
        pathname: "/app",
      });
  }, [auth, router]);

  // Error messages
  if (router.query.error) {
    message.error(decodeURI(router.query.error as string));
    const newQuery = router.query;
    delete newQuery.error;
    Router.push({
      pathname: router.pathname,
      query: newQuery,
    });
  }

  // Succes messages
  if (router.query.success) {
    message.success(decodeURI(router.query.success as string));
    const newQuery = router.query;
    delete newQuery.success;
    Router.push({
      pathname: router.pathname,
      query: newQuery,
    });
  }

  // Payment modal open
  if (router.query.paymentModal === "open" && auth.user) {
    return <PaymentWrapper router={router} />;
  }

  return null;
};

interface Props {
  Component: any;
  pageProps: any;
  err: any;
  supplier: Supplier;
}

const MyApp = (props: Props) => {
  const { t, i18n } = useTranslation(["translation"]);

  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const [wasOffline, setWasOffline] = useState(false);
  const messages = {
    success: t("translation:message.success.yes-internet"),
    error: t("translation:message.error.no-internet"),
  };

  useEffect(() => {
    smoothscroll.polyfill();

    // Remove Service workers
    if (navigator) {
      if ("serviceWorker" in navigator) {
        // console.log("has service worker");
        if (typeof caches !== "undefined") {
          caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
              caches.delete(cacheName);
            });
          });
        }

        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          })
          .catch(function (err) {
            console.log("Service Worker registration failed: ", err);
          });
      }
    }
  }, []);

  const { Component, pageProps, err } = props;

  const getRightLocale = (locale: string) => {
    switch (locale) {
      case "en":
        return localeEn;
      case "da":
        return localeDa;
      default:
        return localeDa;
    }
  };

  useEffect(() => {
    if (
      isRenderedInWebView &&
      typeof window !== "undefined" &&
      window?.["Intercom"]
    ) {
      window?.["Intercom"]("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  const isRenderedInWebView =
    typeof window !== "undefined" && window?.["isRenderedInWebView"];

  return (
    <ConfigProvider locale={getRightLocale(i18n.language)}>
      <Head>
        <title>Kanpla</title>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="var(--main-color-500, #fff)" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="facebook-domain-verification"
          content="pwb71kzbll3luyu2bulurc7dak80qt"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="viewport"
          content="width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
        {/* @ts-ignore */}
        {isRenderedInWebView ? (
          <style id="scroll-pwa">
            {`html {
                  overflow: hidden;
                  user-select: none;
                  -webkit-user-select: none;
                }

                body {
                  overflow: auto;
                  user-select: none;
                  -webkit-user-select: none;
                }`}
          </style>
        ) : (
          <style id="scroll-pwa">
            {`html {
                overflow: scroll;
              }

              body {
                height: auto!important;
                min-height: 100vh;
              }`}
          </style>
        )}
      </Head>
      <Offline>
        <OfflineComponent
          offline
          setPrevOffline={(e) => setWasOffline(e)}
          messages={messages}
        />
      </Offline>
      {wasOffline && (
        <Online>
          <OfflineComponent
            setPrevOffline={(e) => setWasOffline(e)}
            messages={messages}
          />
        </Online>
      )}
      <NextQueryParamProvider>
        <IntercomProvider
          appId="f1qnpluk"
          autoBoot
          autoBootProps={{ hideDefaultLauncher: true }}
        >
          <ContextProvider>
            <ServiceWorker />
            <CompanySuppliers />
            <ConfirmProvider>
              <CustomRouter />
              <CookiesLegalMonster />
              <HotJar />
              <CustomAppBanner />

              <Component {...pageProps} err={err} />

              <RefillNotification />
              <FCM />
              {/* <IntercomProvider /> */}
              <Script
                id="reepay-checkout-api"
                src="https://checkout.reepay.com/checkout.js"
              />
            </ConfirmProvider>
          </ContextProvider>
        </IntercomProvider>
      </NextQueryParamProvider>
    </ConfigProvider>
  );
};

export default MyApp;
