import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, Popconfirm, Space, Tooltip } from "antd";
import React from "react";

interface ItemProps {
  message: string;
  icon: IconProp;
  active: boolean;
  disabled?: boolean;
  onClick?:
    | {
        message: string;
        onConfirm?: () => Promise<void>;
      }
    | (() => void);
}

const ItemInner = ({ message, icon, disabled, active }: ItemProps) => (
  <Tooltip title={message}>
    <FontAwesomeIcon
      icon={icon}
      className={`text-sm ${
        disabled
          ? "text-text-disabled opacity-60"
          : active
          ? "text-success-main"
          : "text-danger-main"
      }`}
    />
  </Tooltip>
);

const Item = ({ onClick, ...props }: ItemProps) => {
  if (onClick?.message && onClick?.onConfirm)
    return (
      <Popconfirm
        placement="bottom"
        align={{ offset: [2, -15] }}
        title={onClick?.message}
        onConfirm={async () => {
          try {
            await onClick?.onConfirm();
          } catch (e) {
            console.error(e);
            message.error(e?.message);
          }
        }}
      >
        <Button type="text" style={{ padding: 0 }} className="hover:border">
          <ItemInner {...props} />
        </Button>
      </Popconfirm>
    );

  if (onClick)
    return (
      <Button
        type="text"
        style={{ padding: 0 }}
        className="hover:border"
        onClick={onClick}
      >
        <ItemInner {...props} />
      </Button>
    );

  return <ItemInner {...props} />;
};

interface Props {
  items: Array<ItemProps>;
}

export const IconDisplay = ({ items }: Props) => {
  return (
    <Space>
      {items.map((item) => (
        <Item key={item.message} {...item} />
      ))}
    </Space>
  );
};
