import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexOption,
  ISelectProductByDayProps,
  MenuItem,
  Module,
} from "@kanpla/types";
import { ActionType, DrawerOrModal, ProductOptions } from "@kanpla/ui";
import { message } from "antd";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  setOpen: (nextState: boolean) => void;
  open: boolean;
  product: CombinedOfferItem;
  currentDay: {
    available: true;
    stock?: number;
    menu?: MenuItem;
  };
  date?: string;
  module?: Module;
  mealOptions?: FlexOption[];
  data: CustomOrderContent;
  setData: Dispatch<SetStateAction<CustomOrderContent>>;
  onPurchase: (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => Promise<void>;
  selectProductByDay: (props: ISelectProductByDayProps) => void;
}

export const VariantsModal = ({
  open,
  setOpen,
  product,
  currentDay,
  mealOptions,
  module,
  data,
  setData,
  onPurchase,
  date,
  selectProductByDay,
}: Props) => {
  const { t, i18n } = useTranslation(["translation", "libs"]);

  moment.locale(i18n.language);

  const todayString = useMemo(
    () => moment.unix(Number(date)).format("dddd"),
    [date]
  );

  const [loading, setLoading] = useState(false);

  const actions: ActionType[] = [
    {
      label: t("translation:confirm"),
      onClick: async () => {
        setLoading(true);
        try {
          await onPurchase(product, data, date);
          selectProductByDay({
            product,
            dateSeconds: date,
          });
          message.success({
            key: "success-flex-order",
            content: t("libs:message.success.success-flex-order", {
              value: todayString,
            }),
          });
        } catch (error) {
          message.error({
            key: "error-flex-order",
            content: t("translation:something-went-wrong"),
          });
        } finally {
          setOpen(false);
          setLoading(false);
        }
      },
      loading,
    },
  ];

  const options = mealOptions?.filter(
    (option: FlexOption) =>
      currentDay?.menu?.options?.includes(option?.id) ||
      product?.options?.includes(option?.id)
  );

  const updateValues = (newData: Partial<CustomOrderContent>) => {
    setData((data) => ({ ...data, ...newData }));
  };

  const optionChoices = data?.["optionChoices"] || {};

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={actions}
      noPadding={true}
      floatingActions
    >
      <div className="px-8">
        <ProductOptions
          product={{
            name: currentDay?.menu?.name || product?.name,
            id: product?.productId,
            color: product?.color,
            price: product?.price,
            taxPercentage:
              module?.plugins?.taxSettings?.active && product?.taxPercentage,
          }}
          options={options}
          value={optionChoices}
          onChange={(newValue) => updateValues({ optionChoices: newValue })}
        />
      </div>
    </DrawerOrModal>
  );
};
