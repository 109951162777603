import Link from "next/link";
import React from "react";
import { IdDisplay } from "./IdDisplay";

interface Props {
  userId: string;
  email: string;
}

export const UserDisplay = ({ userId, email }: Props) => (
  <div className="flex flex-col">
    <a href={`mailto:${email}`}>
      <IdDisplay value={email} ellipsis />
    </a>
    <Link href={`/dashboard/lookup/user?q=${userId}`}>
      <a className="text-xs opacity-50">
        <IdDisplay value={userId} ellipsis />
      </a>
    </Link>
  </div>
);
