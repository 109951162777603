import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { editConfigAmount, priceFormatter } from "@kanpla/system";
import { OrderConfig } from "@kanpla/types";
import { Button, InputNumber, Select } from "antd";
import { isEmpty, set } from "lodash";
import { diff } from "just-diff";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { VariantsContext } from ".";

const MultipleLayers = () => {
  const { t, i18n } = useTranslation(["translation"]);
  const { product, orderProduct, order, setOrder, availableOptions } =
    useContext(VariantsContext);

  const configs = orderProduct?.config || [];

  const getSortingKey = (config: OrderConfig) =>
    JSON.stringify(Object.values(config.options || {}));

  const addNewLine = () => {
    const newOrder = editConfigAmount({
      order: order,
      productId: product.productId,
      choice: {},
      amountChange: 1,
      replaceAmount: false,
    });

    setOrder(newOrder);
  };

  useEffect(() => {
    console.log(configs);
    if (!configs.length) addNewLine();
  }, [configs]);

  return (
    <div>
      {/* TODO: Add header */}
      {configs
        .sort((a, b) => getSortingKey(a).localeCompare(getSortingKey(b)))
        .map((config) => {
          const amount = config.amount;

          const onChangeChoice = (newAmount: number) => {
            // If not a readable value, just return
            if (
              typeof newAmount !== `number` ||
              newAmount < 0 ||
              newAmount === amount
            ) {
              return;
            }

            const newOrder = editConfigAmount({
              order: order,
              productId: product.productId,
              choice: config.options,
              amountChange: newAmount,
              replaceAmount: true,
            });

            setOrder(newOrder);
          };

          return (
            <div
              className="flex justify-between items-center py-2 border-b border-main-800"
              key={getSortingKey(config)}
            >
              {/* TODO: Add select inputs */}
              <div>
                {availableOptions.map((option) => {
                  const choicesIds = Object.keys(config.options || {});
                  // const optionId = option.id;

                  const activeChoice = option.choices.find((ch) =>
                    choicesIds.includes(`${ch.id}`)
                  );

                  const onChange = (newChoiceId) => {
                    // eslint-disable-next-line prefer-const
                    let newOrder = { ...order };

                    const oldConfigSet = newOrder?.[product.productId]?.config;
                    const isTargetConfig = (conf) =>
                      diff(config.options, conf.options).length === 0;
                    const otherConfigs = oldConfigSet.filter(
                      (conf) => !isTargetConfig(conf)
                    );

                    const targetConfig = oldConfigSet.find((conf) =>
                      isTargetConfig(conf)
                    );

                    const oldChoices = Object.entries(
                      targetConfig.options || {}
                    ).map(([choiceId, value]) => ({
                      ...value,
                      id: choiceId,
                    }));
                    const restOfChoices = oldChoices.filter(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      (o) => o.id !== activeChoice?.id && o.amount > 0
                    );

                    const newChoice = option.choices.find(
                      (ch) => ch.id === newChoiceId
                    );

                    const orderChoices = [
                      ...restOfChoices,
                      {
                        name: newChoice.name,
                        id: `${newChoiceId}`,
                        extraPrice: newChoice.price,
                        amount: amount,
                      },
                    ];

                    const newTargetConfigOptions: OrderConfig["options"] =
                      orderChoices.reduce((acc, choice) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        if (choice.amount > 0) acc[choice.id] = choice;
                        return acc;
                      }, {} as OrderConfig["options"]);

                    const newTargetConfig: OrderConfig = {
                      amount,
                      options: newTargetConfigOptions,
                    };

                    const newConfigSet: OrderConfig[] = [
                      ...otherConfigs,
                      newTargetConfig,
                    ];

                    set(newOrder, `${product.productId}.config`, newConfigSet);

                    setOrder(newOrder);
                  };

                  return (
                    <Select
                      key={option.id}
                      value={activeChoice?.id}
                      onChange={onChange}
                      bordered={false}
                      placeholder={
                        isEmpty(i18n)
                          ? "vælg"
                          : t("translation:choose").toLowerCase()
                      }
                    >
                      {option.choices.map((choice) => (
                        <Select.Option value={choice.id}>
                          {choice.name}{" "}
                          {choice.price ? (
                            <span className="text-sm text-text-disabled">
                              (+{priceFormatter(choice.price, i18n)})
                            </span>
                          ) : null}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                })}
              </div>
              <div>
                <div>
                  <InputNumber
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={amount}
                    onChange={onChangeChoice}
                    className="bg-main-100 hover:bg-main-200 focus:bg-main-200 mr-2 w-16"
                  />{" "}
                  stk.
                </div>
              </div>
            </div>
          );
        })}

      <div className="flex justify-between items-center py-2">
        <Button onClick={addNewLine}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {isEmpty(i18n)
            ? "Tilføj ekstra variant"
            : t("translation:add-extra-variation")}
        </Button>
        <div>
          {isEmpty(i18n)
            ? `i alt: ${orderProduct?.amount || "—"}`
            : t("translation:total")}
        </div>
      </div>
    </div>
  );
};

export default MultipleLayers;
