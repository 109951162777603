import { Scope, Tree } from "@kanpla/types";
import { getNestedIds } from "./getNestedIds";

type Props = {
  scope: Scope;
  tree: Tree;
};

export const getSchoolIdsFromScope = ({ scope, tree }: Props) => {
  const listedProductBankIds = Object.keys(scope?.productBanks || {});

  const individualSchoolIds = Object.keys(scope?.schools || {});
  const collectorSchoolIds = [...individualSchoolIds];

  const collector = [];

  const recurse = (productBankIds: string[]) => {
    productBankIds
      .filter((pb) => !collector.includes(pb))
      .forEach((productBankId) => {
        // Add all schools
        getNestedIds(tree?.schools, productBankId).forEach((schoolId) => {
          collectorSchoolIds.push(schoolId);
        });

        // Recurse for each nested productBank
        collector.push(productBankId);
        const nestedBankIds = getNestedIds(tree?.productBanks, productBankId);
        recurse(nestedBankIds);
      });
  };

  // Fun for each listen product bank id
  recurse(listedProductBankIds);

  const schoolIds = [...new Set(collectorSchoolIds)];

  return schoolIds;
};
