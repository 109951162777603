import { BasketItemConfig, OrderOrder } from "@kanpla/types";

export const getOrderConfigs = (order: OrderOrder): BasketItemConfig[] => {
  const configs = Object.entries(order || {})
    .map(([productId, orderProduct]) => {
      if (orderProduct.amount === 0) return [];

      const totalConfigAmount = (orderProduct.config || []).reduce(
        (acc, doc) => {
          return acc + doc.amount;
        },
        0
      );

      const totalProductAmount = orderProduct.amount;

      const configs = orderProduct.config || [];

      // If we have missing configs, add them to the array
      if (totalConfigAmount !== totalProductAmount) {
        configs.push({
          name: orderProduct.name,
          amount: totalProductAmount - totalConfigAmount,
          options: {},
        });
      }

      const separateConfigs = configs
        ?.map((config) => ({
          name: orderProduct.name,
          price: orderProduct.price,
          photo: orderProduct?.photo,
          productId,
          config,
        }))
        .filter((c) => c.config?.amount !== 0);

      return separateConfigs;
    })
    .flat();

  return configs;
};
