import { OrderInfo } from "@kanpla/types";

interface FormattedData {
  [dateSeconds: string]: {
    [moduleId: string]: OrderInfo;
  };
}

/**
 * Transform data received from the orderInfo form into a readable object
 * @param data Form data
 * @returns
 */
export const orderInfoFormToData = (data: any) =>
  Object.entries(data).reduce((acc, [path, value]) => {
    if (!value) return acc;

    const [moduleId, dateSeconds] = path.split("--")[1].split(".");
    const inputName = path.split(".")[0];

    const isTextInput = inputName === "textInput";

    if (acc?.[dateSeconds]) {
      return {
        ...acc,
        [dateSeconds]: {
          ...acc[dateSeconds],
          [moduleId]: {
            ...(acc[dateSeconds]?.[moduleId] || {}),
            [inputName]: !isTextInput
              ? value
              : {
                  ...(acc[dateSeconds][moduleId]?.[inputName] || {}),
                  [path.split(".")[1]]: value,
                },
          } as OrderInfo,
        },
      };
    }

    return {
      ...acc,
      [dateSeconds]: {
        [moduleId]: {
          [inputName]: value,
        },
      },
    };
  }, {} as FormattedData);
