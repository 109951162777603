// import { DecodedIdToken } from "firebase-admin/lib/auth/token-verifier";
// import {
//   admin,
//   HttpsError,
// } from "../../../../apps/functions/src/admin-service-account";

// /**
//  * Verifies given token and returns informational object with type `DecodedIdToken`
//  * or `null` if token is missing or if it can't be verified.
//  */
// const verifyToken = async (
//   accessToken: string
// ): Promise<DecodedIdToken | null> => {
//   const onlyToken: string = accessToken.split("Bearer ")[1] || "";

//   if (!onlyToken.trim()?.length) return null;

//   return admin.auth().verifyIdToken(onlyToken) || null;
// };

export const verifyRequestCaller = async (req) => {};

// export const verifyRequestCaller = async (req) => {
//   try {
//     const token = req.headers?.authorization;

//     if (!token) throw new Error("Missing token");

//     const user = await verifyToken(token);
//     const uid = user?.uid;

//     if (!uid) throw new Error("Invalid authentication");

//     return uid;
//   } catch (e: any) {
//     console.log(e);
//     if (e?.errorInfo?.code === "auth/id-token-expired")
//       throw new HttpsError("unauthenticated", e?.errorInfo?.message, {
//         code: 401,
//       });
//     throw new HttpsError("internal", e?.message);
//   }
// };

// // /**
// //  * Gets accessToken from `Authorization` request header and verifies token
// //  * with @see verifyToken function.
// //  * Returns `DecodedIdToken` informational object or null.
// //  **/
// // export const verifyRequest = async (
// //   req: https.Request
// // ): Promise<DecodedIdToken | null> => {
// //   const accessToken = req.headers.authorization || "";
// //   return verifyToken(accessToken);
