import classNames from "classnames";
import React, { CSSProperties, useEffect, useState } from "react";
import Imgix from "react-imgix";

interface Props {
  className?: string;
  src: string;
  alt: string;
  style?: CSSProperties;
  loadedAnyway?: boolean;
  dataCy?: string;
  size?: {
    w?: number;
    h?: number;
  };
}

export const Image = ({
  className = "",
  src,
  alt,
  style = {},
  loadedAnyway = false,
  dataCy = "",
  size = {},
}: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const finalLoaded = loadedAnyway || imageLoaded;

  useEffect(() => {
    if (!src) return;

    setImageLoaded(false);
  }, [src]);

  if (!src) return null;

  if (src?.includes("imgix.net")) {
    return (
      <Imgix
        imgixParams={{
          w: size?.w,
          h: size?.h,
          q: 70,
        }}
        src={src}
        className={classNames(
          className,
          finalLoaded && src ? "opacity-100" : "loader bg-background-secondary"
        )}
        htmlAttributes={{
          alt,
          "data-cy": dataCy,
          onLoad: () => setImageLoaded(true),
        }}
        sizes="70vw"
        attributeConfig={{
          src: "data-src",
          sizes: "data-sizes",
        }}
      />
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      onLoad={() => setImageLoaded(true)}
      className={`${
        finalLoaded && src ? "opacity-100" : "loader bg-background-secondary"
      } ${className}`}
      style={{ transition: "opacity .2s ease-out", ...style }}
      data-cy={dataCy}
    />
  );
};
