import { db } from "@kanpla/system";
import { Child, Supplier } from "@kanpla/types";
import { ButtonSubmit } from "@kanpla/ui";
import { message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Allergen, allergenList } from "../elements/Allergen";

export const sectionClasses = "border-t border-main-700 mb-2 py-6 px-4";

interface Props {
  child: Child;
  onSave?: () => void;
  customBranding?: Supplier;
}

export const Allergens = ({
  child,
  onSave = () => null,
  customBranding,
}: Props) => {
  const { t } = useTranslation(["libs"]);

  const [allergens, setAllergens] = useState(child.allergens || {});
  const [saving, setSaving] = useState(false);

  const submit = async () => {
    try {
      setSaving(true);
      if (child.ref) {
        await (child.ref || child.child_ref).update({ allergens });
      } else {
        await db.collection("children").doc(child.id).update({ allergens });
      }
      message.success(t("libs:message.success.allergens-info-saved"));
      onSave();
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className={`${sectionClasses} mt-6`}>
      <h3 className="h500 mb-4">{t("libs:allergens")}</h3>
      <div className="grid grid-cols-5 md:grid-cols-6 my-3">
        {Object.keys(allergenList()).map((a) => (
          <AllergenInput
            a={a}
            allergens={allergens}
            setAllergens={setAllergens}
            key={a}
            customBranding={customBranding}
          />
        ))}
      </div>
      <ButtonSubmit onClick={submit} loading={saving} />
    </div>
  );
};

const AllergenInput = ({ a, allergens, setAllergens, customBranding }) => {
  const [hovering, setHovering] = useState(false);
  const [value, setValue] = useState(allergens[a] ? allergens[a] : false);

  return (
    <button
      className={`group mr-2 mb-2 flex flex-col items-center`}
      type="button"
      onClick={() => {
        setAllergens({ ...allergens, [a]: !value });
        setValue(!value);
      }}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <Allergen
        allergenName={a}
        colorClassName={value ? "text-primary-main" : "text-kanpla-400"}
        customBranding={customBranding}
        layout="vertical"
      />
    </button>
  );
};
