import { School, Module, Child } from "@kanpla/types";
import { intersection } from "lodash";

interface Props {
  child: Child;
  school: School;
  module: Module;
}

export const getChildCodes = (child: Child, school: School) => {
  const selectorEntries = Object.entries(
    child?.selectors || ({} as Child["selectors"])
  );
  const selectorCodes = selectorEntries
    .map(([layerName, optionName]) => {
      const targetLayer = school?.selectors?.find(
        (layer) => layer.name === layerName
      );
      const targetOption = targetLayer?.options?.find(
        (o) => o.name === optionName
      );
      const optionCodes = targetOption?.codes || [];
      return optionCodes;
    })
    .flat();
  const allChildCodes = [...(child?.codes || []), ...selectorCodes];

  return allChildCodes;
};

export const hasAccessToModule = (props: Props) => {
  const { module, child, school } = props;

  if (module && !module.scope?.generatedSchoolIds?.includes(school?.id))
    return { individual: false, bulk: false, other: false };

  const moduleConfig = module?.config || {};
  const schoolModuleConfig = school?.modules?.[module?.id || "—"] || {};

  const childCodes = getChildCodes(child, school);

  // Evaluate codes
  const codes = [
    ...(moduleConfig.codes || []),
    ...(schoolModuleConfig.codes || []),
  ];

  const bulkCodes = [
    ...(moduleConfig.bulkCodes || []),
    ...(schoolModuleConfig.bulkCodes || []),
  ];

  const validCodes = (targetCodes: Array<string>) =>
    !targetCodes.length || intersection(targetCodes, childCodes).length;

  // Evaluate enabled/disabled
  const individualDisabled =
    schoolModuleConfig.individualDisabled || moduleConfig.individualDisabled;

  const bulkEnabled =
    schoolModuleConfig.bulkEnabled ||
    moduleConfig.bulkEnabled ||
    school?.hasFlexBulk;

  // Both must be true
  const allowIndividual = !individualDisabled && validCodes(codes);
  const allowBulk = bulkEnabled && validCodes(bulkCodes);
  const allowOther = validCodes(codes);

  return {
    individual: allowIndividual,
    bulk: allowBulk,
    other: allowOther,
  };
};
