import {
  faBadgePercent,
  faCalendarDays,
  faCommentSmile,
  faCreditCardFront,
  faFileInvoice,
  faGrid2Plus,
  faInputText,
  faLandmark,
  faLayerGroup,
  faLeafHeart,
  faMobile,
  faMoneyBill1Wave,
  faRectangleVerticalHistory,
  faSlidersSimple,
  faSparkles,
  faTruckClock,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { faBallotCheck } from "@fortawesome/pro-regular-svg-icons";
import { Plugins } from "@kanpla/types";

type PluginBank = {
  [pluginKey in Plugins.Types]: Plugins.ExtendedGeneral;
};

export const availablePlugins: PluginBank = {
  timeInput: {
    title: "Leveringstidspunkt",
    description:
      "Med time input kan dine kunder tilføje et ønsket leveringstidspunkt.",
    isPlus: false,
    availableFor: ["mealplan", "flex"],
    categories: ["field"],
    icon: faTruckClock,
  },
  textInput: {
    title: "Text input",
    description:
      "Med text input kan dine kunder tilføje instruktioner til køkkenet.",
    isPlus: false,
    availableFor: ["mealplan", "flex"],
    allowMultiple: true,
    categories: ["field"],
    icon: faInputText,
  },
  indexing: {
    title: "Indexing",
    description:
      "Skal du gange dine ordre, så brug indexing! Kig ind i kuverter og indstil tallene.",
    isPlus: true,
    availableFor: ["flex"],
    categories: ["kitchen"],
    icon: faSlidersSimple,
  },
  extendedVariables: {
    title: "Extended variables",
    description: "Tilføj gemte variabler og omdøb dem!",
    isPlus: true,
    availableFor: ["flex"],
    categories: ["kitchen"],
    icon: faSparkles,
  },
  invoiceReference: {
    title: "Reference til faktura",
    description:
      "Tillad dine kunder at tilføje referencer der vises på fakturaer til ordrer.",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["finance"],
    icon: faFileInvoice,
  },
  kanplaGo: {
    title: "Kanpla Go",
    description:
      "Real-time bestilling i kantine i stedet for forudbestillinger.",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["flow", "finance"],
    icon: faMobile,
  },
  requiredProduct: {
    title: "Påkrævet produkt",
    description: "Sæt et produkt som en obligatorisk del af hver bestilling.",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["flow"],
    icon: faGrid2Plus,
  },
  signupOffer: {
    title: "Tilmeldingstilbud",
    description: "Sæt et produkt tilgængeligt kun én gang ved tilmelding.",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["kitchen", "flow"],
    icon: faBadgePercent,
  },
  multipleOrders: {
    title: "Flere bestillinger om dagen",
    description:
      "Giv brugerne mulighed for at bestille til flere tidspunkter og steder.",
    isPlus: false,
    availableFor: [],
    categories: ["flow"],
    icon: faRectangleVerticalHistory,
  },
  taxSettings: {
    title: "Skatteindstillinger",
    description: "Justerer skatteprocenter til hver skole og selektor gruppen.",
    isPlus: true,
    availableFor: ["mealplan"],
    categories: ["finance"],
    icon: faLandmark,
  },
  schoolBasedPrice: {
    title: "Salgsstedbaseret pris",
    description:
      "Overskriver produktprisen med en standardpris på salgsstedet.",
    isPlus: false,
    availableFor: ["flex"],
    categories: ["finance"],
    icon: faMoneyBill1Wave,
  },
  /** @deprecated */
  variantsLimit: {
    title: "Deprecated: Begræns antallet af varianter",
    description: "Det begrænser hvor mange varianter hver skole kan have",
    isPlus: false,
    availableFor: [],
    categories: [],
    icon: null,
  },
  leftovers: {
    title: "Leftovers",
    description: "Tillad brugere at købe madrester fra kantinen",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["flow"],
    icon: faLeafHeart,
  },
  payPerOrder: {
    title: "Direktbetaling",
    description:
      "Brugere kan betale direkte pr. produkt uden at indsætte saldo",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["finance"],
    icon: faCreditCardFront,
  },
  deadlineExcludingWeekends: {
    title: "Deadline uden weekender",
    description: "Fredag er 1 dag før mandag",
    isPlus: false,
    availableFor: ["mealplan", "flex"],
    categories: ["flow"],
    icon: faCalendarDays,
  },
  userSuggestions: {
    title: "Forslag fra brugere",
    description: "Brugere kan anbefale retter til køkkenet",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["field"],
    icon: faCommentSmile,
  },
  orderForAmountOfPeople: {
    title: "Bestil efter antal personer",
    description:
      "Portioner af hver bestilte ret svares til antallet af personer",
    isPlus: true,
    availableFor: ["mealplan"],
    categories: ["field", "flow"],
    icon: faUsers,
  },
  // This is only to enable homescreen editing from backend to limit it for now
  homescreen: {
    title: "Homescreen",
    description:
      "This is only to enable homescreen editing from backend to limit it for now",
    availableFor: [],
    isPlus: true,
    categories: ["beta"],
    icon: null,
  },
  globalVariant: {
    title: "Global variant",
    description: "Opret en variant, der gælder for alle ordrens produkter",
    isPlus: false,
    availableFor: ["flex"],
    categories: ["flow"],
    icon: faLayerGroup,
  },
  advancedVariants: {
    title: "Avancerede varianter",
    description:
      "Ved bestilling kan brugerne vælge forskellige varianter på samme tid",
    isPlus: false,
    availableFor: ["mealplan"],
    categories: ["flow"],
    icon: faBallotCheck,
  },
  BETApdfPrint: {
    title: "Beta: PDF print",
    description:
      "Enable this only to people who should see the PDF print button",
    isPlus: true,
    availableFor: [],
    categories: ["beta"],
    icon: null,
  },
  BETAinteraction: {
    title: "Beta: Interaction",
    description:
      "Enable this only to people who have interaction available on their domain",
    isPlus: true,
    availableFor: [],
    categories: ["beta"],
    icon: null,
  },
  BETAmenuPrint: {
    title: "Beta: Menu Print",
    description: "Enable this only to people who have access to 5.0",
    isPlus: true,
    availableFor: [],
    categories: ["beta"],
    icon: null,
  },
  BILLINGcoor: {
    title: "Billing: COOR",
    description: "Enable this only for COOR now",
    isPlus: true,
    availableFor: [],
    categories: ["finance"],
    icon: null,
  },
  BILLINGapi: {
    title: "Billing: API",
    description:
      "Enable API access, don't forget to talk to devs to get an API key",
    isPlus: true,
    availableFor: [],
    categories: ["finance"],
    icon: null,
  },
};
