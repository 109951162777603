import { sortProducts, TriggerPagePrint } from "@kanpla/system";
import { FlexMenu, Product, Week } from "@kanpla/types";
import { Modal } from "antd";
import contrast from "contrast";
import moment from "moment";
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { Portal } from "react-portal";
import { Menus } from "../../../../apps/backend/lib/flex/getMenus";
import Holiday from "./Holiday";

interface WrapperProps {
  children: ReactNode;
  isDigital?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  title?: string;
}

export const PrintWrapper = ({
  children,
  isDigital,
  open,
  setOpen,
  title,
}: WrapperProps) => {
  if (isDigital)
    return (
      <Modal
        title={title}
        visible={open}
        onCancel={() => setOpen(false)}
        width="auto"
        style={{ minWidth: 500 }}
        className="max-w-5xl"
        footer={null}
      >
        {children}
      </Modal>
    );

  if (typeof document === `undefined`) return null;

  return (
    <Portal node={document.body}>
      <div className="print">{children}</div>
    </Portal>
  );
};

interface Props {
  menus: FlexMenu[] | Menus; // Array<FlexMenu>
  products: Array<Product>;
  week: Week;
  children: ReactElement<any>;
  isDigital?: boolean;
}

export const Print = (props: Props) => {
  const { menus, products, week, children, isDigital = false } = props;
  const [openModal, setOpenModal] = useState(false);
  const [toBePrinted, setToBePrinted] = useState(false);

  const arrayedMenus = Object.entries(menus || {}).reduce((acc, [id, menu]) => {
    acc.push({
      ...menu,
      id,
    });

    return acc;
  }, []);

  const sortedMenus = sortProducts({
    items: arrayedMenus,
    list: products.map((p) => p.id),
  });

  return (
    <div>
      {React.cloneElement(children, {
        onClick: isDigital
          ? () => setOpenModal(true)
          : () => {
              setToBePrinted(true);
              setTimeout(() => TriggerPagePrint(), 300);
              setTimeout(() => setToBePrinted(false), 2000);
            },
      })}
      <PrintWrapper
        isDigital={isDigital}
        open={openModal}
        setOpen={setOpenModal}
        title={`Menuen for uge ${moment.unix(week[0]?.seconds).format("W")}`}
      >
        <table
          className={`table w-full whitespace-normal table-fixed  ${
            toBePrinted ? "" : "print:hidden"
          }`}
        >
          <thead>
            <tr>
              {week.map((date, dayIndex) => (
                <th key={date.seconds} className="w-1/5 p-0">
                  <Holiday
                    date={date}
                    className="rounded-none -mx-4 -my-1 text-xs pointer-events-none"
                    menus={menus}
                    setMenus={() => null}
                    dayIndex={dayIndex}
                    setSaved={() => null}
                  />
                </th>
              ))}
            </tr>
          </thead>

          {sortedMenus.map(({ id, ...menu }) => {
            const { productId, product: defaultProduct } = menu;
            const product =
              defaultProduct || products?.find((p) => p.id === productId);

            const moduleName = product?.name || "";
            const moduleColor = product?.color || "";

            const moduleTextColor =
              contrast(moduleColor || "#fff") === "light"
                ? "#000000ee"
                : "#ffffffee";

            return (
              <tbody style={{ pageBreakInside: "avoid" }} key={id}>
                <tr className="separator">
                  <th
                    colSpan={100}
                    style={{
                      backgroundColor: moduleColor,
                      color: moduleTextColor,
                    }}
                  >
                    <div>{moduleName}</div>
                  </th>
                </tr>
                <tr key={productId}>
                  {Object.entries(menu?.menu || {}).map(([dayIndex, item]) => (
                    <Item
                      key={dayIndex}
                      item={item || {}}
                      // product={product}
                    />
                  ))}
                </tr>
              </tbody>
            );
          })}
        </table>
      </PrintWrapper>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <td className="w-0" style={{ verticalAlign: "top" }}>
      <div className="-ml-1 -mr-2">
        <h4 className="h200 whitespace-normal">{item.name}</h4>
        <p className="whitespace-pre-wrap text-xs">{item.description}</p>
      </div>
    </td>
  );
};

export default Print;
