import { DocumentReference } from "@firebase/firestore-types";
import { createContainer, useContainer } from "unstated-next";
import { setDocumentReact } from "./setDocument";
import { updateDocumentReact } from "./updateDocument";

const useFirestoreContext = (userId: string = null) => {
  return { userId };
};

export const FirebaseContext = createContainer(useFirestoreContext);

export interface SetDocumentOptions {
  userId: string;
}

export const useFirestore = () => {
  const fireContext = useContainer(FirebaseContext);
  const { userId } = fireContext;

  if (!fireContext)
    throw new Error(
      "You are not sending the correct user ID. Try adding FirebaseContext provider into your AppContext and pass it a userId. The userId should ALWAYS be the ID of the user who is actually signed in, without any masking."
    );

  const setDocument = (
    ref: DocumentReference,
    data: any = {},
    merge = true,
    options?: SetDocumentOptions
  ) => setDocumentReact(ref, data, merge, { userId, ...options });

  const updateDocument = (
    ref: DocumentReference,
    data: any = {},
    options?: SetDocumentOptions
  ) => updateDocumentReact(ref, data, { userId, ...options });

  return {
    userId,
    setDocument,
    updateDocument,
  };
};
