import {
  CombinedOfferItem,
  FlexOption,
  MenuItem,
  Module,
  OrderInfo,
  OrderOrder,
} from "@kanpla/types";
import { ActionType, DrawerOrModal, ProductDetailsContent } from "@kanpla/ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  setOpen: (nextState: boolean) => void;
  open: boolean;
  product: CombinedOfferItem;
  currentDay: {
    available: true;
    stock?: number;
    menu?: MenuItem;
  };
  date?: string;
  module?: Module;
  schoolId?: string;
  mealOptions?: FlexOption[];
  order?: OrderOrder;
  orderInfo?: OrderInfo;
}

export const ProductInfosModal = ({
  open,
  setOpen,
  product,
  currentDay,
  date,
  module,
  schoolId,
  mealOptions,
  order,
  orderInfo,
}: Props) => {
  const { t } = useTranslation(["translation"]);

  const actions: ActionType[] = [
    {
      label: t("translation:close"),
      onClick: () => setOpen(false),
    },
  ];

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={actions}
      noPadding={true}
      floatingActions
    >
      <ProductDetailsContent
        product={product}
        currentDay={currentDay}
        currentDayTimestamp={Number(date)}
        module={module}
        schoolId={schoolId}
        mealOptions={mealOptions}
        noImage={true}
        order={order}
        orderInfo={orderInfo}
        hideVariants={true}
        noWrapperMargin={true}
      />
    </DrawerOrModal>
  );
};
