import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  position: "start" | "center" | "end";
}

const Button = ({ onClick, children, position, ...buttonProps }: Props) => {
  return (
    <button
      className={`w-8 flex items-center justify-center group relative border-2 ${
        position === "start" ? "rounded-l-md" : ""
      } ${
        position === "end" ? "rounded-r-md" : ""
      } overflow-hidden color-primary-main border-primary-main`}
      style={{ marginLeft: position !== "start" && -2 }}
      onClick={onClick}
      {...buttonProps}
    >
      <div className="absolute inset-0 opacity-0 bg-primary-main group-hover:opacity-10 transition-opacity"></div>
      {children}
    </button>
  );
};

export default Button;
