import { editConfigAmount, priceFormatter } from "@kanpla/system";
import { FlexOption } from "@kanpla/types";
import { InputNumber } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VariantsContext } from ".";

const OneLayer = () => {
  const {
    product,
    order,
    orderProduct,
    setOrder,
    maxAmount,
    availableOptions,
  } = useContext(VariantsContext);

  const { i18n } = useTranslation(["translation"]);

  const optionLayer: FlexOption = availableOptions[0];

  return (
    <div>
      {optionLayer.choices.map((choice) => {
        const isTargetConfig = (c) => c.options?.[choice.id]?.amount > 0;

        const amount =
          orderProduct?.config?.find((c) => isTargetConfig(c))?.amount ?? 0;

        const onChangeChoice = (newAmount: number) => {
          // If not a readable value, just return
          if (
            typeof newAmount !== `number` ||
            newAmount < 0 ||
            newAmount === amount
          ) {
            return;
          }

          const options = {
            [choice.id]: {
              name: choice.name,
              extraPrice: choice.price,
              amount: 1,
            },
          };

          const newOrder = editConfigAmount({
            order,
            productId: product.productId,
            choice: options,
            amountChange: newAmount,
            replaceAmount: true,
          });

          setOrder(newOrder);
        };

        return (
          <div
            className="flex justify-between items-center border-b border-divider-main text-text-primary py-2"
            key={choice.id}
          >
            <div>
              {choice.name}{" "}
              {choice.price ? (
                <span className="text-sm text-text-disabled">
                  (+{priceFormatter(choice.price, i18n)})
                </span>
              ) : null}
            </div>
            <div>
              <InputNumber
                max={maxAmount}
                value={amount}
                onChange={onChangeChoice}
                className="mr-2 w-16 text-text-primary"
                pattern="[0-9]*"
                inputMode="numeric"
              />{" "}
              stk.
            </div>
          </div>
        );
      })}
      <div className="flex justify-between items-center text-text-primary pt-2 pb-4">
        <div>i alt.</div>
        <div>{orderProduct?.amount || 0} stk.</div>
      </div>
    </div>
  );
};

export default OneLayer;
