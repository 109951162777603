import {
  callFunction,
  getActivePlugins,
  UseAuth,
  UseLocale,
  useSubmit,
  UseTimeNavigation,
  useWindowSize,
} from "@kanpla/system";
import { EmulatorController } from "@kanpla/ui";
import moment from "moment";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { Child, DataAuthenticationModal, LoadOfferReturn } from "@kanpla/types";
import { createContainer, useContainer } from "unstated-next";
import { usePosoneBalance } from "../lib/posone/useBalance";
import { useBalance } from "../lib/useBalance";
import useBasket from "../lib/useBasket";
import { useCustomerCard } from "../lib/useCustomerCard";
import NewPayment from "./modals/payment/index";
import CustomIntercom from "./UseIntercom";
import useLoadFrontend from "./useLoadFrontend";
import UseModules from "./useModules";
import { useStatusBar } from "../lib/useStatusBar";
import { useLocalstorageState } from "rooks";
import { UpdateChildProps } from "../pages/api/internal/user/updateChild";
import { LoadFrontendResult } from "../pages/api/internal/offers/loadFrontend";
import MobileDetect from "mobile-detect";

const ContextState = () => {
  const { i18n } = useTranslation(["translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  // const router = useRouter();

  const { update } = useIntercom();

  const router = useRouter();

  const auth = UseAuth({ updateIntercomUser: update });
  const { user: authUser, loading: userLoading } = auth;
  const [overrideUser, setOverrideUser] = useState(authUser);

  // Carousel
  const [shouldShowCarousel, setShouldShowCarousel] =
    useLocalstorageState<boolean>("should-show-carousel", true);

  // Native status bar
  const { statusBarStyle, setStatusBarStyle } = useStatusBar();

  useEffect(() => {
    setOverrideUser(authUser);
  }, [authUser?.uid]);

  const userId = overrideUser?.uid || (overrideUser as any)?.id || null;

  // MODULE WRAPPER
  const [isBulk, setIsBulk] = useState<boolean | null>(null);

  // UI
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarHasContent, setNavbarHasContent] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  // Popup interaction
  const [showPopupInteractionAnimation, setShowPopupInteractionAnimation] =
    useState(false);

  // Time
  const {
    week,
    setWeek,
    timeNavigation,
    setTimeNavigation,
    dayIndex,
    setDayIndex,
    date,
    setDate,
    dateSeconds,
    displayDate,
  } = UseTimeNavigation();

  const [__kanplaGoOrderFromWindow, __setKanplaGoOrderFromWindow] =
    useState(false);

  // Load everything
  const {
    user,
    isSuperadmin,
    card: receivedCard,
    cards: receivedCards,
    children = [],
    child,
    schools: childSalesplaceSchools,
    school,
    allModules = [],
    modules = [],
    supplier,
    setChildId,
    getOffer,
    loading,
    initialLoading,
    triggerReload,
    setData,
  } = useLoadFrontend({
    userId,
  });

  // Sub values
  const schoolId = school?.id;
  const childId = child?.id;
  const { partnerId = null } = school || {};
  const defaultReference = (child as Child)?.defaultReference;
  const appLoading = loading;
  const moduleLoading = loading;
  const innerAppLoading = loading;
  const customBranding = supplier;
  const customBrandingLoading = initialLoading;

  // Hold states
  const [isSaving, setIsSaving] = useState(0);
  const { balance, setBalance } = useBalance({ isSaving, user });
  const { moduleId, setModuleId, module } = UseModules({
    modules: modules || [],
  });

  const [os, setOs] = useState<string>(null);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const md = new MobileDetect(window.navigator.userAgent);
    setOs(md.os());
  }, [typeof window]);

  const offer: LoadOfferReturn = getOffer(moduleId);
  const {
    card,
    cards = [],
    setCard,
    loadCards,
  } = useCustomerCard({
    userId,
    card: receivedCard,
    cards: receivedCards,
  });

  /**
   * It's a condition needed at the very first login from the landing page.
   * If the school id inside the child it's equal to the current school id,
   * we will return the current school id inside the child (most needed in the anonymous users flow),
   * otherwise we will return the current school id if it's truthy or the current child
   * school id.
   * Try to remove the last OR before the OR that return the value null (|| child?.schoolId),
   * and you will see something funny.
   */
  // const childSchoolId =
  //   (child?.schoolId === schoolId
  //     ? child?.schoolId
  //     : schoolId || child?.schoolId) || null;

  // Update functions
  const { submit: updateCurrentChild } = useSubmit<
    UpdateChildProps,
    Child,
    typeof setData
  >({
    path: "user/updateChild",
    setData,
    requestConstructor: (data) => ({
      childId: data.id || child.id,
      child: data,
    }),
    responseDestructor: (response) => ({
      child: { ...child, ...response },
      children: [
        ...children.filter((ch) => ch.id !== child.id),
        { ...child, ...response },
      ],
    }),
  });

  // Module props
  const hasKanplaGo = module?.plugins?.kanplaGo?.active;
  const hasPayPerOrder = module?.plugins?.payPerOrder?.active;
  const hasShowSaldoAnyways = module?.plugins?.payPerOrder?.showSaldoAnyways;
  const withCredit =
    module &&
    module.type === "mealplan" &&
    (module.paymentMethod === "credit" || module.paymentMethod === "posOne") &&
    !hasKanplaGo &&
    (!hasPayPerOrder || (hasPayPerOrder && hasShowSaldoAnyways));

  const {
    isPosone,
    balance: posoneBalance,
    getBalance: getPosoneBalance,
    balanceLoading: posoneBalanceLoading,
    posonePartnerData,
  } = usePosoneBalance({ partnerId, userId, auth });

  const [deficit, setDeficit] = useState(null);
  const [pleasePayOpen, setPleasePayOpen] = useState(false);

  // Animation: pulse
  const [pulseCounter, setPulseCounter] = useState(0);

  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || screenWidth < 768;

  /**
   * New Cross Basket
   */
  const {
    basket,
    setBasket,
    openBasket,
    setOpenBasket,
    basketContainerUtils,
    basketContainer,
    setBasketContainer,
  } = useBasket({
    userId,
    module,
    schoolId,
    childId,
    dateSeconds,
  });

  // // Total price of items inside the basket
  // const basketPrice = calculateMultipleOrdersTotal({ orders: newBasket });

  const [paymentOpen, setPaymentOpen] = useState(false);

  const { allLanguages, localeFrom, setLocaleFrom } = UseLocale({
    school,
    user,
    customBranding,
  });

  const activePlugins = getActivePlugins({
    module,
    schoolId,
    groupName: child?.groupName,
  });

  // Supplier info
  const isChildSalesplace = supplier?.childIsSalesplace || false;
  // Available salesplaces if the supplier has `isChildSalesplace` property
  // const { childSalesplaceSchools } = useChildSalesplace({
  //   userId,
  //   isChildSalesplace,
  //   children,
  // });

  // Authentication anonymous user
  const [openAuthenticationModal, setOpenAuthenticationModal] =
    useState<boolean>(false);

  const [dataAuthenticationModal, setDataAuthenticationModal] =
    useState<DataAuthenticationModal>({ action: "login" });
  const [fromAPrivateModule, setFromAPrivateModule] = useState<boolean>(false);

  // Tracking id is the notification reference that the user has opened
  const trackingId = useMemo(() => {
    const { trackingId } = router.query;
    if (!trackingId) return null;

    return trackingId as string;
  }, [router.query]);

  // Track notification events
  useEffect(() => {
    if (!trackingId || !childId || !schoolId || !partnerId) return;

    (async () => {
      await callFunction("popups-registerSeenNotificationEvent", {
        schoolId,
        childId,
        partnerId,
        notificationId: trackingId,
      });
    })();
  }, [trackingId, childId, schoolId, partnerId]);

  const [openSalesplaceModal, setOpenSalesplaceModal] = useState(false);

  return {
    // TEMPORARY!!
    openSalesplaceModal,
    setOpenSalesplaceModal,

    // DATA: User
    auth,
    userId,
    user,
    isSuperadmin,
    setOverrideUser, // super-admin!

    // DATA: Money
    deficit,
    setDeficit,
    balance,
    setBalance,

    // DATA: Payment cards
    card,
    cards,
    setCard,
    loadCards,

    // DATA: Children + child
    children,
    child,
    childId,
    setChildId,
    defaultReference,

    // DATA: School
    school,
    schoolId,
    partnerId,
    supplier,
    isChildSalesplace,

    // Available schools if `isChildSalesplace`
    childSalesplaceSchools,

    // DATA: Modules
    allModules,
    modules,
    module,
    moduleId,
    setModuleId,
    withCredit,
    isBulk,
    setIsBulk,

    // DATA: Custom branding
    customBranding,

    customBrandingLoading,

    // UI: Time navigation
    week,
    setWeek,
    /* Selected day index */
    dayIndex,
    setDayIndex,
    date,
    dateSeconds,
    setDate,
    displayDate,
    timeNavigation,
    setTimeNavigation,

    isSaving,
    setIsSaving,

    appLoading,
    innerAppLoading,
    moduleLoading,

    // UI: Animation
    pulseCounter,
    setPulseCounter,

    // DATA: PosOne
    isPosone,
    posonePartnerData,
    posoneBalance,
    getPosoneBalance,
    posoneBalanceLoading,

    // DATA: Basket
    basket,
    setBasket,
    openBasket,
    setOpenBasket,
    basketContainerUtils,
    basketContainer,
    setBasketContainer,

    // DATA: i18n
    allLanguages,
    localeFrom,
    setLocaleFrom,

    // UI: Navigation
    menuOpen,
    setMenuOpen,
    navbarHasContent,
    setNavbarHasContent,

    // UI: Payment modals
    paymentOpen,
    setPaymentOpen,
    pleasePayOpen,
    setPleasePayOpen,

    // UI: Device detect
    mobile,

    // Popup interaction
    showPopupInteractionAnimation,
    setShowPopupInteractionAnimation,

    // Authentication modal
    openAuthenticationModal,
    setOpenAuthenticationModal,
    dataAuthenticationModal,
    setDataAuthenticationModal,
    fromAPrivateModule,
    setFromAPrivateModule,

    __kanplaGoOrderFromWindow,
    __setKanplaGoOrderFromWindow,
    activePlugins,

    historyOpen,
    setHistoryOpen,

    // Carousel
    shouldShowCarousel,
    setShouldShowCarousel,

    // Native status bar style
    statusBarStyle,
    setStatusBarStyle,

    offer,
    getOffer,

    triggerReload,

    os,
    updateCurrentChild,
  };
};

export const AppContext = createContainer(ContextState);

interface Props {
  children: ReactNode;
}

const ContextProvider = (props: Props) => {
  return (
    <AppContext.Provider>
      {props.children}
      <Extras />
    </AppContext.Provider>
  );
};

const Extras = () => {
  const { paymentOpen, setPaymentOpen } = useContainer(AppContext);

  return (
    <>
      <CustomIntercom />
      <NewPayment open={paymentOpen} setOpen={setPaymentOpen} />
      <EmulatorController />
    </>
  );
};

export default ContextProvider;
