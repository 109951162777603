/**
 * Removed *recursively* undefined keys in an object
 * @param obj Object to remove undefined keys from
 * @param recursive Boolean, should it run through rest?
 * @param recursiveDeep Boolean, should it run through rest in loop?
 */
export const removeUndefinedKeys = (
  obj: Object = {},
  recursive = false,
  recursiveDeep = false
) => {
  Object.keys(obj || {}).forEach(function (key) {
    // Get this value and its type
    const value = obj[key];
    const type = typeof value;
    if (type === "object") {
      // Recurse...
      if (recursive) removeUndefinedKeys(value, recursiveDeep, recursiveDeep);
      // ...and remove if now "empty" (NOTE: insert your definition of "empty" here)
      if (!Object.keys(value || {}).length) {
        delete obj[key];
      }
    } else if (type === "undefined" || (type === "number" && isNaN(obj[key]))) {
      // Undefined, remove it
      delete obj[key];
    }
  });
};
