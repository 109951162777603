import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { setLabelsList } from "@kanpla/system";
import { Product } from "@kanpla/types";

const labelsList = (props?) => setLabelsList(props);

const Label = ({ name, onlyOutline = false }) => {
  const { t, i18n } = useTranslation(["libs"]);
  const dictionaries = {
    vegan: t("libs:labels-list.vegan"),
    vegetarian: t("libs:labels-list.vegetarian"),
    "gluten-free": t("libs:labels-list.gluten-free"),
    "lactose-free": t("libs:labels-list.lactose-free"),
    eco: t("libs:labels-list.eco"),
    "partly-eco": t("libs:labels-list.partly-eco"),
    popular: t("libs:labels-list.popular"),
    "surplus-food": t("libs:labels-list.surplus-food"),
    halal: t("libs:labels-list.halal"),
    news: t("libs:labels-list.news"),
    "only-today": t("libs:labels-list.only-today"),
    "only-this-week": t("libs:labels-list.only-this-week"),
    "urgent-sale": t("libs:labels-list.urgent-sale"),
    "animal-care": t("libs:labels-list.animal-care"),
  };

  const background = isEmpty(i18n)
    ? labelsList()[name].background
    : labelsList(dictionaries)[name].background;
  const color = isEmpty(i18n)
    ? labelsList()[name].color
    : labelsList(dictionaries)[name].color;
  const label = isEmpty(i18n)
    ? labelsList()[name].label
    : labelsList(dictionaries)[name].label;

  if (onlyOutline)
    return (
      <div
        className="group flex items-center rounded ml-px mb-px"
        style={{ border: `1px solid ${color}`, padding: "4px 8px" }}
      >
        <span
          className="text-xs uppercase font-semibold whitespace-nowrap"
          style={{ color: color || "#FFFFFF", marginRight: 2 }}
        >
          {label}
        </span>
        <div
          className=" w-4 h-4 flex justify-center"
          style={{ marginLeft: 2, marginBottom: 1 }}
        >
          <img src={`/images/labels/${name}.svg`} className="w-full" alt="" />
        </div>
      </div>
    );

  return (
    <div
      className="group flex items-center"
      style={{ background, padding: "4px 8px" }}
    >
      <span
        className="text-xs uppercase font-semibold whitespace-nowrap text-white"
        style={{ marginRight: 2 }}
      >
        {label}
      </span>
      <div
        className=" w-4 h-4 flex justify-center"
        style={{ marginLeft: 2, marginBottom: 1 }}
      >
        <img src={`/images/labels/${name}.svg`} className="w-full" alt="" />
      </div>
    </div>
  );
};

interface Props {
  labels: Product["labels"];
  classNames?: string;
  /** Outlines the labels as separate */
  onlyOutline?: boolean;
}

export const InlineLabels: FC<Props> = ({
  labels,
  classNames,
  onlyOutline,
}) => {
  if (
    !labels ||
    Object.entries(labels).filter(([_, active]) => active).length === 0
  )
    return null;

  return (
    <div
      className={classnames(
        "inline-flex md:rounded overflow-hidden",
        classNames
      )}
    >
      {Object.entries(labels).map(([name, value]) => {
        if (!value) return null;
        return <Label name={name} key={name} onlyOutline={onlyOutline} />;
      })}
    </div>
  );
};
