import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  IBaseProducts,
  ISelectProductByDayProps,
  OrderInfo,
  OrderOrder,
  Supplier,
} from "@kanpla/types";
import { Collapse } from "antd";
import classnames from "classnames";
import React, { Dispatch, SetStateAction } from "react";
import { NoLunchButton } from "./NoLunchButton";
import { ProductItem } from "./ProductItem";

interface Props {
  categories: {
    [key: string]: CombinedOfferItem[];
  };
  isBulk: boolean;
  date: string;
  selectedProducts: IBaseProducts;
  fromStandard?: boolean;
  mobile?: boolean;
  hidePrices?: boolean;
  schoolId?: string;
  setSelectedProduct: Dispatch<SetStateAction<CombinedOfferItem>>;
  setOpenVariants: Dispatch<SetStateAction<boolean>>;
  setOpenProductInfos: Dispatch<SetStateAction<boolean>>;
  setDayDateSeconds: Dispatch<SetStateAction<string>>;
  selectProductByDay: (props: ISelectProductByDayProps) => void;
  onPurchase?: (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => Promise<void>;
  submit: (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string
  ) => Promise<void>;
  setStandardWeek?: Dispatch<SetStateAction<FlexBulkStandard["standard"]>>;
  isPast?: boolean;
  weekProducts?: IBaseProducts;
  supplier?: Supplier;
}

export const ProductsList = ({
  fromStandard = false,
  hidePrices = false,
  categories,
  selectedProducts,
  isBulk,
  date,
  mobile,
  schoolId,
  setSelectedProduct,
  setOpenProductInfos,
  setOpenVariants,
  setDayDateSeconds,
  selectProductByDay,
  onPurchase,
  submit,
  setStandardWeek,
  isPast,
  weekProducts,
  supplier,
}: Props) => {
  const productIsFromStandard = weekProducts?.[date]?.["isStandard"];

  /**
   * The layout change based on the number of categories
   * and their products
   */
  const isMultipleCategories =
    Object.keys(categories).length > 1 &&
    Object.entries(categories).filter(([_, products]) => products.length)
      .length > 1;

  const singleProducts = (categories as { [key: string]: CombinedOfferItem[] })[
    Object.keys(categories)[0]
  ];

  if (fromStandard || mobile) {
    return (
      <>
        {isMultipleCategories
          ? Object.entries(categories || {}).map(
              ([category, productsDay], index) => {
                return (
                  <Collapse
                    key={`${category}-${index}`}
                    expandIcon={({ isActive }) => {
                      return (
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className={`!text-xl transition-all ${
                            isActive
                              ? "!transform !rotate-90 !-translate-y-1/2"
                              : "!transform !rotate-0 !-translate-y-1/2"
                          }`}
                        />
                      );
                    }}
                    bordered={false}
                    expandIconPosition="end"
                    className="bg-background-secondary rounded-md mb-3"
                  >
                    <Collapse.Panel
                      header={
                        <h4 className="h400">{category || "Dagens ret"}</h4>
                      }
                      key={index}
                    >
                      {productsDay.map((product) => {
                        return (
                          <ProductItem
                            supplier={supplier}
                            fromStandard={fromStandard}
                            date={date}
                            product={product}
                            selectedProducts={selectedProducts}
                            selectProductByDay={selectProductByDay}
                            isSingleCategory={!isMultipleCategories}
                            hidePrices={hidePrices}
                            schoolId={schoolId}
                            setSelectedProduct={setSelectedProduct}
                            setOpenProductInfos={setOpenProductInfos}
                            setOpenVariants={setOpenVariants}
                            setDayDateSeconds={setDayDateSeconds}
                            onPurchase={onPurchase}
                            setStandardWeek={setStandardWeek}
                            isBulk={isBulk}
                            isPast={isPast}
                            isItemFromStandard={productIsFromStandard}
                          />
                        );
                      })}
                    </Collapse.Panel>
                  </Collapse>
                );
              }
            )
          : singleProducts.map((product) => {
              return (
                <ProductItem
                  supplier={supplier}
                  fromStandard={fromStandard}
                  date={date}
                  product={product}
                  selectedProducts={selectedProducts}
                  selectProductByDay={selectProductByDay}
                  isSingleCategory={!isMultipleCategories}
                  full={true}
                  hidePrices={hidePrices}
                  schoolId={schoolId}
                  setSelectedProduct={setSelectedProduct}
                  setOpenProductInfos={setOpenProductInfos}
                  setOpenVariants={setOpenVariants}
                  setDayDateSeconds={setDayDateSeconds}
                  onPurchase={onPurchase}
                  setStandardWeek={setStandardWeek}
                  isBulk={isBulk}
                  isPast={isPast}
                  isItemFromStandard={productIsFromStandard}
                />
              );
            })}
        {!isBulk && (
          <NoLunchButton
            date={date}
            selectProductByDay={selectProductByDay}
            selectedProducts={selectedProducts}
            full={true}
            submit={submit}
            setStandardWeek={setStandardWeek}
            isBulk={isBulk}
            fromStandard={fromStandard}
            isPast={isPast}
          />
        )}
      </>
    );
  }

  const wrapperCategoryClassNames = classnames({
    "bg-background-secondary rounded": true,
    "pointer-events-none opacity-30": isPast,
  });

  return isMultipleCategories ? (
    <div className="flex flex-wrap gap-6">
      {Object.entries(categories as { [key: string]: CombinedOfferItem[] }).map(
        ([category, products]) => {
          return (
            <div
              style={{
                width: "calc(33.333333% - 24px)",
              }}
              className={wrapperCategoryClassNames}
            >
              <div className="px-3 py-2">
                <h4 className="h400 mb-4">{category || "Dagens ret"}</h4>
                {products?.map((product) => {
                  return (
                    <ProductItem
                      supplier={supplier}
                      date={date}
                      product={product}
                      selectedProducts={selectedProducts}
                      selectProductByDay={selectProductByDay}
                      hidePrices={hidePrices}
                      schoolId={schoolId}
                      setSelectedProduct={setSelectedProduct}
                      setOpenProductInfos={setOpenProductInfos}
                      setOpenVariants={setOpenVariants}
                      setDayDateSeconds={setDayDateSeconds}
                      onPurchase={onPurchase}
                      isItemFromStandard={productIsFromStandard}
                    />
                  );
                })}
              </div>
            </div>
          );
        }
      )}
      {!isBulk && (
        <NoLunchButton
          date={date}
          selectProductByDay={selectProductByDay}
          selectedProducts={selectedProducts}
          submit={submit}
          setStandardWeek={setStandardWeek}
          isBulk={isBulk}
          fromStandard={fromStandard}
          isPast={isPast}
        />
      )}
    </div>
  ) : (
    <div className="flex flex-wrap gap-6 justify-between">
      {singleProducts?.map((product) => {
        return (
          <ProductItem
            supplier={supplier}
            date={date}
            product={product}
            selectedProducts={selectedProducts}
            selectProductByDay={selectProductByDay}
            isSingleCategory={!isMultipleCategories}
            hidePrices={hidePrices}
            schoolId={schoolId}
            setSelectedProduct={setSelectedProduct}
            setOpenProductInfos={setOpenProductInfos}
            setOpenVariants={setOpenVariants}
            setDayDateSeconds={setDayDateSeconds}
            onPurchase={onPurchase}
            isPast={isPast}
            isItemFromStandard={productIsFromStandard}
          />
        );
      })}
      {!isBulk && (
        <NoLunchButton
          date={date}
          selectProductByDay={selectProductByDay}
          selectedProducts={selectedProducts}
          submit={submit}
          setStandardWeek={setStandardWeek}
          isBulk={isBulk}
          fromStandard={fromStandard}
          isPast={isPast}
        />
      )}
    </div>
  );
};
