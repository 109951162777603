import { db } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { Image } from "@kanpla/ui";
import { message } from "antd";
import React, { useEffect } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { image } from "./iosBackground";

interface Props {
  partnerId: string;
  /** Notification title */
  title: string;
  /** Notfication body content */
  body: string | (() => string);
  /** Makes the component grayscale */
  disabled?: boolean;
  /** Notification link */
  link?: string;
  /** If the component it's called from a details modal */
  fromDetails?: boolean;
  /** Hides the iOS background */
  noBackground?: boolean;
  /** Removes the padding */
  noPadding?: boolean;
}

export const NotificationPreview = (props: Props) => {
  const {
    partnerId,
    title,
    body: bodyProp,
    disabled,
    link,
    fromDetails,
    noBackground = false,
    noPadding = false,
  } = props;

  const [brandingArray = [], brandingLoading, brandingError] =
    useCollectionDataOnce<Supplier>(
      partnerId
        ? db.collection("suppliers").where("partnerId", "==", partnerId)
        : null
    );

  const singleBranding = brandingArray?.[0];
  const body = typeof bodyProp === "function" ? bodyProp() : bodyProp;

  const hasLogo = singleBranding?.logoUrl;

  // Branding error
  useEffect(() => {
    if (!brandingError) return;
    console.error(brandingError);
    message.error(brandingError?.message);
  }, [brandingError]);

  return (
    <div
      className={`w-full ${noPadding ? "" : "p-4"} ${
        fromDetails ? "" : "mt-4"
      } relative rounded-2xl overflow-hidden`}
    >
      {!noBackground && (
        <div className="absolute inset-0">
          <Image
            className={`absolute inset-0 object-cover object-center z-hidden h-full w-full ${
              disabled ? "grayscale" : ""
            }`}
            src={image}
            alt="Image"
          />
        </div>
      )}
      <div
        className={`relative ${
          noBackground ? "" : "shadow-2xl"
        } bg-opacity-70 text-black bg-white backdrop-filter backdrop-blur-md ${
          brandingLoading ? "loader" : ""
        } rounded-xl p-3 w-full z-30 max-w-md mx-auto`}
      >
        {/* Branding */}
        <div className="flex text-xs items-center">
          <div
            className={`w-5 h-5 overflow-hidden rounded ${
              disabled ? "grayscale" : ""
            }`}
            style={{
              backgroundColor: hasLogo ? "" : singleBranding?.color || "#FFFFF",
            }}
          >
            <Image
              className={`w-full h-full object-center object-contain ${
                disabled ? "grayscale" : ""
              }`}
              src={
                singleBranding?.logoUrl
                  ? singleBranding.logoUrl
                  : "https://kanpla.dk/images/icon.png"
              }
              alt={singleBranding?.name ? singleBranding.name : "Kanpla"}
            />
          </div>
          <p className="uppercase ml-2">
            {singleBranding?.name ? singleBranding.name : "Kanpla"}
          </p>
          <p className="ml-auto">3m siden</p>
        </div>
        {/* Content */}
        <div className="flex flex-col text-sm mt-2">
          <p className="font-semibold">{title}</p>
          <p>
            {body?.substring(0, 179)}
            {body?.length >= 178 && "..."}
          </p>
          {link && (
            <a href={link} className="mt-2 font-semibold">
              {link?.substring(0, 179)}
              {link?.length >= 178 && "..."}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationPreview;
