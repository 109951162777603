import {
  faCircleCheck,
  faOctagonXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  result: boolean;
}

/**
 * A coloured read-only icon based on the result
 * @param Send in result to display it
 * @returns Returns a coloured read-only icon
 */
export const BooleanDisplay = ({ result }: Props) => {
  if (result)
    return (
      <FontAwesomeIcon icon={faCircleCheck} className="text-success-main" />
    );
  return <FontAwesomeIcon icon={faOctagonXmark} className="text-danger-main" />;
};
