import { Scope } from "@kanpla/types";

type Props = {
  scope: Scope | null;
  schoolId: string;
  groupName: string;
};

export const isSchoolSelectorInScope = ({
  scope,
  schoolId,
  groupName,
}: Props) => {
  // 2. Availability by school
  const isAvailableForSchool =
    scope === null || scope?.generatedSchoolIds?.includes(schoolId);

  // 2.1 Selectors
  const selectorStrings = scope?.schools?.[schoolId]?.selectorStrings || [];
  const isLimitingSelectors = selectorStrings?.length > 0;

  const isAvailableForSelector =
    !isLimitingSelectors || selectorStrings.includes(groupName);

  return isAvailableForSchool && isAvailableForSelector;
};
