import { callInternalApi } from "@kanpla/system";
import useSWR, { Key, SWRConfiguration, useSWRConfig } from "swr";

export const useFetch = <RequestData extends Key, ResponseType>(
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string,
  /** Data passed as props to the api */
  requestData: RequestData,
  /** Extra configuration for the fetching, follows SWR config */
  config?: SWRConfiguration
) => {
  const { mutate } = useSWRConfig();

  type Fetcher = (req: RequestData) => Promise<ResponseType>;

  const fetcher: Fetcher = async (keys) => {
    const res = await callInternalApi(path, keys);
    return res;
  };

  // Fetch
  const { data, isValidating } = useSWR<ResponseType>(
    requestData,
    fetcher,
    config
  );

  // Update
  const setData = <NewDataType>(
    /** Data as your expect to receive it from the updater */
    newData: NewDataType,
    /** The actual updater ('submit' from useSubmit hook) */
    updater: () => Promise<NewDataType>,
    /** Transforms the data into a partial that can be merge with the current data */
    dataTransformer: (newData: NewDataType) => Partial<ResponseType>
  ) => {
    const mergeData = (partial: NewDataType) => ({
      ...data,
      ...(dataTransformer(partial) || {}),
    });

    const fullUpdater = async () => {
      const result = (await updater()) || newData;
      return mergeData(result);
    };

    mutate(requestData, fullUpdater, {
      optimisticData: mergeData(newData),
      revalidate: false,
      // populateCache,
      rollbackOnError: true,
    });
  };

  return { data, isValidating, setData };
};
