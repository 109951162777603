import {
  getCurrentWeek,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
} from "@kanpla/system";
import {
  DayIndex,
  TimeNavigation,
  Timestamp as TimestampType,
  Week,
} from "@kanpla/types";
import { useEffect, useState } from "react";
import { NumberParam, useQueryParam } from "next-query-params";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useRouter } from "next/router";

export const UseTimeNavigation = () => {
  const { t } = useTranslation(["translation"]);
  const router = useRouter();

  const [timeNavigation, setTimeNavigation] =
    useState<TimeNavigation>("todaySwitch");
  const [week, setWeek] = useState<Week>(getCurrentWeek({ Timestamp }));
  const [dayIndex, setDayIndex] = useState<DayIndex>(0); // Can be 0,1,2,3,4
  const date = week[dayIndex];
  const dateSeconds = date?.seconds;
  const setDate = (newDate: TimestampType) => {
    const jsDate = new Date(newDate.seconds * 1000);
    let newDateIndex = (jsDate.getDay() - 1) as DayIndex;
    if (typeof newDateIndex !== `number`) return;
    if (newDateIndex > 4) newDateIndex = 4;
    if (newDateIndex < 0) newDateIndex = 0;
    if (setDayIndex) setDayIndex(newDateIndex);

    // Adjust week
    const newWeek = getWeekArray(getWeekSeconds(newDate.seconds), Timestamp);
    setWeek(newWeek);
  };

  const [paramDateSeconds, setParamDateSeconds] = useQueryParam(
    "date",
    NumberParam
  );

  useEffect(() => {
    if (!paramDateSeconds) return;
    if (paramDateSeconds === dateSeconds) return;
    setDate(Timestamp.fromMillis(paramDateSeconds * 1000));
  }, [paramDateSeconds]);

  useEffect(() => {
    // Wait for the router to be ready before resetting the URL
    if (!router.isReady) return;
    if (paramDateSeconds === dateSeconds) return;
    setParamDateSeconds(dateSeconds);
  }, [dateSeconds, router.isReady]);

  const calendarFormatter = {
    sameDay: `[${t("translation:today").toLowerCase()}]`,
    nextDay: `[${t("translation:tomorrow")}]`,
    nextWeek: "dddd",
    lastDay: `[${t("translation:yesterday")}]`,
    lastWeek: `[${t("translation:last")}] dddd`,
    sameElse: "dddd, D/M",
  };

  const displayDate = moment
    .unix(week[dayIndex]?.seconds || 0)
    .calendar(null, calendarFormatter);

  return {
    week,
    setWeek,
    dayIndex,
    setDayIndex,
    date,
    setDate,
    dateSeconds,
    calendarFormatter,
    displayDate,
    timeNavigation,
    setTimeNavigation,
  };
};
