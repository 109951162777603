import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getProductName,
  getWeekDays,
  isDatePastDeadline,
  Timestamp,
} from "@kanpla/system";
import {
  CombinedOffer,
  IBaseProducts,
  IGetCurrentProductsProps,
  Week,
} from "@kanpla/types";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  selectedProducts: IBaseProducts;
  getCurrentProducts: (props: IGetCurrentProductsProps) => void;
  plainProducts: CombinedOffer;
  week: Week;
  deadline: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  selectProductOpen: boolean;
}

export const MenuViewMobile = (props: Props) => {
  const {
    selectedProducts,
    getCurrentProducts,
    plainProducts,
    week,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    selectProductOpen,
  } = props;

  const { t, i18n } = useTranslation(["translation", "libs", "flex"]);

  const dictionaries = [
    t("libs:week-days.monday"),
    t("libs:week-days.tuesday"),
    t("libs:week-days.wednesday"),
    t("libs:week-days.thursday"),
    t("libs:week-days.friday"),
  ];

  const days = isEmpty(i18n) ? dictionaries : getWeekDays(i18n.language);

  const [currentDayIndex, setCurrentDayIndex] = useState(-1);

  useEffect(() => {
    if (!selectProductOpen) setCurrentDayIndex(-1);
  }, [selectProductOpen]);

  return (
    <div className="pt-1">
      {days.map((label, dayIndex) => (
        <DayMenuViewMobile
          key={dayIndex.toString()}
          label={label}
          selectedProducts={selectedProducts}
          getCurrentProducts={getCurrentProducts}
          plainProducts={plainProducts}
          daySeconds={week?.[dayIndex]?.seconds}
          isItemFromStandard={
            selectedProducts?.[week?.[dayIndex]?.seconds]?.["isStandard"] &&
            selectedProducts?.[week?.[dayIndex]?.seconds]?.id !== "noLunch"
          }
          isPast={isDatePastDeadline({
            date: new Timestamp(Number(week?.[dayIndex]?.seconds), 0),
            deadline,
            deadlineWeekRelative,
            deadlineExcludingWeekends,
          })}
          currentDayIndex={currentDayIndex}
          setCurrentDayIndex={setCurrentDayIndex}
          dayIndex={dayIndex}
        />
      ))}
    </div>
  );
};

export const DayMenuViewMobile = ({
  label,
  selectedProducts,
  getCurrentProducts,
  plainProducts,
  daySeconds,
  isItemFromStandard,
  isPast,
  dayIndex,
  currentDayIndex,
  setCurrentDayIndex,
}) => {
  const { t } = useTranslation(["translation"]);

  const today = useMemo(() => {
    return moment().startOf("day").unix() === Number(daySeconds)
      ? ` (${t("translation:today")})`
      : "";
  }, [label]);

  const displayProduct = () => {
    const dateSeconds = Object.entries(selectedProducts || {}).find(
      ([dateSeconds, _]) => {
        const dayName = moment
          .unix(Number(dateSeconds))
          .format("dddd")
          .toLowerCase();

        const isSameDay = dayName === (label as string).toLowerCase();

        return isSameDay;
      }
    )?.[0];

    if (!dateSeconds) return null;

    const productId = selectedProducts[dateSeconds].id;

    const product = plainProducts.find((product) => product.id === productId);

    const wrapperClassNames = classnames({
      "bg-secondary-dark": !product,
      "bg-primary-main": product,
      "bg-blue-600": isItemFromStandard,
    });

    const productInfos = getProductName({
      product,
      date: dateSeconds,
      noLunch: t("translation:no-breakfast"),
    });

    return (
      <>
        <div
          style={{
            minWidth: "32px",
          }}
          className={`w-8 aspect-square rounded-full self-center flex items-center justify-center ${wrapperClassNames}`}
        >
          {product && (
            <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
          )}
        </div>
        <div>
          <p className="text-sm text-secondary-dark">{`${label}${today}`}</p>
          <p className="text-lg">{productInfos.name}</p>
        </div>
      </>
    );
  };

  const dayClassNames = classnames({
    "opacity-30 pointer-events-none": isPast,
    "shadow-lg": currentDayIndex === dayIndex,
  });

  const onClickDay = () => {
    setCurrentDayIndex(dayIndex);
  };

  return (
    <div
      onClick={() => {
        getCurrentProducts({ label });
        onClickDay();
      }}
      className={`flex px-5 py-4 gap-x-4 items-center rounded-lg transition-all cursor-pointer ${dayClassNames}`}
    >
      {displayProduct()}
    </div>
  );
};
