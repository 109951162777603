/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { FirebaseFirestore } from "@firebase/firestore-types";
import { isProductAvailable } from "./isProductAvailable";
import { getModuleList, sortProducts } from "@kanpla/system";
import { DayIndex, Module, Product, ProductBank } from "@kanpla/types";
import { fetchDocument } from "../firestore/fetchDocument";

interface Props {
  db: FirebaseFirestore;
  /** Module to load the products for */
  module: Module;
  /** Filter modules for selected school  */
  schoolId: string;
  /** used to sort the products */
  productBank?: ProductBank;
  /** Optional, to filter products for a specific day */
  dayIndex?: DayIndex;
}

/**
 * @author robert-kanpla
 * @version 3.0.1
 * @returns Sorted modules by date of creation available for the given module/school
 */
export const getProductsPerSchool = ({
  db,
  module,
  productBank,
  schoolId = "—",
  dayIndex = null,
}: Props) => {
  const allProductIds = Object.keys(module?.products || {});

  const schoolProductIds = allProductIds.filter((productId) =>
    isProductAvailable(productId, module, schoolId, dayIndex)
  );
  const [products, setProducts] = useState<Array<Product>>([]);

  const fetchProducts = async () => {
    const promises = schoolProductIds.map((productId) =>
      fetchDocument<Product>(db.collection("products").doc(productId), true)
    );

    const newProducts = await Promise.all(promises);
    const filteredProducts = newProducts.filter((p) => !p.deleted);

    const sorted: Product[] = sortProducts({
      items: filteredProducts,
      list: getModuleList({ module }),
    });

    setProducts(sorted);
  };

  useEffect(() => {
    fetchProducts();
  }, [schoolProductIds.join()]);

  return products;
};
