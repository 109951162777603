import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { ElasticApiKeys } from "@kanpla/types";
import { message } from "antd";
import { useEffect, useState } from "react";
import { callFunction } from "..";
import CryptoJS from "crypto-js";

interface Options {
  /** Do not render an ANTD message on error, defaults to true */
  renderError?: boolean;
}

interface Props {
  userId: string;
  index: string;
  apiKeyName: ElasticApiKeys;
  options?: Options;
}

export const decryptElasticApi = (cipherText: string) => {
  if (!cipherText) return "";
  const reb64 = CryptoJS.enc.Hex.parse(cipherText);
  const bytes = reb64.toString(CryptoJS.enc.Base64);
  const decrypt = CryptoJS.AES.decrypt(bytes, "gA85OUeYYemFuwHQ");
  const plain = decrypt.toString(CryptoJS.enc.Utf8);
  return plain;
};

export const useElasticHashedApi = ({
  userId,
  apiKeyName,
  options = {},
}: Omit<Props, "index">) => {
  const [hashedApiKey, setHashedApiKey] = useState<string>(null);
  const [loadingConnector, setLoadingConnector] = useState(true);

  /**
   * GET API KEY
   * This listens to user ID changes and returns a hashed API KEY and saves it in a state,
   * so we don't need to rerender this key
   */
  const getApiKey = async () => {
    try {
      setLoadingConnector(true);

      if (!userId) throw new Error("You have to send a user ID in!");

      //  Fetch Elastic API key
      const hak = await callFunction("elastic-getElasticApiKey", {
        userId,
        requestedApiKey: apiKeyName,
      });

      setHashedApiKey(hak);
    } catch (e) {
      const renderError = options?.renderError ?? true;
      renderError && message.error(e?.message);

      console.error(e);
    } finally {
      setLoadingConnector(false);
    }
  };

  useEffect(() => {
    if (!userId) return;
    getApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    hashedApiKey,
    loadingApi: loadingConnector,
  };
};
