import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "@kanpla/system";
import { Card, Subscription } from "@kanpla/types";
import { DotDotDot, DrawerOrModal, Spinner } from "@kanpla/ui";
import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import CardAddButtonWrapper from "./CardAddButtonWrapper";
import CardItem from "./CardItem";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
  subtitle?: string;
}

const isCardSelected = (card: Card | Subscription, selectedId: string) =>
  "cardId" in card
    ? card.cardId === selectedId
    : card.subscriptionId === selectedId;

const CardsManage = (props: Props) => {
  const { t } = useTranslation(["modals", "translation"]);
  const { open, setOpen } = props;

  const { user, card, cards, setCard, loadCards, userId } =
    useContainer(AppContext);
  const defaultCardId = card?.cardId ? card.cardId : card?.subscriptionId || "";
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    setSelectedId(defaultCardId);
  }, [defaultCardId, cards, open]);

  const send = async () => {
    if (!selectedId) {
      message.info(t("modals:message.info.select-card"));
      return;
    }

    const selectedCard = cards.find((card: Card | Subscription) =>
      isCardSelected(card, selectedId)
    );

    try {
      if (selectedCard?.cardId) {
        const { cardId } = selectedCard;
        await db.collection("customers").doc(userId).update({
          defaultCardId: cardId,
        });
      } else {
        // update old card to default
        const { subscriptionId } = selectedCard;

        await db.collection("users").doc(user.id).update({
          defaultSubscriptionId: subscriptionId,
        });
      }

      setOpen(false);
      loadCards();
      setCard(card);
      message.success(t("modals:message.success.payment-method-updated"));
    } catch (err) {
      console.error(err);
      message.error(
        t("modals:message.error.payment-method-could-not-be-updated")
      );
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      zMax
      title={t("modals:modal.titles.your-cards")}
      subtitle={t("modals:modal.subtitles.select-default-card")}
      actions={[
        {
          label: t("translation:confirm"),
          onClick: send,
          className: "primary",
        },
      ]}
    >
      <div className="pt-4 pb-3">
        {loading && (
          <div className="pb-6 flex items-center justify-center">
            <Spinner size="medium" useCurrentColor />
          </div>
        )}
        {!loading &&
          cards
            .filter((c) => !c.hasOwnProperty("subscriptionId"))
            .map((card: Card | Subscription, index: number) => (
              <CardItem
                key={index.toString()}
                card={card}
                isSelected={isCardSelected(card, selectedId)}
                setSelectedId={setSelectedId}
                setOpen={setOpen}
                setLoading={setLoading}
              />
            ))}
        <div className="flex">
          <CardAddButtonWrapper setLoading={setLoading}>
            <Button className="lg ml-8 flex-1" disabled={loading}>
              {!loading && (
                <>
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                  {t("modals:add-new-card")}
                </>
              )}
              {loading && (
                <>
                  {t("translation:loading")}
                  <DotDotDot />
                </>
              )}
            </Button>
          </CardAddButtonWrapper>
        </div>
      </div>
    </DrawerOrModal>
  );
};

export default CardsManage;
