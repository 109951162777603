import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "@kanpla/system";
import { Button, message, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { reepayCardRemove } from "../../../lib/firebase.functions";
import useCancelRefill from "../../../lib/useCancelRefill";
import { AppContext } from "../../contextProvider";
import CardPreview from "./CardPreview";

interface Props {
  card: any;
  isSelected: boolean;
  setSelectedId: any;
  setOpen: (nextState: boolean) => void;
  setLoading: (nextState: boolean) => void;
}

const CardItem = (props: Props) => {
  const { card, isSelected, setSelectedId, setOpen, setLoading } = props;
  const { t } = useTranslation(["modals", "translation"]);

  const { setCard, loadCards } = useContainer(AppContext);
  const { handleCancel } = useCancelRefill();

  const deleteCard = async () => {
    try {
      setLoading(true);

      // handle deletion of old system card
      if (!card?.cardId) {
        setCard(null);

        await db.collection("subscriptions").doc(card.id).delete();

        // cancel automatisk bestilling
        message.success(t("modals:message.success.briefly-removed"));
        await handleCancel();

        return;
      }

      // remove reference from the customer document
      await reepayCardRemove({
        cardId: card.cardId,
      });

      // setCard(newDefaultCard);

      message.success(t("modals:message.success.briefly-removed"));

      // cancel automatisk bestilling
      await handleCancel();
    } catch (err) {
      console.error(err);
      message.error(t("modals:message.error.unable-to-delete-card"));
    } finally {
      setLoading(false);
      loadCards();
    }
  };

  return (
    <div className="flex mb-3">
      <label className="items-center flex flex-1">
        <input
          type="radio"
          className="form-radio"
          value={card?.cardId ? card.cardId : card.id}
          checked={isSelected}
          onChange={() =>
            setSelectedId(card?.cardId ? card.cardId : card.subscriptionId)
          }
        />
        <div className="bg-background-secondary rounded-l py-4 px-5 ml-4 flex-1">
          <CardPreview card={card} />
        </div>
      </label>
      <Popconfirm
        title={t("modals:popup.titles.remove-card-question")}
        onConfirm={deleteCard}
        okButtonProps={{ danger: true }}
        okText={t("translation:confirm")}
        cancelText={t("translation:cancel")}
        zIndex={99999999}
      >
        <Button
          className="rounded-l-none h-auto rounded-r flex items-center justify-center ml-1 px-5 border-none"
          danger
          name={card.metadata.last4}
          aria-label={t("modals:delete-credit-card")}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Popconfirm>
    </div>
  );
};

export default CardItem;
