import React, { useEffect, useRef } from "react";
import { Portal } from "react-portal";
import { animated, config, useTransition } from "react-spring";

interface Props {
  children: any;
  open: boolean;
  setOpen: (nextState: boolean) => void;
  containerClassName?: string;
  innerClassName?: string;
  innerStyles?: any;
  dragToClose?: boolean;
  zMax?: boolean;
  fullHeight?: boolean;
  zIndex?: 20 | 30 | 40 | 50 | 60 | 100;
  closableOutside?: boolean;
}

export const ModalWrapper = (props: Props) => {
  const {
    children,
    open,
    setOpen,
    containerClassName,
    innerClassName = "",
    innerStyles = {},
    zMax,
    fullHeight = false,
    zIndex = 40,
    closableOutside = true,
  } = props;

  const windowEl = useRef();
  const containerEl = useRef();

  const detectClick = (e: any) => {
    const scrollbar =
      e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight;
    const isOutside =
      // @ts-ignore
      windowEl?.current && !windowEl?.current?.contains(e.target);
    const closeIfOutside = closableOutside && isOutside;

    if (!scrollbar && closeIfOutside && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const element = containerEl.current;
    if (!element) return;
    (element as any).addEventListener("mousedown", detectClick, false);
    return () => {
      (element as any).removeEventListener("mousedown", detectClick, false);
    };
  }, [containerEl, open]);

  const overlayTransitions = useTransition(open, {
    config: config.slow,
    from: { opacity: 0 },
    enter: { opacity: 0.4 },
    leave: { opacity: 0, pointerEvents: "none" },
  });

  const customConfig = { mass: 3, tension: 450, friction: 40 };

  const modalTransitions = useTransition(open, {
    config: customConfig,
    from: { opacity: 0, scale: 0.95, y: 20 },
    enter: { opacity: 1, scale: 1, y: 0 },
    leave: { opacity: 0, scale: 0.75, y: 20 },
  });

  return (
    <Portal>
      {overlayTransitions(
        (props, item) =>
          item && (
            <animated.button
              style={{ ...props, zIndex: zMax ? "999" : "" }}
              aria-label="close modal"
              className={`block bg-text-primary inset-0 fixed opacity-50 ${`z-${zIndex}`} w-full cursor-default`}
            />
          )
      )}

      <div
        ref={containerEl}
        className={`fixed ${
          zMax ? "z-max" : `z-${zIndex}`
        } inset-0 overflow-x-hidden ${fullHeight ? "h-full" : ""} ${
          open
            ? "pointer-events-auto overflow-y-scroll"
            : "pointer-events-none overflow-y-hidden"
        } ${containerClassName}`}
      >
        {modalTransitions(
          ({ opacity, y }, item) =>
            item && (
              <animated.div
                ref={windowEl}
                style={{
                  opacity: opacity,
                  transform: y.to((y) => `translate3d(0,${y}px,0)`),
                  transformOrigin: "50% 50% 0",
                  height: fullHeight && "100%",
                  ...innerStyles,
                }}
                className={innerClassName}
              >
                {children}
              </animated.div>
            )
        )}
      </div>
    </Portal>
  );
};
