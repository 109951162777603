import { useAppPadding } from "@kanpla/system";
import React, { ReactNode } from "react";
import { tabIcons } from "./ModuleTab";

interface Navigation {
  children: ReactNode;
  backgroundColor?: string;
  loading?: boolean;
}

interface NavigationItem {
  label: string;
  icon: "home" | "lunch" | "breakfast" | "snacks" | "takeaway";
  active?: boolean;
  /** Will display box instead of a label, used for animation purposes */
  blind?: boolean;
  /** Can override active colors */
  defaultColor?: string;
  /** Can override active colors */
  highlightColor?: string;
}

export const BottomNavigation = (props: Navigation) => {
  const { children, loading = false } = props;

  const { shouldAddPadding } = useAppPadding();

  return (
    <div
      className="fixed bottom-0 left-0 w-full py-0 px-1 flex items-center justify-center bg-background-primary overflow-hidden border-t border-divider-main z-20"
      style={{
        minHeight: "50px",
        paddingBottom: `calc(env(safe-area-inset-bottom) + ${
          shouldAddPadding ? "1.66rem" : "-5px"
        })`,
        boxShadow: `0px 0px 8px -5px rgba(0, 0, 0, 0.15), 0px 0px 12px rgba(0, 0, 0, 0.05)`,
      }}
    >
      {loading ? (
        <div className="flex justify-between items-center pt-3 w-full mx-12 -mb-1">
          {[1, 2, 3, 4].map((index) => (
            <div key={index.toString()}>
              <div className="h-8 w-12 rounded loader mb-1" />
              <div className="h-2 w-12 rounded loader" />
            </div>
          ))}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export const BottomNavigationItem = (props: NavigationItem) => {
  const {
    label,
    icon: iconLabel,
    active = false,
    blind,
    defaultColor,
    highlightColor,
  } = props;
  const icon = tabIcons.find((icon) => icon.key === iconLabel).icon;

  return (
    <div
      className={`w-full flex flex-col items-center text-center whitespace-nowrap px-2 transition-all ease-in-out ${
        active ? "text-primary-main" : "text-text-secondary"
      }`}
      style={{ color: active ? highlightColor : defaultColor }}
    >
      <div className={`${active ? "animate-pop" : ""}`}>{icon}</div>
      <span
        style={{ fontSize: "0.75rem", fontWeight: 400 }}
        className={`mt-1 overflow-hidden`}
      >
        {blind ? (
          <span className={`block h-2 w-7 rounded-md bg-current`} />
        ) : (
          label
        )}
      </span>
    </div>
  );
};

export default BottomNavigation;
