import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { School } from "@kanpla/types";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { Button } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../Image";
import { SingleProductProps } from "./Product";

interface Props {
  product: SingleProductProps;
  onClick: () => void | (() => Promise<void>);
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  schools?: School[];
  hidePrices?: boolean;
}

export const ProductPreviewRow = (props: Props) => {
  const { product, onClick, children, hidePrices } = props;
  const { t, i18n } = useTranslation(["translation"]);

  const photo = product.photo;

  return (
    <div
      className="w-full border border-transparent hover:border-divider-main -mx-4 px-4 rounded-lg hover:shadow transition -mb-px z-0 relative cursor-pointer"
      onClick={onClick}
    >
      <div className="w-full flex flex-wrap md:flex-nowrap md:justify-between border-t py-3 -mt-px">
        <div className="w-full md:w-auto flex flex-row items-center justify-start md:flex-1 mr-6">
          {photo ? (
            <Image
              src={`${photo}?w=200`}
              alt={product.name}
              className="w-20 h-14 object-cover object-center rounded-md flex-shrink-0"
            />
          ) : (
            <div
              className="w-20 h-14 object-cover object-center rounded-md flex-shrink-0 bg-primary-main text-primary-contrast flex items-center justify-center"
              style={{ backgroundColor: product?.color }}
            >
              <FontAwesomeIcon icon={faCameraSlash} />
            </div>
          )}
          <h1 className="text-md text-text-primary ml-6">{product.name}</h1>
        </div>
        <div className="flex items-center justify-start md:justify-end -mr-2">
          <h1 className="h400 md:h200">
            {priceFormatter(
              product.price,
              { language: i18n.language },
              false,
              hidePrices,
              {
                wrapper: () => "-",
                component: () => <TooltipHiddenPrices />,
              }
            )}{" "}
          </h1>
          <Button
            onClick={onClick}
            size="small"
            type="link"
            className="ml-2 h-auto"
            data-cy="add-to-basket"
          >
            <span className="text-primary-main hover:text-primary-dark hover:bg-background-secondary transition-colors border border-divider-main bg-transparent rounded-lg p-1.5 px-2 text-sm">
              {t("add-to-basket")}
            </span>
          </Button>
          {/* <VariantsContent product={product} order={order} setOrder={setOrder} maxAmount={100}  /> */}
        </div>
        {children}
      </div>
    </div>
  );
};
