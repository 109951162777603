import { Button, ButtonProps, Modal, ModalProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  label: string | JSX.Element;
  modalProps?: ModalProps;
  buttonProps?: ButtonProps;
  icon?: IconProp;
  openTrigger?: (open: boolean) => void;
}

export const ModalButton: FC<Props> = ({
  children,
  label,
  modalProps = {},
  buttonProps = {},
  icon,
  openTrigger,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!openTrigger) return;

    openTrigger(open);
  }, [open]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        {...buttonProps}
        className={classnames({
          [buttonProps.className]: true,
          "text-sm": buttonProps.size === "small",
        })}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className={classnames({
              "mr-2": true,
              "text-sm": buttonProps.size === "small",
            })}
          />
        )}
        {label}
      </Button>
      <Modal
        centered
        visible={open}
        onCancel={() => setOpen(false)}
        title={label}
        {...modalProps}
        onOk={(e) => {
          modalProps?.onOk && modalProps.onOk(e);
          setOpen(false);
        }}
      >
        {children}
      </Modal>
    </>
  );
};
