const windowIsDefined = typeof window !== "undefined";

/** Check if the web app is being run on the native app */
export const isRenderedInWebView: boolean =
  windowIsDefined &&
  window?.["isRenderedInWebView"] !== undefined &&
  JSON.parse(window?.["isRenderedInWebView"]);

/** Check if the user has already answered to the notifications permissions modal */
export const hasAnsweredToNotificationPermissions: boolean | undefined =
  windowIsDefined &&
  isRenderedInWebView &&
  window?.["hasAnsweredToNotificationPermissions"] !== undefined
    ? JSON.parse(window?.["hasAnsweredToNotificationPermissions"])
    : undefined;

/** Check if Microsoft Login is available on the native app */
export const isMSLoginAvailable: boolean =
  windowIsDefined &&
  window?.["isMSLoginAvailable"] !== undefined &&
  JSON.parse(window?.["isMSLoginAvailable"]);

/** Check if the user has granted tracking permissions from the native app */
export const trackingPermissionsGranted: boolean =
  windowIsDefined &&
  window?.["trackingPermissionsGranted"] !== undefined &&
  JSON.parse(window?.["trackingPermissionsGranted"]);

/** Get the keyboard status, can be either "hidden" or "active" */
export const webViewKeyboardStatus: "hidden" | "active" =
  windowIsDefined && window?.["webViewKeyboardStatus"];

/** Check native app feature version */
export const webViewVersion: number =
  windowIsDefined &&
  window?.["version"] !== undefined &&
  parseInt(window?.["version"]);
