/* eslint-disable react-hooks/rules-of-hooks */
import {
  flexBulkOrder,
  FlexOrder,
  FlexStandard,
  OrderOrder,
  Timestamp,
  Week,
} from "@kanpla/types";
import { isEmpty, set } from "lodash";
import moment from "moment";
import { useCollectionListener } from "../firestore/UseCollectionListener";
import { getDayIndexFromSeconds } from "./getDayIndexFromSeconds";

interface Props {
  schoolId: string;
  moduleId: string;
  childId: string;
  /** Database provider */
  db: any;
  /** Day of the order */
  date: Timestamp;
  /** Standard order as a fallback, if no document was found */
  standard: FlexStandard["standard"];
  week: Week;
}

export const getFlexOrder = <T>(props: Props) => {
  const { schoolId, moduleId, childId, db, date, standard, week } = props;

  const dayIndex = getDayIndexFromSeconds(date?.seconds || 0);

  const individualRef = db
    .collection("flexOrders")
    .where("childId", "==", childId || null)
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where(`date`, ">=", week[0])
    .where(`date`, "<=", week[4]);

  const individualWeekRef = db
    .collection("flexOrders")
    .where("childId", "==", childId || null)
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where(`date`, ">=", week[0])
    .where(`date`, "<=", week[4]);

  const [orders = [], ordersLoading] =
    useCollectionListener<FlexOrder>(individualWeekRef);

  const [result = [], orderLoading] =
    useCollectionListener<FlexOrder>(individualRef);

  // Get the first document
  const orderDocument = result.filter((res) => !res.deleted)[0];

  const ordersDocuments = orders.filter((order) => !order.deleted);

  const isPastDate = moment.unix(date?.seconds || 0).isBefore(moment(), "day");

  const baseWeekOrders = week.reduce((acc, day) => {
    return [
      ...acc,
      ordersDocuments.find((order) => order.dateSeconds === day.seconds) ||
        undefined,
    ];
  }, []);

  // console.log("*** WEEK orders ***", ordersDocuments);
  // console.log("*** BASE orders ***", baseWeekOrders);

  const weekOrders = week.reduce((acc, day, index) => {
    const isPastDate = moment.unix(day?.seconds || 0).isBefore(moment(), "day");
    const noOrderDocument =
      (!ordersLoading &&
        !ordersDocuments.find(
          (order) => (order.dateSeconds || order.date.seconds) === day.seconds
        )) ||
      !ordersDocuments.length;

    const standardOrder = isPastDate ? 0 : standard[index];

    // if (standardOrder) set(standardOrder, "isStandard", true);

    return {
      ...acc,
      [day?.seconds]: noOrderDocument
        ? standardOrder || 0
        : baseWeekOrders[index] || 0,
    };
  }, {});

  // console.log("*** FINAL ***", weekOrders);

  // If no document exists, return standard order
  const noOrderDocument = !orderLoading && typeof orderDocument === `undefined`;
  const standardOrder = isPastDate ? 0 : standard[dayIndex] || {};
  const documentOrderOrder = orderDocument?.order || {};
  const order: OrderOrder = noOrderDocument
    ? standardOrder
    : documentOrderOrder;
  const orderInfo = orderDocument?.info || {};

  return { order, orderInfo, orderLoading, orderDocument, weekOrders };
};
