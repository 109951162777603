import { Order } from "@kanpla/types";
import { isEmpty } from "lodash";
import { calculateAmountOfOrderItems } from "./calculateAmountOfOrderItems";

export const calculateMultipleOrdersItemsAmount = (orders: Order[]) => {
  if (isEmpty(orders)) return 0;

  let totalAmount = 0;

  orders.forEach((order) => {
    totalAmount += calculateAmountOfOrderItems(order.order);
  });

  return totalAmount;
};
