import React, { Dispatch, useEffect, SetStateAction } from "react";
import { BottomSheet as ReactBottomSheet } from "react-spring-bottom-sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  children: React.ReactChild;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  subtitle?: string;
  /** Doesn't allow user scroll outside the component */
  blocking?: boolean;
  /** Callback when the Bottom Sheet is being closed */
  onDismiss?: () => void;
  showCloseButton?: boolean;
  /** Dismissable header  */
  dismissableHeader?: boolean;
}

export const BottomSheet = (props: Props) => {
  const {
    open,
    setOpen,
    children,
    title,
    subtitle,
    blocking = false,
    onDismiss = () => true,
    showCloseButton = true,
  } = props;

  return (
    <ReactBottomSheet
      open={open}
      blocking={blocking}
      scrollLocking={false}
      onDismiss={onDismiss}
      id="react-desktop-drawer"
      className="relative bg-background-primary "
      header={null}
    >
      {blocking && (
        <style>
          {`
          [data-rsbs-overlay] {
            z-index: 999;
          }
        `}
        </style>
      )}
      <div className="w-full h-full p-4 select-none flex flex-col items-center">
        {showCloseButton && (
          <CloseButton
            onClick={() => setOpen(false)}
            className="absolute top-4 right-4"
          />
        )}
        <h1 className="h700">{title}</h1>
        <h3 className="text-text-secondary">{subtitle}</h3>
        <div className="mt-4 pb-4 w-full h-full">{children}</div>
      </div>
    </ReactBottomSheet>
  );
};

const CloseButton = ({ onClick, className = "" }) => {
  return (
    <div
      className={`rounded-full bg-background-secondary text-primary-main hover:opacity-60 transition-opacity ease-in-out flex justify-center items-center p-4 w-8 h-8 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};
